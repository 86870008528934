var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reports-page" },
    [
      _c("div", { staticClass: "cells titles" }, [
        _c("div", { staticClass: "frow centered" }, [
          _c("div", { staticClass: "col-xs-1-3" }, [
            _vm.getRole <= 1
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentCollection,
                        expression: "currentCollection"
                      }
                    ],
                    staticClass: "width-auto ml-30 my-20",
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.currentCollection = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.setCurrentCollection(_vm.currentCollection)
                        }
                      ]
                    }
                  },
                  _vm._l(_vm.collections, function(collection, index) {
                    return _c(
                      "option",
                      { attrs: { selected: "" }, domProps: { value: index } },
                      [_vm._v(_vm._s(collection.title))]
                    )
                  }),
                  0
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "col-xs-1-3" }, [
            _vm.getRole <= 1
              ? _c("div", { staticClass: "text-center" }, [
                  _vm._v("Collection")
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "col-xs-1-3" }),
          _c("div", { staticClass: "col-xs-1-12 text-center" }, [_vm._v("#")]),
          _c(
            "div",
            {
              staticClass: "col-xs-2-12 clickable",
              on: {
                click: function($event) {
                  return _vm.setSort("name")
                }
              }
            },
            [_vm._v("Name")]
          ),
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "col-xs-2-12 text-center clickable",
              on: {
                click: function($event) {
                  return _vm.setSort("priority")
                }
              }
            },
            [_vm._v("Priority")]
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-2-12 text-center clickable",
              on: {
                click: function($event) {
                  return _vm.setSort("risk")
                }
              }
            },
            [_vm._v("Risk")]
          ),
          _c("div", { staticClass: "col-xs-1-12 text-center" }, [
            _vm._v("Sex")
          ]),
          _c("div", { staticClass: "col-xs-2-12 text-center" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedFilter,
                    expression: "selectedFilter"
                  }
                ],
                staticClass: "width-100",
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedFilter = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.sortFilter
                  ],
                  dblclick: _vm.sortFilter
                }
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("No Filter")
                ]),
                _vm._l(_vm.filters, function(filter) {
                  return _c("option", { domProps: { value: filter } }, [
                    _vm._v(_vm._s(filter.name))
                  ])
                })
              ],
              2
            )
          ])
        ])
      ]),
      _vm._l(_vm.sorted, function(row, index) {
        return _c("record", {
          key: row._id,
          attrs: { record: row, index: index, variable: _vm.cellVariable }
        })
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-2-12 text-center" }, [
      _vm._v("WDS Score"),
      _c("div", { staticClass: "wds-score-key" }, [_vm._v("BMI / MetS")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }