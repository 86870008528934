let { mapState } = require('../store/sidebar-visible');

module.exports = {
  components: {
    alerts: require('./alerts/vue.pug').default,
    'header-bar': require('./header-bar/vue.pug').default,
    sidebar: require('./sidebar/vue.pug').default,
  },
  computed: mapState(['visible', 'noSidebar']),
};
