let { mapActions } = require('begin-build/alerts');
let api = require('begin-build/api');
let { mapGetters } = require('../store/auth');
let properties = require('begin-build/properties');

module.exports = {
  data: () => ({
    newOrganizationView: false,
    activeView: '',
    newOrganization: {
      _id: null,
      contactEmail: null,
      contactPhone: null,
      name: null,
      url: null,
      address: null,
    },
    organization: {},
    profile: {
      email: null,
      firstName: null,
      lastName: null,
      role: null,
    },
    type: 'user',
    emailValid: true,
    organizations: [],
    profiles: [],
    saving: false,
    currentCollection: 0,
    collections: null,
    flags: {
      api: false,
      reports: false,
      aggregate: false,
    },
  }),

  computed: Object.assign({
    api() {
      return properties.api;
    },
    organizationsExcludingSelf() {
      return this.organizations.filter(org => org._id !== this.organization._id);
    }, 
    organizationsWithChildren() {
      let orgs = this.organizations.filter(org => !org.parentOrganization);
      orgs.forEach(org => org.children = [])
      this.organizations.forEach((organization => {
        if (organization.parentOrganization) {
          let orgIndex = orgs.findIndex(org => org._id == organization.parentOrganization);
          if (orgIndex > -1) {
            orgs[orgIndex].children.push(organization) 
          }
        }
      }));
      return orgs
    }
  }, mapGetters(['access'])),

  watch: {
    $route(route) {
      this.checkCreate(route);
    },
  },

  async created() {
    this.init();
  },

  methods: Object.assign({
    async init() {
      try {
        let { data } = await api.get('organization');
        this.organizations = data.sort((a, b) =>
          a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);
      } catch (e) {
        this.error(e);
      }
    },

    displayNewOrganization() {
      this.newOrganization = {
        _id: null,
        contactEmail: null,
        contactPhone: null,
        name: null,
        url: null,
        address: null,
      };
      this.newOrganizationView = true;
    },

    async fetchProfiles(organization) {
      this.activeView = 'manage';
      try {
        let { data } = await api.get(`profile/organization/${organization._id}`);
        this.profiles = data;
      } catch (e) {
        this.error(e);
      }
      try {
        let { data } = await api.get(`collection/aggregate/${organization._id}`);
        this.collections = data;
      } catch (e) {
        this.error(e);
      }
      this.organization = organization;
      Object.keys(this.flags).forEach(flag => {
        this.flags[flag] = Array.isArray(organization.flags) && organization.flags.includes(flag);
      });
    },

    isActiveNav(org) {
      if (org._id === this.organization._id) {
        return true;
      }
      if (org.children && org.children.find(child => child._id === this.organization._id)) {
        return true;
      }
      return false
    },

    async submitProfile() {
      this.saving = true;
      try {
        await api.post('profile', {
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          email: this.profile.email,
          organization: this.organization._id,
          role: this.profile.role,
        });
        this.profile = {
          email: null,
          firstName: null,
          lastName: null,
          role: null,
        };
      } catch (e) {
        this.error(e);
      }
      this.saving = false;
    },

    async submitOrganization() {
      this.saving = true;
      try {
        await api.post('organization', this.newOrganization);
        this.newOrganization = {
          _id: null,
          contactEmail: null,
          contactPhone: null,
          name: null,
          url: null,
          address: null,
        };
        this.init();
        this.newOrganizationView = false;
      } catch (e) {
        this.error(e);
      }
      this.saving = false;
    },

    async updateFlags() {
      let flags = Object.entries(this.flags).filter(([, active]) => active).map(([flag]) => flag);
      await api.put(`organization/${this.organization._id}/flags`, flags);
    },

    async updateParentOrganization() {
      await api.put(`organization/${this.organization._id}/setParent`, { parentOrganization: this.organization.parentOrganization})
    },
  }, mapActions(['error'])),
};
