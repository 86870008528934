var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("div", { staticClass: "insurance-table-page" }, [
      _c("div", { staticClass: "frow row-end" }, [
        _c("div", { staticClass: "col-md-1-4" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedPlan,
                  expression: "selectedPlan"
                }
              ],
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedPlan = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function($event) {
                    return _vm.changePlan(_vm.selectedPlan)
                  }
                ]
              }
            },
            [
              _c(
                "option",
                { attrs: { selected: "" }, domProps: { value: null } },
                [_vm._v("+ Add new insurance plan")]
              ),
              _vm._l(_vm.plans, function(plan, index) {
                return _c(
                  "option",
                  { domProps: { value: index }, on: { click: _vm.getPlans } },
                  [_vm._v(_vm._s(plan.name))]
                )
              })
            ],
            2
          )
        ])
      ]),
      _c("div", { staticClass: "insurance-table" }, [
        _c(
          "div",
          { staticClass: "frow" },
          _vm._l(_vm.table, function(cell, index) {
            return _c("div", { staticClass: "col-xs-1-7" }, [
              cell.title !== undefined
                ? _c("div", { staticClass: "cell title" }, [
                    _c("div", { staticClass: "frow centered" }, [
                      _vm._v(_vm._s(cell.title))
                    ])
                  ])
                : _vm.heatMap
                ? _c("div", { staticClass: "cell" }, [
                    _c(
                      "div",
                      {
                        staticClass: "frow centered",
                        style: cell.heatMapStyle
                      },
                      [_vm._v(_vm._s(cell.count))]
                    )
                  ])
                : _c(
                    "div",
                    {
                      staticClass: "cell",
                      class: {
                        "green-cell": cell.green && cell.low,
                        "yellow-cell": !cell.green && cell.low,
                        "red-cell": cell.high,
                        "healthy-cell": cell.healthy,
                        "unhealthy-cell": cell.unhealthy
                      }
                    },
                    [
                      _vm.editingCell === index
                        ? _c(
                            "form",
                            {
                              staticClass: "frow",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.setCell(index)
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "col-xs-7-12" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editCell,
                                      expression: "editCell"
                                    }
                                  ],
                                  ref: "activeInput",
                                  refInFor: true,
                                  staticClass: "cell",
                                  attrs: { type: "tel" },
                                  domProps: { value: _vm.editCell },
                                  on: {
                                    blur: function($event) {
                                      _vm.editCell ? _vm.setCell(index) : ""
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.editCell = $event.target.value
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-xs-5-12" }, [
                                _c("button", { attrs: { type: "submit" } }, [
                                  _vm._v(
                                    _vm._s(_vm.editCell ? "Done" : "Clear")
                                  )
                                ])
                              ])
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "frow centered",
                              on: {
                                click: function($event) {
                                  return _vm.focusInput(index)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    (
                                      _vm.display[index] ||
                                      _vm.calculateCell(cell, _vm.overrides)
                                    ).toFixed(2)
                                  )
                              )
                            ]
                          )
                    ]
                  )
            ])
          }),
          0
        )
      ]),
      _c("div", { staticClass: "insurance-table-options" }, [
        _c("div", { staticClass: "frow" }, [
          _c("div", { staticClass: "col-md-1-3" }, [
            _c("div", { staticClass: "frow" }, [
              _c("label", [
                _vm._v("Name"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name"
                    }
                  ],
                  attrs: { placeholder: "Name this plan" },
                  domProps: { value: _vm.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name = $event.target.value
                    }
                  }
                })
              ]),
              _c("label", [
                _vm._v("Description"),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description"
                    }
                  ],
                  attrs: { placeholder: "Optional description of plan" },
                  domProps: { value: _vm.description },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.description = $event.target.value
                    }
                  }
                })
              ]),
              _c("button", { on: { click: _vm.saveCells } }, [_vm._v("Save")])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }