import { EMBED, HANDSHAKE_API, HANDSHAKE_EMBED, COMPLETE, ERROR } from './constants';

const DOMAIN = 'https://wellnessdata.solutions';
const ORIGIN = process.env.NODE_ENV === 'production' ? DOMAIN : '*';

export default function ({
  element,
  request,
  onComplete = () => {},
  onError = console.error,
}) {
  const iframe = element.contentWindow;

  function handshake() {
    iframe.postMessage({ type: EMBED, message: HANDSHAKE_API, payload: { request } }, ORIGIN);
  }

  function recieve({ data, origin }) {
    if (process.env.NODE_ENV === 'production' && origin !== DOMAIN) {
      return;
    }
    if (!data || data.type !== EMBED) {
      return;
    }
    if (data.message === HANDSHAKE_EMBED) {
      handshake();
      return;
    }
    if (data.message === ERROR) {
      onError(data.payload);
    }
    if (data.message === COMPLETE) {
      onComplete(data.payload);
    }
  }

  window.addEventListener('message', recieve, false);
  handshake();
};
