var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "div",
        {
          staticClass: "print-mets-background",
          class: {
            "out-of-bounds": _vm.flip
              ? _vm.value < _vm.midMax
              : _vm.value >= _vm.midMax
          }
        },
        [
          _c("div", { staticClass: "gauge", class: { flip: _vm.flip } }),
          _c("div", { staticClass: "frow" }, [
            _c("div", { staticClass: "print-mets-range-display" }, [
              _vm._v(_vm._s(_vm.midMin))
            ]),
            _c(
              "div",
              {
                staticClass: "print-mets-value",
                style:
                  "transform: rotate(" + (_vm.calcRange * 270 - 135) + "deg)"
              },
              [
                _c("div", { staticClass: "frow column-center" }, [
                  _c(
                    "div",
                    {
                      staticClass: "print-mets-number",
                      style:
                        "transform: rotate(" +
                        (-(_vm.calcRange * 270) + 135) +
                        "deg)"
                    },
                    [_vm._v(_vm._s(_vm.value || 0))]
                  ),
                  _c("div", { staticClass: "print-mets-needle" })
                ])
              ]
            ),
            _c("div", { staticClass: "print-mets-range-display" }, [
              _vm._v(_vm._s(_vm.midMax))
            ])
          ]),
          _c("div", { staticClass: "smiley-face-container" }, [
            _c("div", { staticClass: "smiley-face" }, [
              _c("div", { staticClass: "smiley-eye" }),
              _c("div", { staticClass: "smiley-eye" }),
              _vm.calcClass === "yellow"
                ? _c("div", { staticClass: "smiley-nuetral" })
                : _c("div", { staticClass: "smiley-mouth-container" }, [
                    _vm.calcClass !== "yellow"
                      ? _c("div", {
                          staticClass: "smiley-mouth",
                          class: { smile: _vm.calcClass === "green" }
                        })
                      : _vm._e()
                  ])
            ])
          ])
        ]
      ),
      _vm._t("default"),
      _c("div", { staticClass: "print-mets-bar-title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("div", { staticClass: "print-mets-bar-units" }, [
        _vm._v(_vm._s("(" + _vm.units + ")"))
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }