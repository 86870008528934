let Chartist = require('chartist');
let api = require('begin-build/api');
let { mapState } = require('../store/sidebar-visible');
let { mapActions } = require('../store/collection');
let { TABLE } = require('../../shared/plan');

const COLUMNS = ['', 0, 1, 2, 3, 4, 5];
const ROWS = ['-1', 0, 1, 2, 3];
const TITLE_ROW = COLUMNS.map(i => ({ title: `${i}` }));
const MEAN_TABLE = {
  age: 'Age',
  height: 'Height (in)',
  weight: 'Weight (lbs)',
  bmi: 'BMI',
  bmiScore: 'BMI Score',
  waistCircumference: 'Waist Circumference (in)',
  systolicBP: 'Systolic B.P.',
  diastolicBP: 'Diastolic B.P.',
  triglycerides: 'Triglycerides',
  hdl: 'HDL Cholesterol',
  bloodGlucose: 'Fasting Glucose',
  metsScore: 'MetS Score',
};
const RESPONSIVE_LINE_OPTIONS = {
  axisY: {
    onlyInteger: true,
  },
};
const RESPONSIVE_OPTIONS = [
  ['screen and (max-width: 767px)', {
    chartPadding: 10,
    labelOffset: 0,
    labelDirection: 'explode',
  }],
  ['screen and (min-width: 768px) and (max-width: 991px)', {
    chartPadding: 10,
    labelOffset: 0,
    labelDirection: 'explode',
  }],
  ['screen and (min-width: 992px) and (max-width: 1199px)', {
    chartPadding: 10,
    labelOffset: 0,
    labelDirection: 'explode',
  }],
  ['screen and (min-width: 1200px) and (max-width: 1601px)', {
    chartPadding: 10,
    labelOffset: 0,
    labelDirection: 'explode',
  }],
  ['screen and (min-width: 1602px) and (max-width: 2000px)', {
    chartPadding: 10,
    labelOffset: 0,
    labelDirection: 'explode',
  }],
];
const HEALTH_PIE_STYLE = {
  chartPadding: 30,
  labelDirection: 'explode',
};

let charts;
let bmiHistoryChart = {};
let bmiDistributionChart = {
  labels: ['Underweight', 'Healthy Weight', 'Overweight', 'Obese', 'Morbidly Obese'],
};
let metsHistoryChart = {};
let metsDistributionChart = {
  labels: ['0', '1', '2', '3', '4', '5'],
};

module.exports = {
  components: {
    dashboardGraph: require('./graph/vue.pug').default,
  },

  data: () => ({
    allCollections: null,
    minorCollections: null,
    showMinorCollections: null,
    departments: null,
    currentCollection: 0,
    currentDepartment: 'Depts.',
    currentFilter: 'No-Filter',
    bmiAvg: 0,
    metsAvg: 0,
    filters: ['Coached', 'Employees', 'Spouses', 'Male', 'Female', '18-20', '20-30', '30-40', '40-50', '50-60', '60-70', '70-80', '80-90', '90+'],
    scoreTable: null,
    compareScoreTable: false,
    meanTable: null,
  }),

  computed: Object.assign({
    bigColor() {
      return {
        bmi: this.healthClass(this.bmiAvg),
        mets: this.healthClass(this.metsAvg),
      };
    },

    collections() {
      if (this.showMinorCollections && this.minorCollections) {
        return this.allCollections.concat(this.minorCollections);
      }
      return this.allCollections;
    },
  }, mapState({ sidebarVisible: 'visible' })),

  watch: {
    sidebarVisible() {
      setTimeout(() => {
        if (charts) {
          charts.forEach(chart => {
            chart.update();
          });
        }
      }, 400);
    },
  },

  methods: Object.assign({
    healthClass(avg) {
      switch (Math.floor(avg)) {
        case -1:
          return 'underweight';
        case 0:
          return 'healthy-weight';
        case 1:
          return 'overweight';
        case 2:
          return 'obese';
        default:
          return 'morbidly-obese';
      }
    },

    sortMinorCollections(data) {
      this.allCollections = data.filter(col => !col.minor);
      this.minorCollections = data.filter(col => col.minor);
    },

    setCurrentCollection(index = 0) {
      let selectionIndex = index;
      if (this.collections[index - 2]) {
        selectionIndex = index - 2;
      } else if (this.collections[index - 1]) {
        selectionIndex = index - 1;
      }
      this.bmiAvg = this.collections[index].bmiAvg;
      this.metsAvg = this.collections[index].metsAvg;
      let selection = this.collections.slice(selectionIndex, selectionIndex + 5);
      let { underweight, healthy, overweight, obese, morbidlyObese } = this.collections[index];
      bmiDistributionChart.series = [underweight, healthy, overweight, obese, morbidlyObese];
      let bmiLabels = [];
      bmiHistoryChart.series = selection.reduce((totals, aggregateData) => {
        bmiLabels.unshift(new Date(aggregateData.created).getUTCFullYear());
        totals[0].unshift((aggregateData.underweight / aggregateData.count) * 100);
        totals[1].unshift((aggregateData.healthy / aggregateData.count) * 100);
        totals[2].unshift((aggregateData.overweight / aggregateData.count) * 100);
        totals[3].unshift((aggregateData.obese / aggregateData.count) * 100);
        totals[4].unshift((aggregateData.morbidlyObese / aggregateData.count) * 100);
        return totals;
      }, [[], [], [], [], []]);
      bmiHistoryChart.labels = bmiLabels;
      let {
        metsFactor0,
        metsFactor1,
        metsFactor2,
        metsFactor3,
        metsFactor4,
        metsFactor5,
      } = this.collections[index];
      metsDistributionChart.series = [
        metsFactor0,
        metsFactor1,
        metsFactor2,
        metsFactor3,
        metsFactor4,
        metsFactor5,
      ];
      let metsLabels = [];
      metsHistoryChart.series = selection.reduce((totals, aggregateData) => {
        metsLabels.unshift(new Date(aggregateData.created).getUTCFullYear());
        [...Array(6).keys()].forEach(key => {
          totals[key].unshift((aggregateData[`metsFactor${key}`] / aggregateData.count) * 100);
        });
        return totals;
      }, [[], [], [], [], [], []]);
      metsHistoryChart.labels = metsLabels;
      if (charts) {
        charts.forEach(chart => {
          chart.update();
        });
      } else {
        charts = [
          new Chartist.Line('#main-bmi-history-chart', bmiHistoryChart, RESPONSIVE_LINE_OPTIONS),
          new Chartist.Pie('#main-bmi-distribution-chart', bmiDistributionChart, HEALTH_PIE_STYLE, RESPONSIVE_OPTIONS),
          new Chartist.Line('#main-mets-history-chart', metsHistoryChart, RESPONSIVE_LINE_OPTIONS),
          new Chartist.Pie('#main-mets-distribution-chart', metsDistributionChart, HEALTH_PIE_STYLE, RESPONSIVE_OPTIONS),
        ];
      }
      this.$emit('updateChart');
      this.compareScoreTable = this.collections[index + 1] !== undefined;
      this.scoreTable = ROWS.reduce((out, title) => {
        let columns = this.collections[index].scoreTable[title];
        columns = Object.values(columns);
        return out.concat([{ title }].concat(columns.map((count, column) => {
          let cell = TABLE[title][column];
          let color = 'red-cell';
          if (cell.low) {
            color = 'yellow-cell';
          }
          if (cell.green) {
            color = 'green-cell';
          }
          let change = null;
          if (this.compareScoreTable) {
            let i = index + 1;
            let old = this.collections[i].scoreTable[title][column] / this.collections[i].count;
            change = ((count / this.collections[index].count) - old) * 100;
          }
          return { count, color, change };
        })));
      }, TITLE_ROW);
      this.meanTable = Object.entries(MEAN_TABLE).map(([key, title]) =>
        Object.assign({ key, title }, this.collections[index].mean[key]));
    },

    async filterCollection() {
      let config = {
        params: {},
      };
      if (this.currentFilter !== 'No-Filter') {
        config.params.filter = this.currentFilter;
      }
      if (this.currentDepartment !== 'Depts.') {
        config.params.department = this.departments[this.currentDepartment]._id;
      }
      let { data } = await api.get(`collection/aggregate/${this.$route.params.id}`, config);
      this.sortMinorCollections(data);
      this.setCurrentCollection(this.currentCollection);
    },

    toggleMinorCollections() {
      this.setCurrentCollection();
    },
  }, mapActions(['getAll'])),

  async mounted() {
    if (this.$route.params.id) {
      let { data } = await api.get(`collection/aggregate/${this.$route.params.id}`);
      this.sortMinorCollections(data);
      let { data: departments } = await api.get(`grouping/${this.$route.params.id}/department`);
      this.departments = departments;
    } else {
      let all = await this.getAll();
      this.sortMinorCollections(all);
    }
    this.setCurrentCollection();
  },

  beforeDestroy() {
    // TODO This doesn't exsist?
    // listener();
    charts.forEach(chart => {
      chart.detach();
    });
    charts = undefined;
  },
};
