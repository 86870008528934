var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-page" }, [
    _c("div", { staticClass: "frow row-end" }, [
      _c("label", { staticClass: "dashboard-checkbox row-end width-auto" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.showMinorCollections,
              expression: "showMinorCollections"
            }
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.showMinorCollections)
              ? _vm._i(_vm.showMinorCollections, null) > -1
              : _vm.showMinorCollections
          },
          on: {
            click: _vm.toggleMinorCollections,
            change: function($event) {
              var $$a = _vm.showMinorCollections,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.showMinorCollections = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.showMinorCollections = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.showMinorCollections = $$c
              }
            }
          }
        }),
        _vm._v("Include Minor Collections")
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentCollection,
              expression: "currentCollection"
            }
          ],
          staticClass: "width-auto ml-30",
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.currentCollection = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function($event) {
                return _vm.setCurrentCollection(_vm.currentCollection)
              }
            ]
          }
        },
        _vm._l(_vm.collections, function(collection, index) {
          return _c(
            "option",
            { attrs: { selected: "" }, domProps: { value: index } },
            [_vm._v(_vm._s(collection.title))]
          )
        }),
        0
      ),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentDepartment,
              expression: "currentDepartment"
            }
          ],
          staticClass: "width-auto ml-30",
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.currentDepartment = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function($event) {
                return _vm.filterCollection()
              }
            ]
          }
        },
        [
          _c("option", { attrs: { value: "Depts." } }, [_vm._v("Depts.")]),
          _vm._l(_vm.departments, function(department, index) {
            return _c("option", { domProps: { value: index } }, [
              _vm._v(_vm._s(department.title))
            ])
          })
        ],
        2
      ),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentFilter,
              expression: "currentFilter"
            }
          ],
          staticClass: "width-auto ml-30",
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.currentFilter = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              function($event) {
                return _vm.filterCollection()
              }
            ]
          }
        },
        [
          _c("option", { attrs: { value: "No-Filter" } }, [
            _vm._v("No Filter")
          ]),
          _vm._l(_vm.filters, function(filter) {
            return _c("option", { domProps: { value: filter } }, [
              _vm._v(_vm._s(filter))
            ])
          })
        ],
        2
      )
    ]),
    _c("div", { staticClass: "frow gutters" }, [
      _c("div", { staticClass: "col-md-1-1" }, [
        _vm.bmiAvg && _vm.metsAvg
          ? _c("div", { staticClass: "shadow-light averages-box" }, [
              _c("div", { staticClass: "frow" }, [
                _c("div", { staticClass: "col-md-1-2" }, [
                  _c("div", { staticClass: "frow column-center" }, [
                    _c(
                      "div",
                      { staticClass: "giant-number", class: _vm.bigColor.bmi },
                      [_vm._v(_vm._s(_vm.bmiAvg.toFixed(1)))]
                    ),
                    _c("div", { staticClass: "giant-subtitle" }, [
                      _vm._v("Average BMI Score")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-md-1-2" }, [
                  _c("div", { staticClass: "frow column-center" }, [
                    _c(
                      "div",
                      { staticClass: "giant-number", class: _vm.bigColor.mets },
                      [_vm._v(_vm._s(_vm.metsAvg.toFixed(1)))]
                    ),
                    _c("div", { staticClass: "giant-subtitle" }, [
                      _vm._v("Average Mets Score")
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "col-md-1-2" }, [
        _vm.collections
          ? _c("div", { staticClass: "shadow-light chart-box" }, [
              _c("div", { staticClass: "dashboard-insurance-table" }, [
                _c(
                  "div",
                  { staticClass: "frow" },
                  _vm._l(_vm.scoreTable, function(cell) {
                    return _c("div", { staticClass: "col-xs-1-7" }, [
                      cell.title !== undefined
                        ? _c("div", { staticClass: "cell title" }, [
                            _c("div", { staticClass: "frow centered" }, [
                              _vm._v(_vm._s(cell.title))
                            ])
                          ])
                        : _c(
                            "div",
                            { staticClass: "cell", class: cell.color },
                            [
                              _c("div", { staticClass: "frow centered" }, [
                                _vm._v(_vm._s(cell.count))
                              ])
                            ]
                          )
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "col-md-1-2" }, [
        _vm.compareScoreTable
          ? _c("div", { staticClass: "shadow-light chart-box" }, [
              _c("div", { staticClass: "dashboard-insurance-table" }, [
                _c(
                  "div",
                  { staticClass: "frow" },
                  _vm._l(_vm.scoreTable, function(cell) {
                    return _c("div", { staticClass: "col-xs-1-7" }, [
                      cell.title !== undefined
                        ? _c("div", { staticClass: "cell title" }, [
                            _c("div", { staticClass: "frow centered" }, [
                              _vm._v(_vm._s(cell.title))
                            ])
                          ])
                        : _c(
                            "div",
                            { staticClass: "cell", class: cell.color },
                            [
                              _c("div", { staticClass: "frow centered" }, [
                                _c("div", {
                                  staticClass: "dashboard-insurance-arrow",
                                  class: {
                                    up: cell.change > 0,
                                    down: cell.change < 0
                                  }
                                }),
                                _vm._v(
                                  _vm._s(
                                    Math.abs((cell.change || 0).toFixed(1))
                                  ) + "%"
                                )
                              ])
                            ]
                          )
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "col-md-1-2" }, [
        _vm.meanTable
          ? _c("div", { staticClass: "shadow-light chart-box" }, [
              _c(
                "div",
                { staticClass: "dashboard-mean-table text-center" },
                [
                  _vm._m(2),
                  _vm._l(_vm.meanTable, function(entry) {
                    return _c(
                      "div",
                      {
                        class: {
                          "mean-boxed":
                            entry.key === "bmiScore" ||
                            entry.key === "metsScore"
                        }
                      },
                      [
                        _c("div", { staticClass: "frow" }, [
                          _c("div", { staticClass: "col-xs-2-5" }, [
                            _c("div", { staticClass: "mean-title" }, [
                              _vm._v(_vm._s(entry.title))
                            ])
                          ]),
                          _c("div", { staticClass: "col-xs-1-5" }, [
                            _vm._v(_vm._s((entry.all || 0).toFixed(1)))
                          ]),
                          _c("div", { staticClass: "col-xs-1-5" }, [
                            _vm._v(_vm._s((entry.f || 0).toFixed(1)))
                          ]),
                          _c("div", { staticClass: "col-xs-1-5" }, [
                            _vm._v(_vm._s((entry.m || 0).toFixed(1)))
                          ])
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "col-md-1-1" }, [
        _vm.collections
          ? _c("div", { staticClass: "shadow-light chart-box" }, [
              _c("div", { staticClass: "frow" }, [
                _c(
                  "div",
                  { staticClass: "col-md-1-2" },
                  [
                    _c("dashboard-graph", {
                      attrs: {
                        "graph-data": _vm.collections,
                        "current-collection": _vm.currentCollection,
                        "graph-type": "waistCirc",
                        title: "Waist Circumference"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-1-2" },
                  [
                    _c("dashboard-graph", {
                      attrs: {
                        "graph-data": _vm.collections,
                        "current-collection": _vm.currentCollection,
                        "graph-type": "triglycerides",
                        title: "Triglycerides"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-1-2" },
                  [
                    _c("dashboard-graph", {
                      attrs: {
                        "graph-data": _vm.collections,
                        "current-collection": _vm.currentCollection,
                        "graph-type": "bloodGlucose",
                        title: "Blood Glucose"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-1-2" },
                  [
                    _c("dashboard-graph", {
                      attrs: {
                        "graph-data": _vm.collections,
                        "current-collection": _vm.currentCollection,
                        "graph-type": "hdl",
                        title: "HDL"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-1-2" },
                  [
                    _c("dashboard-graph", {
                      attrs: {
                        "graph-data": _vm.collections,
                        "current-collection": _vm.currentCollection,
                        "graph-type": "systolicBP",
                        title: "Systolic Blood Pressure"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-1-2" },
                  [
                    _c("dashboard-graph", {
                      attrs: {
                        "graph-data": _vm.collections,
                        "current-collection": _vm.currentCollection,
                        "graph-type": "diastolicBP",
                        title: "Diastolic Blood Pressure"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1-2" }, [
      _c("div", { staticClass: "shadow-light chart-box" }, [
        _c("div", { staticClass: "frow" }, [
          _c("div", { staticClass: "col-md-1-2" }, [
            _c("div", { staticClass: "chart-title" }, [
              _vm._v("BMI Distribution")
            ]),
            _c("div", { staticClass: "frow" }, [
              _c("div", {
                staticClass: "bmi-distribution-chart ct-perfect-fourth",
                attrs: { id: "main-bmi-distribution-chart" }
              })
            ])
          ]),
          _c("div", { staticClass: "col-md-1-2" }, [
            _c("div", { staticClass: "chart-title" }, [_vm._v("BMI History")]),
            _c("div", { staticClass: "frow" }, [
              _c("div", {
                staticClass: "bmi-history-chart ct-perfect-fourth",
                attrs: { id: "main-bmi-history-chart" }
              })
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-1-2" }, [
      _c("div", { staticClass: "shadow-light chart-box" }, [
        _c("div", { staticClass: "frow" }, [
          _c("div", { staticClass: "col-md-1-2" }, [
            _c("div", { staticClass: "chart-title" }, [
              _vm._v("MetS Distribution")
            ]),
            _c("div", { staticClass: "frow" }, [
              _c("div", {
                staticClass: "mets-distribution-chart ct-perfect-fourth",
                attrs: { id: "main-mets-distribution-chart" }
              })
            ])
          ]),
          _c("div", { staticClass: "col-md-1-2" }, [
            _c("div", { staticClass: "chart-title" }, [_vm._v("MetS History")]),
            _c("div", { staticClass: "frow" }, [
              _c("div", {
                staticClass: "mets-history-chart ct-perfect-fourth",
                attrs: { id: "main-mets-history-chart" }
              })
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mean-boxed" }, [
      _c("div", { staticClass: "frow" }, [
        _c("div", { staticClass: "col-xs-2-5" }),
        _c("div", { staticClass: "col-xs-1-5" }, [_vm._v("All")]),
        _c("div", { staticClass: "col-xs-1-5" }, [_vm._v("Women")]),
        _c("div", { staticClass: "col-xs-1-5" }, [_vm._v("Men")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }