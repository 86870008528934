'use strict';

module.exports = {
  utcToHuman(time) {
    let date = time ? new Date(+time) : new Date();
    let mm = date.getUTCMonth() + 1;
    let dd = date.getUTCDate();
    let yyyy = date.getUTCFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    return `${mm}/${dd}/${yyyy}`;
  },
};
