let { mapActions, mapState } = require("../../store/sidebar-visible");

module.exports = {
  components: {
    logo: require("./logo-black.svg").default
  },
  computed: mapState(["visible"]),
  methods: Object.assign(
    {
      toggle() {
        if (this.visible) {
          this.hide();
        } else {
          this.show();
        }
      }
    },
    mapActions(["show", "hide"])
  )
};
