var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "admin-page" }, [
      _c("div", { staticClass: "frow centered" }, [
        _vm._v(
          "Please reach out to your company for access to the correct assessment."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }