'use strict';

let validate = require('begin-server/validate');
let fields = require('./fields');

module.exports = {
  height: {
    text: 'Height',
    validate: validate.any,
    description: fields.length.description,
    parse: fields.length.parse,
  },

  weight: {
    text: 'Weight',
    description: 'pounds',
    validate: validate.any,
    parse: fields.number.parse,
  },

  systolicBP: {
    text: 'Systolic BP',
    validate: validate.any,
    description: fields.number.description,
    parse: fields.number.parse,
  },

  diastolicBP: {
    text: 'Diastolic BP',
    validate: validate.any,
    description: fields.number.description,
    parse: fields.number.parse,
  },

  totalCholesterol: {
    text: 'Total Cholesterol',
    validate: validate.any,
    description: fields.number.description,
    parse: fields.number.parse,
  },

  ldlCholesterol: {
    text: 'LDL Cholesterol',
    validate: validate.any,
    description: fields.number.description,
    parse: fields.number.parse,
  },

  waistCirc: {
    text: 'Waist Circumference',
    description: 'inches',
    validate: validate.any,
    parse: fields.length.parse,
  },

  bloodGlucose: {
    text: 'Blood Glucose',
    validate: validate.any,
    description: fields.number.description,
    parse: fields.number.parse,
  },

  a1c: {
    text: 'A1C',
    validate: validate.any,
    description: fields.number.description,
    parse: fields.number.parse,
  },

  triglycerides: {
    text: 'Triglycerides',
    validate: validate.any,
    description: fields.number.description,
    parse: fields.number.parse,
  },

  hdl: {
    text: 'HDL',
    validate: validate.any,
    description: fields.number.description,
    parse: fields.number.parse,
  },

  nicotine: {
    text: 'Nicotine',
    validate: validate.any,
    description: fields.bool.description,
    parse: fields.bool.parse,
  },

  diagnosedWith: {
    text: 'Diagnosed With',
    validate: validate.any,
  },

  medications: {
    text: 'Medications',
    validate: validate.any,
  },

  /* TODO: not included in the API atm 07/28 */
  recentCheckups: {
    text: 'Recent Checkups',
    validate: validate.any,
  },

  /* TODO: not included in the API atm 07/28 */
  participation: {
    text: 'Participation',
    validate: validate.any,
    description: fields.bool.description,
    parse: fields.bool.parse,
  },

  readinessDiet: {
    text: 'Readiness Diet',
    validate: validate.any,
    description: fields.range.description,
    parse: fields.range.parse,
  },

  readinessPhysical: {
    text: 'Readiness Physical',
    validate: validate.any,
    description: fields.range.description,
    parse: fields.range.parse,
  },

  /* TODO: not included in API 07/28 */
  healthCoaching: {
    text: 'Health Coaching',
    validate: validate.any,
    description: fields.bool.description,
    parse: fields.bool.parse,
  },

  /* extra comments TODO: not included in API */
  notes: {
    text: 'Notes',
    validate: validate.any,
  },

  /* TODO: not included in API */
  department: {
    text: 'Department',
    validate: validate.any,
  },
};
