var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "developer-page" }, [
    _c("div", { staticClass: "frow" }, [
      _c("div", { staticClass: "col-md-2-3" }, [
        _c("div", { staticClass: "developer-api" }, [
          _c("div", { staticClass: "setting-card shadow-light" }, [
            _c("div", { staticClass: "card-title" }, [_vm._v("Your API key")]),
            _c(
              "textarea",
              {
                staticClass: "developer-key",
                attrs: { readonly: "" },
                on: {
                  click: function($event) {
                    return $event.target.select()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.key))]
            ),
            _c("div", { staticClass: "frow" }, [
              !_vm.confirmGenerateKey
                ? _c(
                    "a",
                    {
                      staticClass: "mt-15",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.confirmGenerateKey = true
                        }
                      }
                    },
                    [_vm._v("Generate New Key")]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "red-button mt-15",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.generateKey()
                        }
                      }
                    },
                    [_vm._v("Click Again To Confirm")]
                  )
            ])
          ])
        ])
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2-3" }, [
      _c("div", { staticClass: "developer-documentation" }, [
        _c("div", { staticClass: "setting-card full-height shadow-light" }, [
          _c("h6", [_vm._v("Last Updated on 03/26/2021")]),
          _vm._v(" "),
          _c("h2", [_vm._v("External API Usage")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v("Note: The API is "),
              _c("em", [_vm._v("only")]),
              _vm._v(" accessible via HTTPS")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Note: The API "),
              _c("em", [_vm._v("only")]),
              _vm._v(" accepts and sends data in JSON format")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Note: The API domain is "),
              _c("code", [_vm._v("api.wellnessdata.solutions")])
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("For technical support please email "),
              _c("a", { attrs: { href: "mailto:info@beg.in" } }, [
                _vm._v("info@beg.in")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("API Error Codes")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v("A response code of "),
              _c("code", [_vm._v("400")]),
              _vm._v(" for a bad request")
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Authenticating with the API")]),
          _vm._v(" "),
          _c("h4", [_vm._v("Obtaining an access token")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v("Access tokens are used for sending and retrieving data")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Obtain one using your API key in an Authorization header with the "
              ),
              _c("code", [_vm._v("Bearer")]),
              _vm._v(" Scheme")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Access tokens expire within 24 hours, so refresh your token daily"
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Expiration time is the number of seconds since the epoch in UTC"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample request")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token request-line" }, [
                _c("span", { staticClass: "token property" }, [_vm._v("GET")]),
                _vm._v(" /v1/external/access HTTP/1.1")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Host:")
              ]),
              _vm._v(" api.wellnessdata.solutions\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Authorization:")
              ]),
              _vm._v(" Bearer YOUR_API_KEY\n")
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample response")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token response-status" }, [
                _vm._v("HTTP/1.1 "),
                _c("span", { staticClass: "token property" }, [
                  _vm._v("200 OK")
                ])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Content-Type:")
              ]),
              _vm._v(" application/json; charset=utf-8\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"access"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"abc...123"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [_vm._v('"exp"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("1501388228")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Available API Routes")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Upon a successful handshake, the API will return an access token that will allow access these public API routes."
            )
          ]),
          _vm._v(" "),
          _c("h4", [_vm._v("Fetching Display Text")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v("The text content for all possible results of assessments")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "This should be cached for later use to hide and show the sections based on the request"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample request")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token request-line" }, [
                _c("span", { staticClass: "token property" }, [_vm._v("GET")]),
                _vm._v(" /v1/external/assessment HTTP/1.1")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Host:")
              ]),
              _vm._v(" api.wellnessdata.solutions\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Authorization:")
              ]),
              _vm._v(" Bearer YOUR_ACCESS_TOKEN\n")
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample response")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token response-status" }, [
                _vm._v("HTTP/1.1 "),
                _c("span", { staticClass: "token property" }, [
                  _vm._v("200 OK")
                ])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Content-Type:")
              ]),
              _vm._v(" application/json; charset=utf-8\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"sections"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"SECTION_KEY"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"title"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"SECTION_TITLE"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"content"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Bullet point 1 text"')
              ]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _vm._v("\n          "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Sub-bullet point 1"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n          "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Sub-bullet point 2"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n          ...\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Bullet point 2 text"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        ...\n      "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    ...\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"interventions"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"INTERVENTION_KEY"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Intervention bullet point text"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    ...\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("h4", [_vm._v("Sending Assessment Data")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v(
                "Returns an object with booleans of which text sections to display from the Display Text data retrieved in the previous route"
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "We recommend that you do not store any assessment data in accordance with HIPAA regulations"
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "If you have access to the aggregate data API you may include "
              ),
              _c("code", [_vm._v("organizationId")]),
              _vm._v(" in the request. This should be the "),
              _c("code", [_vm._v("id")]),
              _vm._v(" of one of your children organizations.")
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample request")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token request-line" }, [
                _c("span", { staticClass: "token property" }, [_vm._v("POST")]),
                _vm._v(" /v1/external/assessment HTTP/1.1")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Host:")
              ]),
              _vm._v(" api.wellnessdata.solutions\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Authorization:")
              ]),
              _vm._v(" Bearer YOUR_ACCESS_TOKEN\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"firstName"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [_vm._v('"Jane"')]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"lastName"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [_vm._v('"Doe"')]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [_vm._v('"sex"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"email"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"jdoe@example.com"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"ethnicity"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [_vm._v('"White"')]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"dateOfBirth"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"01/01/1990"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"phone"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"8888888888"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"spouse"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"height"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("69")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"weight"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("140")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"systolicBP"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("120")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diastolicBP"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("80")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"waistCirc"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("30")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucose"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("80")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [_vm._v('"a1c"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token null keyword" }, [
                _vm._v("null")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"triglycerides"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("110")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [_vm._v('"hdl"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("60")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"ldlCholesterol"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("10")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"totalCholesterol"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("20")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"nicotine"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diagnosedWith"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"heartDisease"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"hypertension"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diabetes"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"cancer"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"medications"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"cholesterol"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodPressure"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diabetes"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"asthma"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"depression"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"betaBlocker"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"recentCheckups"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"dental"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"mammogram"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"cervicalCancer"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"colorectalCancer"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"fluVaccination"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"participation"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"readinessDiet"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0.5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"confidenceDiet"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0.5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"readinessPhysical"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0.5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"confidencePhysical"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0.5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"readinessLifestyle"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0.5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"confidenceLifestyle"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0.5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"healthCoaching"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"physicianConsult"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"oneTimeConsult"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"notes"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Some text notes"')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample response")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token response-status" }, [
                _vm._v("HTTP/1.1 "),
                _c("span", { staticClass: "token property" }, [
                  _vm._v("200 OK")
                ])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Content-Type:")
              ]),
              _vm._v(" application/json; charset=utf-8\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"display"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"menAndWomen50to75"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"menAndWomen40to65"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"menAndWomenHealthScreening"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"women40to74"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"women21to65"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"menOver49"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"smokers"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"heartDiseaseOr3Over5"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"thoseWithDiabetes"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"menAndWomenUnder26Vaccines"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"menAndWomenVaccines"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"menAndWomenOver60Vaccines"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"menAndWomenOver65Vaccines"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"nicotine"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"triglycerides"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [_vm._v('"hdl"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"waistCirc"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diabetes1"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diabetes2"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diabetes3"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucose100to125"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucoseOver125"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"systolicBp131to140"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"systolicBpOver140OrDiastolicBpOver90"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodPressureAndBloodGlucose"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodPressureAndHdl"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"waistCircumferenceAndBloodPressure"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodPressureAndTriglycerides"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"hdlAndBloodGlucose"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"hdlAndTriglycerides"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucoseAndTriglycerides"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"hdlAndWaistCircumference"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucoseAndWaistCircumference"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"waistCircumferenceAndTriglycerides"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"hdlAndTriglyceridesAndBloodGlucose"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsScoreOver2"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"medicationAdherence"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("true")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucoseOver126AndUnder180"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucoseOver179"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"triglyceridesOver499"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"tobaccoResource"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diabetesResource"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token boolean" }, [_vm._v("false")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"calculations"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [_vm._v('"bmi"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("20.67212770426381")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bmiScore"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [_vm._v('"mets"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"embed"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"abc...123"')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Display Assessment Results in an iFrame")]),
          _vm._v(" "),
          _c("ol", [
            _c("li", [_vm._v("Collect an API key from above")]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Make the API key available to your server (this should be kept secret)"
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Install the embed API in your client side code - available as an npm package at @beg.in/wds-embed or by using the jsDelivr CDN with a script tag using the following URL: "
              ),
              _c("code", [
                _vm._v("https://cdn.jsdelivr.net/npm/@beg.in/wds-embed")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Retrieve the api with require/import (if installed via npm) from "
              ),
              _c("code", [_vm._v("@beg.in/wds-embed")]),
              _vm._v(" or by using the global variable "),
              _c("code", [_vm._v("WDS")]),
              _vm._v(" (if installed via cdn).")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Create an iframe with the source "),
              _c("code", [_vm._v("https://wellnessdata.solutions/embed.html")])
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Obtain a reference to the iframe you just created.")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Before the user is ready to be shown the WDS Assessment Embed form first send a GET request to "
              ),
              _c("code", [
                _vm._v("https://api.wellnessdata.solutions/v1/external/embed")
              ]),
              _vm._v(" with an Authorization header with the value of "),
              _c("code", [_vm._v("Bearer YOUR_API_KEY")]),
              _vm._v(". Include a query string parameter called "),
              _c("code", [_vm._v("access")]),
              _vm._v(" with the "),
              _c("code", [_vm._v("embed")]),
              _vm._v(" token returned from "),
              _c("code", [_vm._v("/assessment")]),
              _vm._v(".")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "The GET request will respond with a JSON object containing a single parameter "
              ),
              _c("code", [_vm._v("request")]),
              _vm._v(".")
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Send this request value to your client (these should be unique per individual assessment)."
              )
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "In your client-side script call the JS API you obtained a reference to as a function with an "
              ),
              _c("code", [_vm._v("Object")]),
              _vm._v(
                " as the only argument. The parameters you should pass are "
              ),
              _c("code", [_vm._v("element")]),
              _vm._v(
                " - a reference to the DOM element of the WDS embed iframe you created in step 5, (obtained via getElementById or similar), "
              ),
              _c("code", [_vm._v("request")]),
              _vm._v(
                " - the value of the request parameter sent from the GET request described in step 7, "
              ),
              _c("code", [_vm._v("onComplete")]),
              _vm._v(
                " - a callback function to run when the user has submitted the assessment form, and "
              ),
              _c("code", [_vm._v("onError")]),
              _vm._v(" - an optional callback when an error occurs.")
            ])
          ]),
          _vm._v(" "),
          _c("pre", [_c("code", [_vm._v("\n")])]),
          _vm._v(" "),
          _c("h2", [_vm._v("Aggregate Data")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "If you have the aggregate data plan with children companies setup, you can retrive the aggregate data as so."
            )
          ]),
          _vm._v(" "),
          _c("h3", [
            _vm._v("Make a request to retrieve your children companies")
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample Request")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token request-line" }, [
                _c("span", { staticClass: "token property" }, [_vm._v("GET")]),
                _vm._v(" /v1/external/aggregate/children HTTP/1.1")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Host:")
              ]),
              _vm._v(" api.wellnessdata.solutions\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Authorization:")
              ]),
              _vm._v(" Bearer YOUR_ACCESS_TOKEN\n")
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample Response")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token response-status" }, [
                _vm._v("HTTP/1.1 "),
                _c("span", { staticClass: "token property" }, [
                  _vm._v("200 OK")
                ])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Content-Type:")
              ]),
              _vm._v(" application/json; charset=utf-8\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [_vm._v('"id"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"jHux2SGLmJDwrqan"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [_vm._v('"name"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Langosh Group"')
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [_vm._v('"id"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"VEPSIEZ-CQrjnw3_"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [_vm._v('"name"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Reichert - Klein"')
              ]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    ...\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", [_c("code")]),
          _vm._v(" "),
          _c("h3", [
            _vm._v("Use the id from the previous result to get aggregate data")
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample Request")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token request-line" }, [
                _c("span", { staticClass: "token property" }, [_vm._v("GET")]),
                _vm._v(" /v1/external/aggregate/CHILDS_ID HTTP/1.1")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Host:")
              ]),
              _vm._v(" api.wellnessdata.solutions\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Authorization:")
              ]),
              _vm._v(" Bearer YOUR_ACCESS_TOKEN\n")
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample Response")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token response-status" }, [
                _vm._v("HTTP/1.1 "),
                _c("span", { staticClass: "token property" }, [
                  _vm._v("200 OK")
                ])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Content-Type:")
              ]),
              _vm._v(" application/json; charset=utf-8\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"title"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"recusandae-ipsam-ipsa"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"location"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"023 Rodriguez Terrace"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"created"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("1593247465065")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"count"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("50")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"healthy"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("10")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"obese"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("10")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"underweight"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("13")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"overweight"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("4")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"morbidlyObese"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("13")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"incomplete"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"notParticipating"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("22")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"scoreTable"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"0"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"0"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"1"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"2"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"3"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("2")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"4"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"5"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"1"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"0"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"1"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"2"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"3"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"4"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("2")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"5"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"2"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"0"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"1"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"2"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("2")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"3"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("2")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"4"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"5"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"3"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"0"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"1"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"2"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"3"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("3")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"4"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("6")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"5"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("3")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"-1"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"0"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"1"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"2"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("2")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"3"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"4"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("3")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"5"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("3")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsFactor0"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsFactor1"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsFactor2"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("6")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsFactor3"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("13")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsFactor4"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("21")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsFactor5"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("9")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"waistCirc"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"green"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("20")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"yellow"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("9")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"red"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("7")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"darkred"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("14")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [_vm._v('"hdl"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"green"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("33")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"yellow"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("13")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"red"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("4")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"darkred"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"systolicBP"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"green"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("18")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"yellow"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("11")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"red"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("20")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"darkred"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diastolicBP"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"green"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("22")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"yellow"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("11")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"red"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("13")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"darkred"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("4")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"triglycerides"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"green"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("0")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"yellow"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("8")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"red"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("31")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"darkred"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("11")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucose"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"green"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("6")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"yellow"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("9")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"red"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("9")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"darkred"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("26")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [_vm._v('"mean"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"age"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("53.125")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("45.61538461538461")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("49.22")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"height"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("74.25")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("69.96153846153847")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("72.02")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"weight"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("236.70833333333334")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("191.73076923076923")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("213.32")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"bmi"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("31.681961414514586")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("28.289668025912988")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("29.91796885244175")
              ]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bmiScore"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("1.1666666666666667")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("0.8461538461538461")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"waistCircumference"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("39.041666666666664")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("41.11538461538461")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("40.12")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"systolicBP"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("133.25")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("132.34615384615384")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("132.78")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"diastolicBP"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("85.70833333333333")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("84.5")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("85.08")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"triglycerides"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("404.625")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("346.03846153846155")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("374.16")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [_vm._v('"hdl"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("49.583333333333336")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("51.61538461538461")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("50.64")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bloodGlucose"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("150.54166666666666")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("147.03846153846155")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("148.72")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsScore"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"m"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [
                _vm._v("3.2083333333333335")
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"f"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("4")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n                "),
              _c("span", { staticClass: "token property" }, [_vm._v('"all"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("3.62")]),
              _vm._v("\n            "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"bmiAvg"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("1")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n        "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"metsAvg"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("3.62")]),
              _vm._v("\n      "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    ...\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", [_c("code", [_vm._v("\n")])]),
          _vm._v(" "),
          _c("h3", [_vm._v("Use a child id to get aggregate risk")]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample Request")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token request-line" }, [
                _c("span", { staticClass: "token property" }, [_vm._v("GET")]),
                _vm._v(" /v1/external/aggregate/riskScores/CHILDS_ID HTTP/1.1")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Host:")
              ]),
              _vm._v(" api.wellnessdata.solutions\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Authorization:")
              ]),
              _vm._v(" Bearer YOUR_ACCESS_TOKEN\n")
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample Response")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token response-status" }, [
                _vm._v("HTTP/1.1 "),
                _c("span", { staticClass: "token property" }, [
                  _vm._v("200 OK")
                ])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Content-Type:")
              ]),
              _vm._v(" application/json; charset=utf-8\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("[")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"person"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [_vm._v('"abc..."')]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [_vm._v('"risk"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token number" }, [_vm._v("98")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  ...\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("]")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", [_c("code", [_vm._v("\n")])]),
          _vm._v(" "),
          _c("h2", [_vm._v("Creating Child Organizations")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "If you have the child organization data plan, you can create children companies to keep track of your assessments."
            )
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Create a child organization")]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample request")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token request-line" }, [
                _c("span", { staticClass: "token property" }, [_vm._v("POST")]),
                _vm._v(" /v1/external/createChild HTTP/1.1")
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Host:")
              ]),
              _vm._v(" api.wellnessdata.solutions\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Authorization:")
              ]),
              _vm._v(" Bearer YOUR_ACCESS_TOKEN\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [_vm._v('"name"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Company A"')
              ]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n  "),
              _c("span", { staticClass: "token property" }, [
                _vm._v('"contactEmail"')
              ]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"person@companya.com"')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("p", [_c("strong", [_vm._v("Sample Response")])]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-http" }, [
            _c("code", { staticClass: "language-http" }, [
              _c("span", { staticClass: "token response-status" }, [
                _vm._v("HTTP/1.1 "),
                _c("span", { staticClass: "token property" }, [
                  _vm._v("200 OK")
                ])
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token header-name keyword" }, [
                _vm._v("Content-Type:")
              ]),
              _vm._v(" application/json; charset=utf-8\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", { staticClass: "language-json" }, [
            _c("code", { staticClass: "language-json" }, [
              _c("span", { staticClass: "token punctuation" }, [_vm._v("{")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [_vm._v('"id"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [_vm._v('"abc..."')]),
              _c("span", { staticClass: "token punctuation" }, [_vm._v(",")]),
              _vm._v("\n    "),
              _c("span", { staticClass: "token property" }, [_vm._v('"name"')]),
              _c("span", { staticClass: "token operator" }, [_vm._v(":")]),
              _vm._v(" "),
              _c("span", { staticClass: "token string" }, [
                _vm._v('"Company A"')
              ]),
              _vm._v("\n"),
              _c("span", { staticClass: "token punctuation" }, [_vm._v("}")]),
              _vm._v("\n")
            ])
          ]),
          _vm._v(" "),
          _c("pre", [_c("code", [_vm._v("\n")])]),
          _vm._v(" "),
          _c("h2", [_vm._v("Form Properties")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Name")]),
                _vm._v(" "),
                _c("th", [_vm._v("Type")]),
                _vm._v(" "),
                _c("th", [_vm._v("Description")])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("firstName")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("The person's first name")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("lastName")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("Last name of the person")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("sex")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("The sex of the person for this assessment, either "),
                  _c("code", [_vm._v("'m'")]),
                  _vm._v(" or "),
                  _c("code", [_vm._v("'f'")])
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("email")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Email address for the person entered as a string")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("ethnicity")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Must be one of the following: "),
                  _c("code", [_vm._v("'White'")]),
                  _vm._v(", "),
                  _c("code", [_vm._v("'Black or African American'")]),
                  _vm._v(", "),
                  _c("code", [_vm._v("'American Indian'")]),
                  _vm._v(", "),
                  _c("code", [_vm._v("'Alaska Native'")]),
                  _vm._v(", "),
                  _c("code", [_vm._v("'Asian'")]),
                  _vm._v(", "),
                  _c("code", [_vm._v("'Hispanic/Latino'")]),
                  _vm._v(", "),
                  _c("code", [
                    _vm._v("'Native Hawaiian/Other Pacific Islander'")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("dateOfBirth")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Date in format of "),
                  _c("code", [_vm._v("mm/dd/yyyy")])
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("phone")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Phone number as "),
                  _c("code", [_vm._v("123456789")])
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("height")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("How tall the person is in total inches")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("weight")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("How much the person weighs in pounds")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("systolicBP")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Measure of the pressure in your blood vessels when your heart beats"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("diastolicBP")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Measure of the pressure in your blood vessels when your heart rests between beats"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("waistCirc")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("Measurement of waist in total inches")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodGlucose")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("A measurement of glucose in blood")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("a1c")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("The level of blood sugar")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("triglycerides")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("The level of triglycerides in blood")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("hdl")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("The level of HDL in blood")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("ldlCholesterol")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("LDL Cholesterol")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("totalCholesterol")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [_vm._v("Total Cholesterol")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("nicotine")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("A boolean for whether a person is a smoker/nonsmoker")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("diagnosedWith")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Object")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("In format of: "),
                  _c("code", [
                    _vm._v(
                      "{ heartDisease: null, hypertension: true, diabetes: null, cancer: null }"
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("medications")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Object")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("In format of: "),
                  _c("code", [
                    _vm._v(
                      "{ cholesterol: null, bloodPressure: true, diabetes: null}"
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("readinessDiet")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Readiness to make diet and nutrition change as either "
                  ),
                  _c("code", [_vm._v("0")]),
                  _vm._v(" for low, "),
                  _c("code", [_vm._v("0.5")]),
                  _vm._v(" for medium, and "),
                  _c("code", [_vm._v("1")]),
                  _vm._v(" for high")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("readinessPhysical")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("String")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Readiness to make physical activity changes as either "
                  ),
                  _c("code", [_vm._v("0")]),
                  _vm._v(" for low, "),
                  _c("code", [_vm._v("0.5")]),
                  _vm._v(" for medium, and "),
                  _c("code", [_vm._v("1")]),
                  _vm._v(" for high")
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("Response")]),
          _vm._v(" "),
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Name")]),
                _vm._v(" "),
                _c("th", [_vm._v("Type")]),
                _vm._v(" "),
                _c("th", [_vm._v("Description")])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v("calcHdl")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Integer")])]),
                _vm._v(" "),
                _c("td", [_vm._v("The user's calculated HDL")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("calcBloodGlucose")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Integer")])]),
                _vm._v(" "),
                _c("td", [_vm._v("The user's calculated Blood Glucose")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("calcBloodPressure")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Integer")])]),
                _vm._v(" "),
                _c("td", [_vm._v("The user's calculated Blood Pressure")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("menAndWomen50to75")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the menAndWomen50to75 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("menAndWomen40to65")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the menAndWomen40to65 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("menAndWomenHealthScreening")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the menAndWomenHealthScreening text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("women40to74")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the women40to74 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("women21to65")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the women21to65 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("menOver49")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the menOver49 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("smokers")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [_vm._v("Whether to display the smokers text block")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("heartDiseaseOr3Over5")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the heartDiseaseOr3Over5 text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("thoseWithDiabetes:")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the thoseWithDiabetes: text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("menAndWomenUnder26Vaccines")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the menAndWomenUnder26Vaccines text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("menAndWomenVaccines:")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the menAndWomenVaccines text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("menAndWomenOver60Vaccines:")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the menAndWomenOver60Vaccines text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("menAndWomenOver65Vaccines:")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the menAndWomenOver65Vaccines text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("nicotine")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [_vm._v("Whether to display the nicotine text block")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("triglycerides")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the triglycerides text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("hdl")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [_vm._v("Whether to display the HDL text block")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("waistCirc")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the waistCirc text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("diabetes1")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the diabetes1 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("diabetes2")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the diabetes2 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("diabetes3")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the diabetes3 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodGlucose100to125")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodGlucose100to125 text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodGlucoseOver125")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodGlucoseOver125 text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("systolicBp131to140")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the systolicBp131to140 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("systolicBpOver140OrDiastolicBpOver90")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the systolicBpOver140OrDiastolicBpOver90 text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodPressureAndBloodGlucose")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodPressureAndBloodGlucose text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodPressureAndHdl")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodPressureAndHdl text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("waistCircumferenceAndBloodPressure")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the waistCircumferenceAndBloodPressure text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodPressureAndTriglycerides")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodPressureAndTriglycerides text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("hdlAndBloodGlucose")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the hdlAndBloodGlucose text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("hdlAndTriglycerides")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the hdlAndTriglycerides text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodGlucoseAndTriglycerides")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodGlucoseAndTriglycerides text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("hdlAndWaistCircumference")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the hdlAndWaistCircumference text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodGlucoseAndWaistCircumference")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodGlucoseAndWaistCircumference text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("waistCircumferenceAndTriglycerides")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the waistCircumferenceAndTriglycerides text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("hdlAndTriglyceridesAndBloodGlucose")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the hdlAndTriglyceridesAndBloodGlucose text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("metsScoreOver2")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the metsScoreOver2 text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("medicationAdherence")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the medicationAdherence text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodGlucoseOver126AndUnder180")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodGlucoseOver126AndUnder180 text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("bloodGlucoseOver179")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the bloodGlucoseOver179 text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("triglyceridesOver499")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "Whether to display the triglyceridesOver499 text block"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("tobaccoResource")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the tobaccoResource text block")
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("diabetesResource")]),
                _vm._v(" "),
                _c("td", [_c("code", [_vm._v("Boolean")])]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("Whether to display the diabetesResource text block")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }