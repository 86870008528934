let api = require('begin-build/api');
let { priority, calcBmi } = require('../../shared/calculations');
let calculations = require('../../shared/calculations')
let { fromRecord: risk } = require('../../shared/risk');
let { mapGetters} = require('../store/auth');

const FILTERS = [
  { name: 'Obese', fn: ({ bmiScore }) => bmiScore === 2, cell: 'bmi' },
  { name: 'Morbidly Obese', fn: ({ bmiScore }) => bmiScore === 3, cell: 'bmi' },
  { name: 'Blood Glucose', fn: ({ bloodGlucose }) => ~~bloodGlucose >= 100, cell: 'bloodGlucose' },
  { name: 'HDL Cholesterol', fn: ({ hdl, sex }) => ~~hdl >= (sex === 'f' ? 40 : 30), cell: 'hdl' },
  { name: 'Triglycerides', fn: ({ triglycerides }) => ~~triglycerides >= 150, cell: 'triglycerides' },
  { name: 'Blood Pressure', fn: ({ systolicBP, diastolicBP }) => ~~systolicBP >= 130 && ~~diastolicBP >= 85, cell: 'systolicBP' },
  { name: 'Heart Disease', fn: ({ diagnosedWith }) => diagnosedWith && diagnosedWith.heartDisease, cell: 'diagnosedWith' },
  { name: 'Hypertension', fn: ({ diagnosedWith }) => diagnosedWith && diagnosedWith.hypertension, cell: 'diagnosedWith' },
  { name: 'Diabetes', fn: ({ diagnosedWith }) => diagnosedWith && diagnosedWith.diabetes, cell: 'diagnosedWith' },
  { name: 'Cancer', fn: ({ diagnosedWith }) => diagnosedWith && diagnosedWith.cancer, cell: 'diagnosedWith' },
  { name: 'Cholesterol Meds', fn: ({ medications }) => medications && medications.cholesterol, cell: 'medications' },
  { name: 'Blood Pressure Meds', fn: ({ medications }) => medications && medications.bloodPressure, cell: 'medications' },
  { name: 'Diabetes Meds', fn: ({ medications }) => medications && medications.diabetes, cell: 'medications' },
  { name: 'Asthma Meds', fn: ({ medications }) => medications && medications.asthma, cell: 'medications' },
  { name: 'Depression Meds', fn: ({ medications }) => medications && medications.depression, cell: 'medications' },
  { name: 'Beta Blocker Meds', fn: ({ medications }) => medications && medications.betaBlocker, cell: 'medications' },
  { name: 'Metabolic Syndrome', fn: ({ metsScore }) => ~~metsScore >= 3, cell: null },
  { name: 'Critical Value: Glucose', fn: ({ bloodGlucose }) => ~~bloodGlucose >= 180, cell: 'bloodGlucose' },
  { name: 'Critical Value: Triglycerides', fn: ({ triglycerides }) => ~~triglycerides >= 500, cell: 'triglycerides' },
  { name: 'Critical Value: Blood Pressure', fn: ({ systolicBP, diastolicBP }) => ~~systolicBP >= 180 && ~~diastolicBP >= 110, cell: 'systolicBP' },
];

module.exports = {
  components: {
    record: require('./record/vue.pug').default,
  },

  data: () => ({
    selectedFilter: null,
    selectedSort: 'name',
    reverse: false,
    records: [],
    currentCollection: 0,
    collections: null
  }),

  computed: Object.assign({
    filters() {
      return FILTERS;
    },

    cellVariable() {
      if (this.selectedFilter) {
        return this.selectedFilter.cell;
      }
      return null;
    },

    mapped() {
      return this.records.map(record => Object.assign({
        bmi: calcBmi(record.weight, record.height).toFixed(1),
        priority: priority(record),
        risk: calculations.calcAdvancedRiskScore(record),
      }, record));
    },

    rows() {
      if (this.selectedFilter) {
        return this.mapped.filter(this.selectedFilter.fn).map(record => {
          if (this.cellVariable) {
            return Object.assign(record, { filteredVariable: record[this.cellVariable] });
          }
          return record;
        });
      }
      return this.mapped;
    },

    sort() {
      if (this.selectedSort === 'name') {
        return ({ lastName: a }, { lastName: b }) => {
          a = a.toUpperCase();
          b = b.toUpperCase();
          if (a < b) {
            return -1;
          }
          if (b < a) {
            return 1;
          }
          return 0;
        };
      } else if (this.selectedSort === 'risk') {
        return ({ [this.selectedSort]: a }, { [this.selectedSort]: b }) => a - b;
      }
      return ({ [this.selectedSort]: a }, { [this.selectedSort]: b }) => ~~a - ~~b;
    },

    sorted() {
      let sorted = this.rows.sort(this.sort);
      if (this.reverse) {
        sorted = sorted.reverse();
      }
      return sorted;
    },
  }, mapGetters(['getRole'])),

  methods: {
    sortFilter() {
      if (this.selectedFilter && this.selectedFilter.cell) {
        if (this.selectedFilter.cell !== this.selectedSort) {
          this.reverse = false;
          this.selectedSort = this.selectedFilter.cell;
        } else {
          this.reverse = !this.reverse;
        }
      } else {
        this.reverse = false;
        this.selectedSort = 'name';
      }
    },

    setSort(filter) {
      if (this.selectedSort !== filter) {
        this.reverse = false;
        this.selectedSort = filter;
      } else {
        this.reverse = !this.reverse;
      }
    },

    async getCollections() {
      let { data } = await api.get(`collection/organization/${this.$route.params.id}`, {});
      this.collections = data;
    },

    async setCurrentCollection(collection) {
      let { data } = await api.get(`report/collection/${this.$route.params.id}/${this.collections[collection]._id}`, {});
      this.records = data;
    }
  },
  async mounted() {
    let { data } = await api.get(`report/collection/latest/${this.$route.params.id || ''}`);
    this.records = data;
    this.getCollections();
  },
};
