let marked = require('marked');
let api = require('begin-build/api');
let debounce = require('begin-util/debounce');
let { mapActions: mapAlertsActions } = require('begin-build/alerts');
let { mapGetters, mapActions } = require('../../store/articles');

module.exports = {
  data: () => ({
    confirmDelete: false,
  }),

  computed: Object.assign({
    compiledMarkdown() {
      return marked(this.activeArticle.text, { sanitize: true });
    },
  }, mapGetters(['articles', 'activeArticle'])),

  methods: Object.assign({
    update: debounce(function (e) {
      this.activeArticle.text = e.target.value;
    }),

    async saveArticle() {
      this.confirmDelete = false;
      if (this.activeArticle._id) {
        let article = Object.assign({}, this.activeArticle);
        delete article.children;
        if (article.parent == null) {
          delete article.parent;
        }
        try {
          await api.put(`article`, article);
          this.success('Article updated');
          this.$emit('saved');
          // this.fetchArticles();
        } catch (e) {
          this.error(e);
        }
      } else {
        try {
          await api.post('article', this.activeArticle);
          this.$emit('saved');
          this.success('Article created');
          this.setDefaultArticle();
        } catch (e) {
          this.error(e);
        }
      }
    },
    async deleteArticle() {
      this.confirmDelete = false;
      if (this.activeArticle.children.length > 0) {
        this.error('Must delete children before deleting parent');
        return;
      }
      await api.delete(`article/${this.activeArticle._id}`);
      this.success('Article deleted');
      this.$emit('saved');
    },
    updateTitle(e) {
      this.activeArticle.title = e.target.value;
    },
  }, mapAlertsActions(['error', 'success']), mapActions(['fetchArticles', 'setDefaultArticle'])),
};
