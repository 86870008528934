var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeArticle
    ? _c("div", { attrs: { id: "editor" } }, [
        _c("div", { staticClass: "frow height-100" }, [
          _c("div", { staticClass: "col-md-1-2 height-100" }, [
            _c("div", { staticClass: "left-column height-100" }, [
              _c("div", { staticClass: "frow column-start height-100" }, [
                _c("label", { staticClass: "row-start width-100" }, [
                  _vm._v("Parent"),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.activeArticle.parent,
                          expression: "activeArticle.parent"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.activeArticle,
                            "parent",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    _vm._l(_vm.articles, function(article) {
                      return _c(
                        "option",
                        { domProps: { value: article._id } },
                        [_vm._v(_vm._s(article.title))]
                      )
                    }),
                    0
                  )
                ]),
                _c("label", { staticClass: "row-start width-100" }, [
                  _vm._v("Title"),
                  _c("input", {
                    attrs: { type: "text", placeholder: "Article Title" },
                    domProps: { value: _vm.activeArticle.title },
                    on: { blur: _vm.updateTitle }
                  })
                ]),
                _c("div", { staticClass: "frow gutters width-100" }, [
                  _c("div", { staticClass: "col-xs-1-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "width-100 mb-15",
                        on: {
                          click: function($event) {
                            return _vm.saveArticle()
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ]),
                  _c("div", { staticClass: "col-xs-1-2" }, [
                    !_vm.confirmDelete
                      ? _c(
                          "button",
                          {
                            staticClass: "width-100 mb-15 delete",
                            on: {
                              click: function($event) {
                                _vm.confirmDelete = true
                              }
                            }
                          },
                          [_vm._v("Delete")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "width-100 mb-15 delete confirm",
                            on: {
                              click: function($event) {
                                return _vm.deleteArticle()
                              }
                            }
                          },
                          [_vm._v("Click to Confirm")]
                        )
                  ])
                ]),
                _c("textarea", {
                  domProps: { value: _vm.activeArticle.text },
                  on: { input: _vm.update }
                })
              ])
            ])
          ]),
          _c("div", { staticClass: "col-md-1-2 height-100" }, [
            _c("div", {
              staticClass: "editor-output height-100",
              domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) }
            })
          ])
        ])
      ])
    : _c("div", { staticClass: "frow" }, [
        _vm._v("Select an article or create a new one")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }