var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("div", { staticClass: "chart-title" }, [_vm._v(_vm._s(_vm.title))]),
    _c("div", { staticClass: "frow" }, [
      _c("div", { staticClass: "col-md-1-2" }, [
        _c("div", {
          staticClass: "distribution-chart ct-perfect-fourth",
          attrs: {
            id: "bmi-distribution-chart",
            id: _vm.graphType + "-distribution-chart"
          }
        })
      ]),
      _c("div", { staticClass: "col-md-1-2" }, [
        _c("div", {
          staticClass: "history-chart ct-perfect-fourth",
          attrs: {
            id: "bmi-history-chart",
            id: _vm.graphType + "-history-chart"
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }