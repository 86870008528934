let Vue = require('vue');
let { mapActions } = require('begin-build/alerts');
let api = require('begin-build/api');
let { TABLE, calculateCell } = require('../../shared/plan');

const COLUMNS = ['', 0, 1, 2, 3, 4, 5];
const ROWS = ['-1', 0, 1, 2, 3];

module.exports = {
  data: () => ({
    table: [],
    heatMap: false,
    name: null,
    description: null,
    collections: null,
    overrides: Object.assign({}, Array(30).fill(null), { 6: 0, 29: 0 }),
    editingCell: null,
    editCell: null,
    plans: null,
    selectedPlan: null,
  }),

  methods: Object.assign({
    setCollection(index = 0) {
      let { scoreTable } = this.collections[index];
      let min;
      let max;
      this.table = COLUMNS.map(title => ({ title }));
      this.table = this.table.concat(ROWS.reduce((cells, title) =>
        cells.concat([{ title }], Object.entries(scoreTable[title]).map(([column, count]) => {
          if (min === undefined || count < min) {
            min = count;
          }
          if (max === undefined || count > max) {
            max = count;
          }
          let cell = TABLE[title][column];
          return Object.assign({}, cell, { count });
        })), []).map(cell => {
        if (cell.title === undefined) {
          cell.heatMapStyle = {
            'background-color': `rgb(255,${
              Math.round((1 - ((cell.count - min) / (max - min))) * 255)
            },0)`,
          };
        }
        return cell;
      }));
    },

    setCell(index) {
      index -= COLUMNS.length;
      let j = index % COLUMNS.length;
      let i = ((index - j) / COLUMNS.length);
      index = (6 * i) + (j - 1);
      this.overrides[index] = +this.editCell;
      this.editingCell = null;
      this.editCell = null;
    },

    async saveCells() {
      try {
        await api.post('plan', {
          name: this.name,
          description: this.description,
          table: this.overrides,
          organization: this.$route.params.id,
          _id: this._id,
        });
        this.success('Plan was saved');
        this.changePlan(null);
      } catch (e) {
        this.error(e);
      }
    },

    async getPlans(plan) {
      if (this.$route.params.id) {
        let { data } = await api.get(`plan/${this.$route.params.id}`);
        this.plans = data;
        if (plan) {
          this.overrides = Object.assign({}, Array(30).fill(null), this.plans[plan].table);
          this.name = data[plan].name;
          this._id = data[plan]._id;
          this.description = data[plan].description;
        } else {
          this.overrides = Object.assign({}, Array(30).fill(null));
          this.name = null;
          this._id = null;
          this.description = null;
          this.selectedPlan = null;
        }
      }
    },

    changePlan(plan) {
      if (plan === null) {
        this.getPlans();
      } else {
        this.getPlans(plan);
      }
    },

    focusInput(index) {
      this.editingCell = index;
      Vue.nextTick(() => {
        this.$refs.activeInput[0].focus();
      });
    },

    calculateCell,
  }, mapActions(['error', 'success'])),

  computed: {
    display() {
      return [...Array(30).keys()].reduce((out, i) => {
        let j = i % 6;
        out[COLUMNS.length + (COLUMNS.length * ((i - j) / 6)) + (j + 1)] = this.overrides[i];
        return out;
      }, {});
    },
  },

  async created() {
    // await collection.ready; // TODO: This IS the problem on master only
    if (this.$route.params.id) {
      let { data } = await api.get(`collection/aggregate/${this.$route.params.id}`);
      this.collections = data;
      // this.heatMap = true;
    }
    this.setCollection();
    this.getPlans();
  },
};
