let articleHelper = require('../../shared/articles');
let { mapGetters, mapActions } = require('../store/articles');

module.exports = {
  components: {
    editor: require('./editor/vue.pug').default,
    tree: require('./tree/vue.pug').default,
  },

  data: () => ({
    articleContainer: {
      title: 'Articles',
      text: '',
      children: [],
    },
  }),

  computed: mapGetters(['articles', 'activeArticle']),

  methods: Object.assign({
    setArticle(article) {
      this.activeArticle = article;
    },

    async refreshArticles() {
      await this.fetchArticles();
      this.articleContainer.children = articleHelper.parseArticles(this.articles);
    },
  }, mapActions(['fetchArticles', 'setDefaultArticle'])),

  async created() {
    await this.fetchArticles();
    this.articleContainer.children = articleHelper.parseArticles(this.articles);
  },
};
