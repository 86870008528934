let { mapActions } = require('begin-build/alerts');
let api = require('begin-build/api');
let { mapGetters } = require('../store/auth');

module.exports = {
  data: () => ({
    companyDataType: '',
    currentOrganization: {
      logo: null,
      resource: {
        companyResource: {},
        generalResource: {},
        tobaccoResource: {},
        fitnessResource: {},
        coachingResource: {},
        diabetesResource: {},
        heartResource: {},
      },
    },
    addData: false,
    addResource: false,
    activeData: [],
    newData: {
      title: null,
      location: null,
      description: null,
      organization: null,
      inactive: null,
    },
    newResource: {
      name: null,
      website: null,
      organization: null,
    },
    removed: false,
    collections: [],
    departments: [],
    plans: [],
    saving: false,
  }),

  async mounted() {
    this.changeCompanyDataType('collections');
    if (this.$route.params.id || this.getProfile.organization) {
      let { data } = await api.get(`organization/${
        this.$route.params.id || this.getProfile.organization
      }`);
      this.currentOrganization = data;
      this.currentOrganization.resource = this.currentOrganization.resource || {
        companyResource: {},
        generalResource: {},
        tobaccoResource: {},
        fitnessResource: {},
        coachingResource: {},
        diabetesResource: {},
        heartResource: {},
      };
    } else {
      this.error('No Organization');
    }
    this.getCollections();
    this.getDepartments();
    this.getPlans();
  },

  computed: mapGetters(['getRole', 'getProfile']),

  methods: Object.assign({
    uploadLogo(e) {
      if (window.FileReader) {
        let file = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onprogress = this.updateProgress;
        reader.onload = this.loaded;
        reader.onerror = this.errorHandler;
      } else {
        this.error('FileReader is not supported in your browser');
      }
    },

    loaded(evt) {
      let img = new Image();
      img.src = evt.target.result;
      this.currentOrganization.logo = img.src;
      this.updateOrganization();
    },

    // TODO: could maybe check to see if logo is updated
    async updateOrganization() {
      try {
        await api.put('organization', this.currentOrganization);
        // this.success('Info Updated');
      } catch (e) {
        this.error(e);
      }
    },

    changeCompanyDataType(view) {
      if (view === 'collections') {
        this.activeData = this.collections;
      } else if (view === 'departments') {
        this.activeData = this.departments;
      } else if (view === 'insurance') {
        this.activeData = this.plans;
      }
      this.addData = false;
      this.clearData();
      this.companyDataType = view;
    },

    async getCollections() {
      try {
        let { data } = await api.get(`collection/organization/${this.currentOrganization._id}`);
        this.collections = data;
        if (this.companyDataType === 'collections') {
          this.activeData = data;
        }
      } catch (e) {
        this.error(e);
      }
    },

    async getDepartments() {
      try {
        let { data } = await api.get(`grouping/${this.currentOrganization._id}/department`);
        this.departments = data;
        if (this.companyDataType === 'departments') {
          this.activeData = data;
        }
      } catch (e) {
        this.error(e);
      }
    },

    async getPlans() {
      try {
        let { data } = await api.get(`grouping/${this.currentOrganization._id}/plan`);
        this.plans = data;
        if (this.companyDataType === 'insurance') {
          this.activeData = data;
        }
      } catch (e) {
        this.error(e);
      }
    },

    clearData() {
      this.newData = {
        title: null,
        location: null,
        description: null,
        organization: null,
      };
    },

    clearResource() {
      this.newResource = {
        name: null,
        website: null,
        organization: null,
      };
    },

    async addNewResource() {
      this.newResource.organization = this.currentOrganization._id;
      this.addResource = false;
      try {
        await api.post('resource', this.newResource);
        this.success('Resource added');
        // TODO: function call to 'refresh'
      } catch (e) {
        this.error(e);
      }
    },

    async removeDepartment(id) {
      try {
        await api.delete(`grouping/${id}`);
        this.success('Department deleted');
        this.getDepartments();
      } catch (e) {
        this.error(e);
      }
    },

    async addNewData() {
      this.saving = true;
      try {
        this.newData.organization = this.currentOrganization._id;
        if (this.companyDataType === 'collections') {
          await api.post('collection', this.newData);
          this.success('Collection added');
          this.getCollections();
        } else if (this.companyDataType === 'departments') {
          this.newData.type = 'department';
          await api.post('grouping', this.newData);
          this.success('Department added');
          this.getDepartments();
        }
        this.addData = false;
        this.clearData();
      } catch (e) {
        this.error(e);
      }
      this.saving = false;
    },

    importLink() {
      this.$router.push(this.$route.params.id ? `/import/${this.$route.params.id}` : '/import');
    },

    async updateCollection(id, index) {
      try {
        await api.put(`collection/${id}`, this.activeData[index]);
        this.success('Collection updated');
      } catch (e) {
        this.error(e);
      }
    },
  }, mapActions(['error', 'success'])),
};
