var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-page" }, [
    _c("div", { staticClass: "frow centered" }, [
      _c("div", { staticClass: "col-md-2-3" }, [
        _c("div", { staticClass: "setting-card shadow-light height-100" }, [
          _c("div", { staticClass: "card-title" }, [
            _vm._v("Test Advanced Risk Calulation"),
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.calculateScore()
                  }
                }
              },
              [
                _c("div", { staticClass: "frow gutters" }, [
                  _c("div", { staticClass: "col-xs-1-2" }, [
                    _c("label", [
                      _vm._v("Glucose*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.bloodGlucose,
                            expression: "advancedRisk.bloodGlucose"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.bloodGlucose },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "bloodGlucose",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("A1c*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.a1c,
                            expression: "advancedRisk.a1c"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.a1c },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "a1c",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("TG*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.triglycerides,
                            expression: "advancedRisk.triglycerides"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.triglycerides },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "triglycerides",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("HDL*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.hdl,
                            expression: "advancedRisk.hdl"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.hdl },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "hdl",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("WC*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.waistCirc,
                            expression: "advancedRisk.waistCirc"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.waistCirc },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "waistCirc",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("SBP*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.systolicBP,
                            expression: "advancedRisk.systolicBP"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.systolicBP },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "systolicBP",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [_vm._v("Nicotine Use?")]),
                    _c("div", { staticClass: "frow" }, [
                      _c("div", { staticClass: "col-xs-1-2" }, [
                        _c(
                          "div",
                          { staticClass: "frow column-center width-100" },
                          [
                            _c("label", { staticClass: "row-start" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.advancedRisk.nicotine,
                                    expression: "advancedRisk.nicotine"
                                  }
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: true,
                                  checked: _vm._q(
                                    _vm.advancedRisk.nicotine,
                                    true
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.advancedRisk,
                                      "nicotine",
                                      true
                                    )
                                  }
                                }
                              }),
                              _vm._v("Yes")
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "col-xs-1-2" }, [
                        _c(
                          "div",
                          { staticClass: "frow column-center width-100" },
                          [
                            _c("label", { staticClass: "row-start" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.advancedRisk.nicotine,
                                    expression: "advancedRisk.nicotine"
                                  }
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: false,
                                  checked: _vm._q(
                                    _vm.advancedRisk.nicotine,
                                    false
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.advancedRisk,
                                      "nicotine",
                                      false
                                    )
                                  }
                                }
                              }),
                              _vm._v("No")
                            ])
                          ]
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-1-2" }, [
                    _c("label", [
                      _vm._v("DBP*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.diastolicBP,
                            expression: "advancedRisk.diastolicBP"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.diastolicBP },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "diastolicBP",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("Height*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.height,
                            expression: "advancedRisk.height"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.height },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "height",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("Weight*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.weight,
                            expression: "advancedRisk.weight"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.weight },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "weight",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("BMI*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.bmi,
                            expression: "advancedRisk.bmi"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.bmi },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "bmi",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("Sex*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.sex,
                            expression: "advancedRisk.sex"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.sex },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "sex",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v("Age*"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.advancedRisk.age,
                            expression: "advancedRisk.age"
                          }
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.advancedRisk.age },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.advancedRisk,
                              "age",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("label", [_vm._v("Currently taking medication for:")]),
                    _c("div", { staticClass: "frow column-start" }, [
                      _c("div", { staticClass: "print-checkbox-row" }, [
                        _c("div", { staticClass: "frow" }, [
                          _c("label", { staticClass: "row-start" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.advancedRisk.medications.cholesterol,
                                  expression:
                                    "advancedRisk.medications.cholesterol"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.advancedRisk.medications.cholesterol
                                )
                                  ? _vm._i(
                                      _vm.advancedRisk.medications.cholesterol,
                                      null
                                    ) > -1
                                  : _vm.advancedRisk.medications.cholesterol
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.advancedRisk.medications.cholesterol,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.advancedRisk.medications,
                                          "cholesterol",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.advancedRisk.medications,
                                          "cholesterol",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.advancedRisk.medications,
                                      "cholesterol",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v("Cholesterol")
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "print-checkbox-row" }, [
                        _c("div", { staticClass: "frow" }, [
                          _c("label", { staticClass: "row-start" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.advancedRisk.medications.bloodPressure,
                                  expression:
                                    "advancedRisk.medications.bloodPressure"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.advancedRisk.medications.bloodPressure
                                )
                                  ? _vm._i(
                                      _vm.advancedRisk.medications
                                        .bloodPressure,
                                      null
                                    ) > -1
                                  : _vm.advancedRisk.medications.bloodPressure
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.advancedRisk.medications
                                        .bloodPressure,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.advancedRisk.medications,
                                          "bloodPressure",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.advancedRisk.medications,
                                          "bloodPressure",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.advancedRisk.medications,
                                      "bloodPressure",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v("Blood Pressure")
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "print-checkbox-row" }, [
                        _c("div", { staticClass: "frow" }, [
                          _c("label", { staticClass: "row-start" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.advancedRisk.medications.diabetes,
                                  expression:
                                    "advancedRisk.medications.diabetes"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.advancedRisk.medications.diabetes
                                )
                                  ? _vm._i(
                                      _vm.advancedRisk.medications.diabetes,
                                      null
                                    ) > -1
                                  : _vm.advancedRisk.medications.diabetes
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.advancedRisk.medications.diabetes,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.advancedRisk.medications,
                                          "diabetes",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.advancedRisk.medications,
                                          "diabetes",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.advancedRisk.medications,
                                      "diabetes",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v("Diabetes")
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "settings-buttons" }, [
                  _c("div", { staticClass: "frow centered" }, [
                    _c(
                      "button",
                      { attrs: { type: "submit", disabled: false } },
                      [_vm._v("Run")]
                    ),
                    _c("div", { staticClass: "frow" }, [
                      _c("div", { staticClass: "col-md-1-2" }, [
                        _c("div", { staticClass: "frow" }, [
                          _c("span", [
                            _vm._v("Output: " + _vm._s(_vm.advancedRisk.score))
                          ])
                        ]),
                        _c("div", { staticClass: "frow" }, [
                          _c("span", [
                            _vm._v(
                              "Glucose and A1c Score: " +
                                _vm._s(_vm.glucoseA1cScore)
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "frow" }, [
                          _c("span", [
                            _vm._v(
                              "Triglycerides Score: " +
                                _vm._s(_vm.triglyceridesScore)
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "frow" }, [
                          _c("span", [
                            _vm._v("HDL Score: " + _vm._s(_vm.hdlScore))
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-1-2" }, [
                        _c("div", { staticClass: "frow" }, [
                          _c("span", [
                            _vm._v("WC Score: " + _vm._s(_vm.waistCircScore))
                          ])
                        ]),
                        _c("div", { staticClass: "frow" }, [
                          _c("span", [
                            _vm._v(
                              "BP Score: " + _vm._s(_vm.bloodPressureScore)
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "frow" }, [
                          _c("span", [
                            _vm._v("BMI Score: " + _vm._s(_vm.bmiScore))
                          ])
                        ]),
                        _c("div", { staticClass: "frow" }, [
                          _c("span", [
                            _vm._v("Age Score: " + _vm._s(_vm.ageScore))
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }