'use strict';

const NORMALIZE_TO_STRING = input => `${input}`.trim().toLowerCase();

module.exports = {
  collect(map, input) {
    return Object.keys(map).reduce((output, key) => {
      if (input[key] !== undefined) {
        output[key] = map[key].parse ? map[key].parse(input[key]) : input[key];
      }
      return output;
    }, input);
  },

  bool: {
    description: 'yes/no',
    parse(input) {
      input = NORMALIZE_TO_STRING(input);
      switch (input) {
        case 'y':
        case 'yes':
        case 't':
        case 'true':
        case '1':
          return true;
        case 'n':
        case 'no':
        case 'f':
        case 'false':
        case '0':
          return false;
        default:
          return undefined;
      }
    },
  },

  range: {
    description: 'high/medium/low',
    parse(input) {
      input = NORMALIZE_TO_STRING(input);
      switch (input) {
        case 'h':
        case 'hi':
        case 'high':
          return 1;
        case 'm':
        case 'med':
        case 'medium':
          return 0.5;
        case 'l':
        case 'lo':
        case 'low':
          return 0;
        default: {
          let out = Number.parseFloat(input);
          if (!isNaN(out)) {
            return out;
          }
          return undefined;
        }
      }
    },
  },

  length: {
    description: 'inches or #\' #" #/#',
    parse(input) {
      input = NORMALIZE_TO_STRING(input);
      let total = 0;
      let fraction = input.match(/(\d)\/(\d)/);
      if (fraction) {
        total += ~~fraction[1] / ~~fraction[2];
        input = input.substring(0, fraction.index).trim();
      }
      let feet = input.indexOf('\'');
      if (~feet) {
        feet = Number.parseFloat(input.substring(0, feet));
        if (!isNaN(feet)) {
          total += 12 * feet;
        }
        input = input.substring(input.indexOf('\'') + 1).trim();
      }
      let inches = Number.parseFloat(input);
      if (!isNaN(inches)) {
        total += inches;
      }
      return total;
    },
  },

  number: {
    description: 'number',
    parse(input) {
      input = Number.parseFloat(input);
      return isNaN(input) ? undefined : input;
    },
  },

  sex: {
    description: 'female/male',
    parse(input) {
      input = NORMALIZE_TO_STRING(input);
      switch (input) {
        case 'f':
        case 'female':
          return 'f';
        case 'm':
        case 'male':
          return 'm';
        default:
          return undefined;
      }
    },
  },

  date: {
    description: 'mm/dd/yyyy',
    parse(input) {
      input = NORMALIZE_TO_STRING(input);
      let time = new Date(input).getTime();
      return isNaN(time) ? undefined : time;
    },
  },
};
