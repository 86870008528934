var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "articles-page height-100" }, [
    _c("div", { staticClass: "frow-container height-100" }, [
      _c(
        "div",
        {
          staticClass: "frow height-100",
          class: _vm.treeVisible ? "tree-visible" : "tree-hidden"
        },
        [
          _c("div", { staticClass: "tree-container col-sm-1-4 hidden-xs" }, [
            _c("div", { staticClass: "articles-nav" }, [
              _c("div", { staticClass: "frow justify-end" }, [
                _vm.activeArticle
                  ? _c(
                      "button",
                      {
                        staticClass: "visible-xs",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.treeVisible = !_vm.treeVisible
                          }
                        }
                      },
                      [_vm._v("Back to Article")]
                    )
                  : _vm._e()
              ])
            ]),
            _c(
              "nav",
              { staticClass: "articles-sidebar" },
              [_c("tree", { attrs: { model: _vm.articleContainer } })],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "article-container col-sm-3-4 height-100 hidden-xs"
            },
            [
              _c("div", { staticClass: "articles-nav" }, [
                _c("div", { staticClass: "frow justify-end" }, [
                  _c(
                    "button",
                    {
                      staticClass: "visible-xs",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.treeVisible = !_vm.treeVisible
                        }
                      }
                    },
                    [_vm._v("Table of Contents")]
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "shadow-light",
                  attrs: { id: "article-content" }
                },
                [
                  _vm.activeArticle
                    ? _c("h1", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.activeArticle.title))
                      ])
                    : _vm._e(),
                  _vm.activeArticle
                    ? _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) }
                      })
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }