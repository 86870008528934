'use strict';

/* eslint-disable max-len */
module.exports = {
  display() {
    return {
      menAndWomen50to75: ((this.sex === 'm' || this.sex === 'f') && (this.calcAge >= 50 && this.calcAge <= 75)),
      menAndWomen40to65: ((this.sex === 'm' || this.sex === 'f') && (this.calcAge >= 40 && this.calcAge <= 65)),
      menAndWomenHealthScreening: ((this.sex === 'm' || this.sex === 'f')),
      women40to74: ((this.sex === 'f') && (this.calcAge >= 40 && this.calcAge <= 74)),
      women21to65: ((this.sex === 'f') && (this.calcAge >= 21 && this.calcAge <= 65)),
      menOver49: ((this.sex === 'm') && (this.calcAge >= 50)),
      smokers: this.nicotine,
      heartDiseaseOr3Over5: ((this.diagnosedWith && this.diagnosedWith.heartDisease) || (this.bmiScore >= 3 && this.metsScore >= 5)),
      thoseWithDiabetes: this.diabetes || (this.diagnosedWith && this.diagnosedWith.diabetes),
      menAndWomenUnder26Vaccines: ((this.sex === 'm' || this.sex === 'f') && (this.calcAge <= 26)),
      menAndWomenVaccines: ((this.sex === 'm' || this.sex === 'f')),
      menAndWomenOver60Vaccines: ((this.sex === 'm' || this.sex === 'f') && (this.calcAge > 60)),
      menAndWomenOver65Vaccines: ((this.sex === 'm' || this.sex === 'f') && (this.calcAge >= 65)),
      nicotine: this.nicotine,
      triglycerides: this.triglycerides >= 150,
      hdl: (this.hdl < 40 && this.sex === 'm') || (this.hdl < 50 && this.sex === 'f'),
      waistCirc: (this.waistCirc > 35 && this.sex === 'f') || (this.waistCirc > 40 && this.sex === 'm'),
      diabetes1: this.calcDiabetesLabel === 1,
      diabetes2: this.calcDiabetesLabel === 2,
      diabetes3: this.calcDiabetesLabel === 3 || (this.diagnosedWith && this.diagnosedWith.diabetes),
      bloodGlucose100to125: this.bloodGlucose >= 100 && this.bloodGlucose <= 125,
      bloodGlucoseOver125: this.bloodGlucose > 125,
      systolicBp131to140: this.systolicBP >= 131 && this.systolicBP <= 140,
      systolicBpOver140OrDiastolicBpOver90: this.systolicBP > 140 || this.diastolicBP >= 90,
      bloodPressureAndBloodGlucose: this.bloodGlucose >= 100 && (this.systolicBP >= 130 || this.diastolicBP >= 85),
      bloodPressureAndHdl: ((this.hdl < 40 && this.sex === 'm') || (this.hdl < 50 && this.sex === 'f')) && (this.systolicBP >= 130 || this.diastolicBP >= 85),
      waistCircumferenceAndBloodPressure: ((this.waistCirc > 35 && this.sex === 'f') || (this.waistCirc > 40 && this.sex === 'm')) && (this.systolicBP >= 130 || this.diastolicBP >= 85),
      bloodPressureAndTriglycerides: this.triglycerides >= 150 && (this.systolicBP >= 130 || this.diastolicBP >= 85),
      hdlAndBloodGlucose: this.bloodGlucose >= 100 && ((this.hdl < 40 && this.sex === 'm') || (this.hdl < 50 && this.sex === 'f')),
      hdlAndTriglycerides: this.triglycerides >= 150 && ((this.hdl < 40 && this.sex === 'm') || (this.hdl < 50 && this.sex === 'f')),
      bloodGlucoseAndTriglycerides: this.bloodGlucose >= 100 && this.triglycerides >= 150,
      hdlAndWaistCircumference: ((this.waistCirc > 35 && this.sex === 'f') || (this.waistCirc > 40 && this.sex === 'm')) && ((this.hdl < 40 && this.sex === 'm') || (this.hdl < 50 && this.sex === 'f')),
      bloodGlucoseAndWaistCircumference: this.bloodGlucose >= 100 && ((this.waistCirc > 35 && this.sex === 'f') || (this.waistCirc > 40 && this.sex === 'm')),
      waistCircumferenceAndTriglycerides: ((this.waistCirc > 35 && this.sex === 'f') || (this.waistCirc > 40 && this.sex === 'm')) && this.triglycerides >= 150,
      hdlAndTriglyceridesAndBloodGlucose: this.triglycerides >= 150 && this.bloodGlucose >= 100 && ((this.hdl < 40 && this.sex === 'm') || (this.hdl < 50 && this.sex === 'f')),
      metsScoreOver2: this.metsScore > 2,
      medicationAdherence: this.medications && (this.medications.cholesterol || this.medications.bloodPressure || this.medications.diabetes || this.medications.asthma || this.medications.depression || this.medications.betaBlocker),
      bloodGlucoseOver126AndUnder180: this.bloodGlucose >= 126 && this.bloodGlucose < 180,
      bloodGlucoseOver179: this.bloodGlucose >= 180,
      triglyceridesOver499: this.triglycerides >= 500,
      tobaccoResource: this.nicotine,
      diabetesResource: (this.diagnosedWith && this.diagnosedWith.diabetes) || (this.diabetesScore && this.diabetesScore > 1),
    };
  },

  sections: {
    triglycerides: {
      title: 'Triglycerides',
      content: [
        'You have high triglycerides, defined as a value greater than 150 mg/dl. High triglycerides may contribute to hardening of the arteries or thickening of the artery walls (atherosclerosis) which increases the risk of stroke, heart attack and heart disease.',
        'Diet and lifestyle changes to help lower your triglycerides.',
        [
          'Lose weight and stay at a healthy weight.',
          'Limit saturated fats, sugars, and simple carbohydrates in your diet. Simple carbohydrates are the “white” foods such as white rice, white bread, regular potatoes and pasta.',
          'Replace simple carbohydrates with complex carbohydrates and fiber such as brown rice, whole grain bread, sweet potatoes, and whole wheat pasta.',
          'Increasing your physical activity can lower your triglycerides. Benefits can be seen with as little as 60 minutes of moderate-intensity aerobic exercise a week.',
          'Do not begin smoking or, if you do smoke, stop!',
          'Decrease or eliminate your consumption of alcohol.',
        ],
        'You may need medicine to help lower your triglycerides. Consult with your doctor.',
      ],
    },

    hdl: {
      title: 'HDL',
      content: [
        'HDL (High Density Lipoprotein) is the ‘good’ cholesterol. High levels of HDL reduce your risk for heart disease. You have low HDL, defined as a value less than 40 mg/dl for men and 50 mg/dl for women. A low level of HDL is thought to accelerate the development of coronary heart disease, vascular disease and atherosclerosis. Genetic factors, type 2 diabetes, smoking, being overweight and being sedentary can result in lower HDL cholesterol.',
        'Diet and lifestyle changes can increase HDL levels.',
        [
          'Lose weight and stay at a healthy weight.',
          'Do not begin smoking or, if you do smoke, stop!',
          'Increasing your physical activity can increase your HDL levels. Benefits can be seen with as little as 60 minutes of moderate-intensity aerobic exercise a week.',
          'Avoid trans fats, as they can increase LDL cholesterol and lower HDL cholesterol levels. Foods prepared with shortening, such as cakes and cookies, often contain trans fats, as do most fried foods and some margarines. Read the ingredients and avoid "partially hydrogenated" fats.',
          'Moderate use of alcohol has been linked with higher levels of HDL cholesterol. For healthy adults, that means up to one drink a day for women of all ages and men older than age 65, and up to two drinks a day for men age 65 and younger. Too much alcohol can cause weight gain and may increase your blood pressure and triglyceride levels.',
        ],
        'Some medications, like steroids and progestin’s, may decrease HDL and increase LDL.',
        'HDL levels are sometimes improved by drugs used to lower LDL and triglyceride levels.',
      ],
    },

    waistCirc: {
      title: 'Waist Circumference',
      content: [
        'You have a high waist circumference defined by a waist circumference greater than 35 inches for women and greater than 40 inches for men.',
        'A high waist circumference may indicate that you are storing fat in and around your gut organs (in the viscera). This type of fat is called “visceral fat.” Visceral fat increases your risk for type 2 diabetes, hypertension, and cardiovascular disease.',
        'Increased waist circumference and higher body weight are often connected. In other words, decreasing body weight will often lead to decreases in waist circumference. If you begin an exercise program, you MAY see a decrease in waist circumference without any change in body weight. A decrease in waist circumference is almost always a positive thing for your health, even if you don’t see a change in body weight.',
        'Diet and lifestyle changes can help decrease your waist circumference:',
        [
          'Focus on strategies that build muscle. Lean body tissue burns calories, preventing or even reversing belly-fat expansion.',
          'Eat muscle-protecting protein. To calculate the amount of protein you should eat eat each day, do this simple calculation: multiply your weight in pounds by 0.4. This number is the amount of grams of protein you should eat each day. For example, if you weight 150 pounds: 150 x 0.4 = 60. You would aim to eat 60 grams of protein each day. Try to spread your protein out throughout the day, aiming for at least 30 grams of protein during one of your daily meals. Get your protein from fish, skinless poultry, and plants (kidney beans, nuts, and edamame).',
          'Snack on healthy fats. Nuts, fish, avocados, seeds, and a splash of canola oil are bursting with unsaturated fats that help your body. Eat fish a couple of times a week and enjoy a small handful of nuts every day.',
          'Aim for three 20-minute strength-training sessions per week.',
          'Get adequate amount of sleep each night. Short-changing yourself on sleep leads to cravings.',
        ],
      ],
    },

    diabetes2: {
      title: 'Prediabetes',
      content: [
        'Because your fasting blood glucose is between 100 mg/dL and 125 mg/dL and your hemoglobin A1c is 5.7% to 6.4%, you meet the criteria for prediabetes.',
        'This means that your blood sugar is above normal but not high enough to be diabetes.',
        'Please take these results to your doctor and discuss how often you need to be tested.',
        'It is possible to prevent prediabetes from developing into Type 2 diabetes.',
        'You could develop Type 2 diabetes if you do not immediately make lifestyle changes.',
      ],
    },

    diabetes3: {
      title: 'Diabetes',
      content: [
        'Because your fasting blood glucose is greater than 126 mg/dL and your hemoglobin A1c is greater than 6.5%, you are at high risk for diabetes.',
        'Take these results to your doctor immediately and discuss treatment options.',
        'With Type 2 diabetes, the body either doesn’t produce enough insulin, or is insulin resistant. The result is high levels of circulating glucose.',
        'Symptoms include increased thirst, frequent urination, hunger, fatigue, and blurred vision. In some cases, there may be no symptoms.',
        'Treatments include diet, exercise, medication and drug therapy.',
      ],
    },

    bloodGlucose100to125: {
      title: 'Blood Glucose',
      content: [
        'Your fasting blood glucose level is between 100 to 125 mg/dL, which is over the 100 mg/dl upper limit for ‘normal’ fasting glucose. At this point, you have impaired fasting glucose and can be classified as pre-diabetic. Simply put, you are at high risk for developing Type 2 Diabetes if you don’t make some changes in your diet and lifestyle.',
        'Consult with your physician or medical professional.',
        'The good news is that prediabetes may be reversible with lifestyle and diet modifications.',
        [
          'Lose 7% of your body weight, losing even 10-15 pounds can make a huge difference.',
          'Learn about carbohydrate counting and portion size.',
          'Make every meal well-balanced.',
          'Avoid sugar sweetened beverages.',
          'Hydrate. Water helps flush out your system and stabilize the glucose in the bloodstream.',
          'Exercise moderately (such as brisk walking) 30 minutes a day, 7 days a week.',
          'Eat a fat-packed snack. Fat acts as a blood sugar stabilizer and can slow the absorption rate of glucose.',
          'We recommend that you get your vitamin D levels checked. If your levels are low, Vitamin D supplementation may help prevent the development of diabetes and may help maintain blood glucose control.',
        ],
      ],
    },

    bloodGlucoseOver125: {
      title: 'Blood Glucose',
      content: [
        'A fasting blood glucose over 125 indicates diabetes. Consult with your physician immediately. The sooner your diabetes is addressed and treated the better your health outcome.',
        'Consult with your physician immediately.',
        'Lose 7% of your body weight, losing even 10-15 pounds can make a huge difference.',
        'Learn about carbohydrate counting and portion size.',
        'Make every meal well-balanced.',
        'Avoid sugar sweetened beverages.',
        'Hydrate. The more water you drink, the better. Drink at least two glasses of water, one right after the other. Water helps flush out your system and stabilize the glucose in the bloodstream.',
        'Exercise moderately (such as brisk walking) 30 minutes a day, 7 days a week.',
        'Eat a protein-packed snack. Protein acts as a blood sugar stabilizer and can slow the absorption rate of glucose.',
      ],
    },

    systolicBp131to140: {
      title: 'Blood Pressure',
      content: [
        'You blood pressure value exceeds the Metabolic Syndrome criteria for high blood pressure, which has an upper limit of 130/85.',
        'At this point, you are classified as “pre-hypertensive.”  The range for prehypertension is between 120-140 for the systolic value (the first number) and between 80-90 for the diastolic value (the second number).',
        'People with prehypertension are likely to develop high blood pressure unless lifestyle changes are taken to control it.',
        [
          'Lose extra pounds.',
          'Exercise regularly with moderate to vigorous to moderate exercise 30 minutes every day.',
          'Eat a healthy diet by increasing the amount of fruits and vegetables in your diet, reducing sodium, consider boosting potassium, eat fewer processed foods, and limit caffeine. Strive for 4-5 servings of fruits and vegetables per day.',
          'Limit alcoholic beverages.',
          'Do not begin smoking or, if you do smoke, stop!',
          'Relax, consider taking up yoga, meditation or stress management.',
          'Adequate magnesium intake can also help manage high blood pressure. Eat foods rich in magnesium, such as pumpkin seeds, spinach, chard, sunflower seeds, and navy beans.',
        ],
      ],
    },

    systolicBpOver140OrDiastolicBpOver90: {
      title: 'Blood Pressure',
      content: [
        'You are at high risk for Hypertension, defined as a systolic value (the first number) over 140 and/or the diastolic value (the second number) over 90.',
        'Consult with your physician or medical professional.',
        'Treatment includes lifestyle changes and possibly a blood pressure medication.',
        'Maintain a healthy body weight.',
        'Exercise regularly with moderate to vigorous exercise for 30 minutes every day.',
        'Eat a healthy diet by increasing the amount of fruits and vegetables in your diet, reducing sodium, consider boosting potassium, eat fewer processed foods, and limit caffeine. Strive for 4-5 servings of fruits and vegetables per day.',
        'Limit alcoholic beverages.',
        'Do not begin smoking or, if you do smoke, stop!',
        'Relax and practice stress management techniques.',
        'Adequate magnesium intake can also help manage high blood pressure. Eat foods rich in magnesium, such as pumpkin seeds, spinach, chard, sunflower seeds, and navy beans.',
        'Take blood pressure medication as prescribed.',
      ],
    },

    bloodPressureAndBloodGlucose: {
      title: 'Blood Pressure and High Fasting Blood Glucose',
      content: [
        'Because you have high blood pressure and high blood glucose, you have some additional risks you should be aware of:',
        'High blood pressure (hypertension) can lead to and make worse many complications of diabetes, including diabetic eye disease and kidney disease. Most people with diabetes develop high blood pressure during their life.',
        'Having diabetes increases the likelihood of high blood pressure and other heart and circulation problems, because diabetes damages arteries and makes them targets for hardening (atherosclerosis). Atherosclerosis can cause high blood pressure, which if not treated, can lead to blood vessel damage, stroke, heart failure, heart attack, or kidney failure.',
        'Control your blood sugar.',
        'Stop smoking.',
        'Eat healthy.',
        'Limit salt intake.',
        'Visit your doctor regularly.',
      ],
    },

    bloodPressureAndHdl: {
      title: 'Blood Pressure and Low HDL',
      content: [
        'Because you have high blood pressure and low HDL, here are some additional things to be aware of:',
        [
          'Eat a healthy diet that is low in salt, low in total fat, saturated fat and cholesterol and rich in fresh fruits and vegetables.',
          'Take a brisk 10-minute walk, 3 times a day, 5 days a week.',
          'Do not smoke. If you do smoke, quit as soon as possible. Consider the Illinois Quit Line, a free resource to help you kick the habit.',
          'Control any other risk factors you may have, such as high blood pressure and quit smoking.',
          'Follow a low saturated fat, low refined carbohydrate eating plan.',
          'Maintain a desirable weight.',
          'Participate in regular physical activity.',
          'Begin medication therapy as directed by your doctor.',
        ],
      ],
    },

    waistCircumferenceAndBloodPressure: {
      title: 'Waist Circumference and Blood Pressure',
      content: [
        'Because you have a high waist circumference and high blood pressure, you have the two key factors that identify high risk for heart attack or stroke.',
        'The good news is that there are many lifestyle changes you can make to improve these two factors:',
        'Re-read the points under waist circumference and blood pressure above.',
        'The DASH Diet (Dietary Approaches to Stop Hypertension) and daily physically activity will help control both your waist circumference and blood pressure. The DASH eating plan requires no special foods and instead provides daily and weekly nutritional goals. Visit www.dashdiet.org, consult with your physician and health coach to learn more.',
        'The DASH Diet is research based and recommended by the American Heart Association and U.S. guidelines for treatment of high blood pressure. The DASH Diet includes:',
        [
          'Eating vegetables, fruits, nuts, seeds, legumes and whole grains',
          'Including fat-free or low-fat dairy products, fish, poultry, beans, nuts, and vegetable oils',
          'Limiting foods that are high in saturated fat, such as fatty meats, full-fat dairy products, and tropical oils such as coconut, palm kernel, and palm oils',
          'Limiting sugar-sweetened beverages and sweets.',
        ],
      ],
    },

    bloodPressureAndTriglycerides: {
      title: 'Blood Pressure and Triglycerides',
      content: [
        'Because you have high blood pressure and high triglycerides, here are some additional things to be aware of:',
        'If you are on medications:',
        [
          'Some blood pressure medications can affect triglyceride and cholesterol levels. If you are on blood pressure medication you may want to discuss with a physician because some blood pressure medications can increase your triglyceride levels.',
          'Hydrochlorothiazide is commonly prescribed for high blood pressure. It\'s from a class of medications called diuretics, more commonly called water pills. High doses can temporarily increase your low-density lipoprotein (LDL) cholesterol and triglycerides.',
        ],
        'If you\'re worried about increasing triglyceride levels, talk to your doctor about making changes to your diet and exercise routine. Don\'t stop taking any prescribed medications without first talking to your doctor.',
      ],
    },

    hdlAndBloodGlucose: {
      title: 'HDL and Blood Glucose',
      content: [
        'Because you have low HDL and high blood glucose, this combination can increase your risk of developing Type 2 Diabetes.',
        'Consult with your physician and health coach regarding your risk for lifestyle disease.',
        'High density lipoprotein cholesterol (HDL), the so-called "good" cholesterol improves blood glucose levels by enhancing skeletal muscle function and reducing adiposity.',
        'A low plasma level of HDL cholesterol is an atherosclerotic risk factor; however, emerging evidence suggests that low HDL levels might also contribute to the pathophysiology of type 2 diabetes through direct effects on plasma glucose.',
        'Pay careful attention to the points above on how to increase HDL!',
        'Incorporate the strategies above to help lower blood glucose.',
      ],
    },

    hdlAndTriglycerides: {
      title: 'HDL and Triglycerides',
      content: [
        'Because you have two Metabolic Syndrome factors, low HDL and high triglycerides, you have a blood lipid profile that increases your risk for heart disease.',
        'Consult with your physician and health coach regarding your risk for lifestyle disease.',
        'In addition to the information about HDL and triglycerides above, here is some additional information:',
        'High triglycerides and low HDL increase your risk for heart disease. Low levels of HDL and raised triglycerides, affecting millions, are strongly linked to significantly increased risk of coronary heart disease even in those who achieve or surpass current low density lipoprotein cholesterol (LDL-C) targets.',
        'Triglycerides and HDL appear to act synergistically with the impact of triglycerides increasing when HDL is low and the impact of HDL increasing when triglycerides levels are high.',
        'Increasing your physical activity can lower your triglycerides while increasing your HDL levels. Benefits can be seen with as little as 60 minutes of moderate-intensity aerobic exercise a week.',
      ],
    },

    bloodGlucoseAndTriglycerides: {
      title: 'Blood Glucose and Triglycerides',
      content: [
        'Because you have two Metabolic Syndrome factors, high blood glucose and high triglycerides, here are some additional things to be aware of:',
        'High triglycerides don\'t cause diabetes. Instead, their levels indicate that your system for turning food into energy isn\'t working properly.',
        'A common cause of high triglycerides is insulin resistance. Insulin resistance is when your cells don’t respond to insulin and have diminished ability to let glucose inside your cells. As a result, both glucose and triglycerides build up in your blood.',
        'Consult with your physician and health coach regarding your risk for lifestyle disease.',
      ],
    },

    hdlAndWaistCircumference: {
      title: 'HDL and Waist Circumference',
      content: [
        'Because you have low HDL and high blood glucose, this combination can increase your risk of developing Type 2 Diabetes.',
        'High density lipoprotein cholesterol (HDL), the so-called "good" cholesterol improves blood glucose levels by enhancing skeletal muscle function and reducing adiposity.',
        'A low plasma level of HDL cholesterol is an atherosclerotic risk factor; however, emerging evidence suggests that low HDL levels might also contribute to the pathophysiology of type 2 diabetes through direct effects on plasma glucose.',
      ],
    },

    bloodGlucoseAndWaistCircumference: {
      title: 'Blood Glucose and Waist Circumference',
      content: [
        'Because you have high fasting blood glucose and a large waist circumference, you are at higher risk for developing Type 2 Diabetes.',
        'Researchers have demonstrated a strong link between having a large waist circumference and an increased risk of developing Type 2 diabetes, independent of a person’s body mass index (BMI). It is strongly encouraged to reduce body weight and increase physical activity.',
      ],
    },

    waistCircumferenceAndTriglycerides: {
      title: 'Waist Circumference and Triglycerides',
      content: [
        'Because you have a large waist circumference and high triglycerides, you have an increased risk for coronary artery disease and heart disease.',
      ],
    },

    hdlAndTriglyceridesAndBloodGlucose: {
      title: 'HDL, Triglycerides and Blood Glucose',
      content: [
        'You have low HDL, high triglycerides, and high blood glucose. Because you have three factors, you have Metabolic Syndrome.',
        'Your risk for heart disease, diabetes, and stroke increases with the number of metabolic risk factors you have.',
        'It is possible to prevent or delay metabolic syndrome, mainly with lifestyle changes. A healthy lifestyle is a lifelong commitment. Successfully controlling metabolic syndrome requires long-term effort and teamwork with your health care providers.',
        'The first line treatment is lifestyle change. However, if efforts at reversing risk factors don’t work, then drug treatment may be required.',
        'Low HDL, high triglycerides, and high blood glucose are often seen together. People with chronic high blood glucose or Type 2 diabetes tend to exhibit lower HDL. When there is something wrong with insulin, it\'s likely that cholesterol and triglycerides will also be affected, not just glucose. Diabetes lowers the amount of "good cholesterol," or HDL that sweeps through the blood and vacuums up excess fat.',
        'When HDL levels are lowered, the "bad cholesterol", or LDL increases, as do the triglycerides. Low HDL levels paired with high triglycerides result in increased plaque buildup in artery walls, the blockages that lead to heart attacks and strokes. In fact, the American Diabetes Association reports that more than 65% diabetics die from either heart attacks or strokes.',
      ],
    },

    metsScoreOver2: {
      title: 'Metabolic Syndrome',
      content: [
        'You have three positive Metabolic Syndrome factors, therefore you have Metabolic Syndrome.',
        'Your risk for heart disease, diabetes, and stroke increases with the number of metabolic risk factors you have.',
        'It is possible to prevent or delay metabolic syndrome, mainly with lifestyle changes. A healthy lifestyle is a lifelong commitment. Successfully controlling metabolic syndrome requires long-term effort and teamwork with your health care providers.',
        'The first line treatment is lifestyle change. However, if efforts at reversing risk factors don’t work, then drug treatment may be required.',
        'Below are some important points that relate to the combination of factors you have:',
        [
          'Diet. It cannot be emphasized enough. Metabolic Syndrome is a chronic condition that can potentially be reversed by adhering to healthy diet guidelines. By changing your diet, you can lose weight, lower your blood pressure, lower your bad cholesterol and triglyceride levels.',
          'Increase your magnesium intake. New studies now show that adequate magnesium intake can reduce diabetes risk by as much as 34 percent. Adequate magnesium intake can also help manage high blood pressure. Eat foods rich in magnesium, such as pumpkin seeds, spinach, chard, sunflower seeds, and navy beans.',
          'Eat more low-glycemic fiber. People who have problems with blood sugar control and/or weight typically eat the foods that have very low fiber content. Fiber, found in whole grains and unprocessed foods, can be very effective in stabilizing blood sugar levels.',
          'Increase the amount of nutrient-dense, low-glycemic foods in your diet, such as dark green, leafy vegetables, whole fruits, and sprouted nuts and seeds.',
          'Increase your omega-3 intake.',
          'Not all fats are bad, and in fact, some fats are essential for good nutrition. A healthy balance of good fats is more important than eliminating ALL fats.',
          'Exercise. Like diet, exercise is an essential part of the treatment plan for Metabolic Syndrome. Exercise helps control weight, helps control blood sugar levels more effectively, helps reduce high blood pressure, and strengthens the heart muscle. These are all essential in reversing Metabolic Syndrome.',
          'Sleep well. Chronic sleep deprivation can alter hormone levels and slow metabolism.',
          'Avoid hydrogenated or partially hydrogenated oils found in many processed foods, deep-fried foods, fast foods, and junk food.',
          'Caffeine intake after a meal surges blood sugar levels, making blood sugar control more difficult.',
          'Smoking. A recent study links smoking to increased diabetes risk.',
          'Avoid all simple or refined carbohydrates (white flour, white rice, white bread, pasta, cookies, cakes, crackers, processed snack foods, starchy vegetables that grow underground such as potatoes, beets, peanuts.)',
          'Avoid refined sugar or artificial sugar-substitutes.',
          'Avoid alcoholic beverages, which can rapidly spike blood sugar levels.',
        ],
        'Consult with your physician, medical professional or health coach immediately regarding your risk for lifestyle disease.',
      ],
    },

    medicationAdherence: {
      title: 'Medication Adherence',
      content: [
        'You are currently taking medication prescribed by a physician.',
        'Poor medication adherence, or non-adherence, increases the likelihood of hospitalizations, avoidable doctor and emergency room visits, and other problems arising from poor health, which can significantly increase costs.',
        'At least 125,000 Americans die annually due to poor medication adherence.',
        'Follow medication directions as prescribed by your physician. If you are experiencing any difficulties using or obtaining your prescription medication, please contact your physician.',
        'Do not discontinue use of your medications without advising your physician.',
      ],
    },

    nicotine: {
      title: 'Smoking',
      content: [
        'You indicated that you are a tobacco user.',
        'The Tobacco Quit Help website is a resource for tobacco users who want to quit. Visit https://smokefree.gov for more information and to enroll in the program.',
        'Prepare for your cravings with healthy snacks.',
        'Think about how much money you will be saving.',
        'Change your routine.',
        'Know your smoking triggers.',
        'Focus on your “why”.',
        'Slipping does not mean you have failed.',
        'Stay positive.',
      ],
    },
  },

  interventions: {
    bloodGlucoseOver126AndUnder180: 'Blood Glucose 126+ Follow up with a physician within two weeks; Health Coach Follow Up',
    bloodGlucoseOver179: 'Blood Glucose 180+ Immediate follow up with a physician; Health Coach Follow Up',
    triglyceridesOver499: 'Triglycerides 500+ Follow up with a physician within two weeks; Health Coach Follow Up',
  },

  recommendations: {
    menAndWomen50to75: {
      content: [
        'Men and Women 50 to 75:',
        [
          'Colorectal cancer screening tests save lives by finding precancerous polyps and colorectal cancer early, when treatment works best. Screening is recommended for men and women aged 50-75 years.',
          'All women and men over age 50 with fractures should have a bone density test (DEXA scan).',
        ],
      ],
    },

    menAndWomenUnder26Vaccines: {
      content: [
        'Men and Women Under 26:',
        [
          'The CDC recommends that all males and females between ages 11-26 get two doses of the HPV vaccine to protect against diseases caused by HPV.',
        ],
      ],
    },

    thoseWithDiabetes: {
      content: [
        'Men and Women with Diabetes:',
        [
          'Even if the doctor who takes care of your diabetes checks your eyes, you need an eye exam every 1 to 2 years by an eye doctor who takes care of people with diabetes to check for diabetic retinopathy, glaucoma and other eye problems.',
          'For those with diabetes special exams may be done to look at your feet, reflexes and kidney functions.',
        ],
      ],
    },

    heartDiseaseOr3Over5: {
      content: [
        'Men and Women with Heart Disease or 3/5:',
        [
          'A daily low-dose of aspirin may be beneficial if recommended by your health care provider.',
        ],
      ],
    },

    smokers: {
      content: [
        'Smokers:',
        [
          'For those who currently smoke or have quit within the past 15 years, the USPSTF recommends annual screening for lung cancer with low-dose computed tomography (LDCT) in adults aged 55 to 80.',
        ],
      ],
    },

    menOver49: {
      content: [
        'Men 50 and Older:',
        [
          'Most men age 50 or older should discuss screening for prostate cancer with their provider. African American men and those with a family history of prostate cancer in a first degree relative younger than age 65 should discuss screening at age 45.',
          'The potential benefits of PSA testing as a routine screening test have not been shown to outweigh the harms of testing and treatment. If you choose to be tested, the PSA blood test is most often done every year.',
        ],
      ],
    },

    women21to65: {
      content: [
        'Women Ages 21 to 65:',
        [
          'Women aged 21-65 should have a Pap smear every 3 years. If you have both a Pap smear and human papilloma virus (HPV) test, you may be tested every 5 years.',
          'Women who are sexually active and at high risk should be screened for chlamydia and gonorrhea. Your provider may talk with you about testing for other infections.',
        ],
      ],
    },

    women40to74: {
      content: [
        'Women Ages 40 to 74:',
        [
          'Mammography to screen for breast cancer has been shown to reduce mortality from the disease among women ages 40 to 74, especially those age 50 or older.',
          'If you are under age 65 and have risk factors for osteoporosis, you should be screened.',
        ],
      ],
    },

    menAndWomenHealthScreening: {
      content: [
        'Men and Women:',
        [
          'The American Cancer Society recommends a skin exam as part of a periodic exam by your provider, if it is indicated.',
          'Depending on your lifestyle and medical history, you may need to be screened for infections such as syphilis, chlamydia, and HIV, as well as other infections.',
          'Go to the dentist once or twice a year for an exam and cleaning.',
        ],
      ],
    },

    menAndWomen40to65: {
      content: [
        'Under 60 years of age:',
        [
          'Have an eye exam every 2 years and every year if you are over 60. Your provider may recommend more frequent eye exams if you have vision problems or glaucoma risk.',
        ],
      ],
    },
  },

  vaccinations: {
    menAndWomenVaccines: {
      content: [
        'Men and Women:',
        [
          'Everyone 6 months or older, with rare exceptions, should get a flu shot every year.',
          'You should have a tetanus-diphtheria and acellular pertussis (Tap) vaccine once as part of your tetanus-diphtheria vaccines. You should have a tetanus-diphtheria booster every 10 years.',
          'Your provider may recommend other immunizations if you are at high risk for certain conditions.',
        ],
      ],
    },

    menAndWomenUnder26Vaccines: {
      content: [
        'Men and Women Under 26:',
        [
          'The CDC recommends that all males and females between ages 11-26 get two doses of the HPV vaccine to protect against diseases caused by HPV.',
        ],
      ],
    },

    menAndWomenOver60Vaccines: {
      content: [
        'Men and Women Over 60:',
        [
          'You may get a shingles or herpes zoster vaccine once after age 60.',
        ],
      ],
    },

    menAndWomenOver65Vaccines: {
      content: [
        'Men and Women Over 65:',
        [
          'Pneumococcal Vaccine (for Pneumonia) for adults ages 65 and over who are at average risk with diabetes, cancer, or heart, lung or immune disease.',
        ],
      ],
    },
  },
};
