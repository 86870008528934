'use strict';

const { nicotine } = require('./assessment-fields');
let { sex: { parse } } = require('./fields');

/**
 * @module calculations
 */

module.exports = {
  getInches(feet = 0, inches = 0) {
    return (feet * 12) + inches;
  },

  calcWeightRange(bmi, height) {
    return ~~((bmi * height * height) / 703);
  },

  getDiabetesRanges(bloodGlucose, a1c) {
    switch (true) {
      case (bloodGlucose < 100 && a1c < 5.6):
        return 1;
      case ((bloodGlucose >= 100 && bloodGlucose <= 125) || (a1c >= 5.7 && a1c <= 6.4)):
        return 2;
      case ((bloodGlucose >= 126 && bloodGlucose <= 150) || (a1c >= 6.5 && a1c <= 6.9)):
        return 3;
      case (bloodGlucose > 150 || a1c >= 7.0):
        return 4;
      default:
        return 5;
    }
  },

  getWeightRanges(height) {
    return {
      underweight: {
        high: this.calcWeightRange(18.4, height),
      },
      healthyWeight: {
        low: this.calcWeightRange(18.5, height),
        high: this.calcWeightRange(24.9, height),
      },
      overweight: {
        low: this.calcWeightRange(25.0, height),
        high: this.calcWeightRange(29.9, height),
      },
      obese: {
        low: this.calcWeightRange(30.0, height),
        high: this.calcWeightRange(39.9, height),
      },
      morbidlyObese: {
        low: this.calcWeightRange(40.0, height),
      },
    };
  },

  calcBmi(weight, height) {
    return (weight / (height ** 2)) * 703;
  },

  calcBmiScore(bmi) {
    switch (true) {
      case (bmi <= 18.5):
        return -1;
      case (bmi < 25):
        return 0;
      case (bmi < 30):
        return 1;
      case (bmi < 40):
        return 2;
      case (bmi >= 40):
        return 3;
      default:
        return null;
    }
  },

  // TODO: document arguments
  // waistCircumference, triglycerides, hdl, bloodGlucose, bloodPressure
  calcMetsScore(...args) { //
    return Math.min(5, ~~(args.reduce((sum, val) => sum + val, 0)));
  },

  calcWaistCircumference(sex, waistCircumference) {
    return waistCircumference >= (parse(sex) === 'f' ? 35 : 40) ? 1 : 0;
  },

  calcTriglycerides(triglycerides) {
    return triglycerides >= 150 ? 1 : 0;
  },

  /**
   * @property {Integer} calcHdl The user's calculated HDL
   */
  calcHdl(sex, hdl) {
    return (hdl > 0 && hdl < (parse(sex) === 'f' ? 50 : 40)) ? 1 : 0;
  },

  /**
   * @property {Integer} calcBloodGlucose The user's calculated Blood Glucose
   */
  calcBloodGlucose(bloodGlucose) {
    return bloodGlucose >= 100 ? 1 : 0;
  },

  /**
   * @property {Integer} calcBloodPressure The user's calculated Blood Pressure
   */
  calcBloodPressure(systolicBP, diastolicBP) {
    return (systolicBP >= 130 || diastolicBP >= 85) ? 1 : 0;
  },

  getScores({
    sex,
    height,
    weight,
    systolicBP,
    diastolicBP,
    waistCirc,
    bloodGlucose,
    triglycerides,
    hdl,
  }) {
    let incomplete = [
      height, weight, systolicBP, diastolicBP,
      waistCirc, bloodGlucose, triglycerides, hdl,
    ].some(n => Number.isNaN(parseFloat(n)) || !Number.isFinite(n));
    incomplete = incomplete || (sex !== 'f' && sex !== 'm');
    if (incomplete) {
      return { incomplete };
    }
    let bloodPressure = this.calcBloodPressure(systolicBP, diastolicBP);
    waistCirc = this.calcWaistCircumference(sex, waistCirc);
    bloodGlucose = this.calcBloodGlucose(bloodGlucose);
    triglycerides = this.calcTriglycerides(triglycerides);
    hdl = this.calcHdl(sex, hdl);
    return {
      bmi: this.calcBmi(weight, height),
      bmiScore: this.calcBmiScore(this.calcBmi(weight, height)),
      mets: this.calcMetsScore(bloodPressure, waistCirc, bloodGlucose, triglycerides, hdl),
      risk: Math.floor(Math.random() * 101),
    };
  },

  priority({ bmiScore, metsScore, readinessDiet, readinessPhysical, healthCoaching }) {
    let highRisk = bmiScore / metsScore >= 2 / 3;
    let highReadiness = readinessDiet === 1 || readinessPhysical === 1;
    if (healthCoaching) {
      if (highReadiness) {
        if (highRisk) {
          return 1;
        }
        return 3;
      }
      if (highRisk) {
        return 2;
      }
      return 4;
    }
    if (highReadiness) {
      if (highRisk) {
        return 5;
      }
      return 7;
    }
    if (highRisk) {
      return 6;
    }
    return 8;
  },

  calcAdvancedRiskScore({
    bloodGlucose,
    a1c,
    triglycerides,
    hdl,
    waistCirc,
    systolicBP,
    diastolicBP,
    height,
    weight,
    bmi,
    sex,
    age,
    nicotine,
    medications,
  }) {
    // RISK SCORE = (GLU or A1c*) + (TG) + (HDL*) + (WC/Height) + (SBP or DBP*) + (BMI) + (AGE)
    let score = 
      this.calcAdvBloodSugar(bloodGlucose, a1c, medications) 
      + this.calcAdvTriglycerides(triglycerides) 
      + this.calcAdvHDL(hdl, sex, medications)
      + this.calcAdvWaistCirc(waistCirc, height)
      + this.calcAdvBloodPressure(systolicBP, diastolicBP, medications)
      + this.calcAdvBMI(bmi)
      + this.calcAdvAge(age);
    nicotine ? score += 1 : null
    return score > 10 ? 10 : score.toFixed(1);
  },
  
  calcAdvBloodSugar(bloodGlucose, a1c, medications) {
    let bloodGlucoseScore = 0;
    let a1cScore = 0;
    switch (true) {
      case (bloodGlucose < 90):
        bloodGlucoseScore = 0
        break
      case (bloodGlucose < 92):
        bloodGlucoseScore = 0.1
        break
      case (bloodGlucose < 93):
        bloodGlucoseScore = 0.2
        break
      case (bloodGlucose < 94):
        bloodGlucoseScore = 0.3
        break
      case (bloodGlucose < 95):
        bloodGlucoseScore = 0.4
        break
      case (bloodGlucose < 96):
        bloodGlucoseScore = 0.5
        break
      case (bloodGlucose < 97):
        bloodGlucoseScore = 0.6
        break
      case (bloodGlucose < 98):
        bloodGlucoseScore = 0.7
        break
      case (bloodGlucose < 99):
        bloodGlucoseScore = 0.8
        break
      case (bloodGlucose < 100):
        bloodGlucoseScore = 0.9
        break
      case (bloodGlucose == 100):
        bloodGlucoseScore = 1
        break
      case (bloodGlucose < 104):
        bloodGlucoseScore = 1.1
        break
      case (bloodGlucose < 110):
        bloodGlucoseScore = 1.2
        break
      case (bloodGlucose < 118):
        bloodGlucoseScore = 1.3
        break
      case (bloodGlucose < 125):
        bloodGlucoseScore = 1.4
        break
      case (bloodGlucose < 130):
        bloodGlucoseScore = 1.5
        break
      case (bloodGlucose < 135):
        bloodGlucoseScore = 1.6
        break
      case (bloodGlucose < 140):
        bloodGlucoseScore = 1.7
        break
      case (bloodGlucose < 145):
        bloodGlucoseScore = 1.8
        break
      case (bloodGlucose < 150):
        bloodGlucoseScore = 1.9
        break
      case (bloodGlucose >= 150):
        bloodGlucoseScore = 2
        break
        
    }
    switch (true) {
      case (a1c < 4.5):
        a1cScore = 0
        break
      case (a1c < 4.6):
        a1cScore = 0.1
        break
      case (a1c < 4.7):
        a1cScore = 0.2
        break
      case (a1c < 4.8):
        a1cScore = 0.3
        break
      case (a1c < 4.9):
        a1cScore = 0.6
        break
      case (a1c < 5):
        a1cScore = 0.8
        break
      case (a1c < 5.1):
        a1cScore = 1
        break
      case (a1c < 5.4):
        a1cScore = 1.1
        break
      case (a1c < 5.7):
        a1cScore = 1.2
        break
      case (a1c < 6):
        a1cScore = 1.3
        break
      case (a1c < 6.5):
        a1cScore = 1.4
        break
      case (a1c < 6.6):
        a1cScore = 1.5
        break
      case (a1c < 6.8):
        a1cScore = 1.6
        break
      case (a1c < 7):
        a1cScore = 1.7
        break
      case (a1c < 7.3):
        a1cScore = 1.8
        break
      case (a1c < 7.6):
        a1cScore = 1.9
        break
      case (a1c >= 7.6):
        a1cScore = 2
        break
    }
    let score = bloodGlucoseScore > a1cScore ? bloodGlucoseScore : a1cScore;
    if (medications && medications.diabetes && score < 1) {
      return 1;
    }
    return score; 
  },

  calcAdvTriglycerides(triglycerides) {
    let triglyceridesScore = 0;
    switch(true) {
      case(triglycerides <= 75):
        triglyceridesScore = 0
        break
      case(triglycerides < 90):
        triglyceridesScore = 0.1
        break
      case(triglycerides < 100):
        triglyceridesScore = 0.2
        break
      case(triglycerides < 110):
        triglyceridesScore = 0.3
        break
      case(triglycerides < 120):
        triglyceridesScore = 0.4
        break
      case(triglycerides < 130):
        triglyceridesScore = 0.5
        break
      case(triglycerides < 135):
        triglyceridesScore = 0.6
        break
      case(triglycerides < 140):
        triglyceridesScore = 0.7
        break
      case(triglycerides < 145):
        triglyceridesScore = 0.8
        break
      case(triglycerides < 150):
        triglyceridesScore = 0.9
        break
      case(triglycerides < 151):
        triglyceridesScore = 1
        break
      case(triglycerides < 160):
        triglyceridesScore = 1.1
        break
      case(triglycerides < 170):
        triglyceridesScore = 1.2
        break
      case(triglycerides < 185):
        triglyceridesScore = 1.3
        break
      case(triglycerides < 200):
        triglyceridesScore = 1.4
        break
      case(triglycerides < 201):
        triglyceridesScore = 1.5
        break
      case(triglycerides < 250):
        triglyceridesScore = 1.6
        break
      case(triglycerides < 300):
        triglyceridesScore = 1.7
        break
      case(triglycerides < 400):
        triglyceridesScore = 1.8
        break
      case(triglycerides < 500):
        triglyceridesScore = 1.9
        break
      case(triglycerides >= 500):
        triglyceridesScore = 2
        break
    }
    return triglyceridesScore
  },

  calcAdvHDL(hdl, sex, medications) {
    let hdlScore;
    if (parse(sex) === 'm') {
      switch(true) {
        case(hdl >= 60 && hdl <= 75):
          hdlScore = 0;
          break
        case(hdl >= 58 && hdl <= 59.9):
          hdlScore = 0.1;
          break
        case(hdl >= 55 && hdl <= 57.9):
          hdlScore = 0.2;
          break
        case(hdl >= 52 && hdl <= 54.9):
          hdlScore = 0.3;
          break
        case((hdl >= 50 && hdl <= 51.9) || hdl == 76):
          hdlScore = 0.4;
          break
        case((hdl >= 49 && hdl <= 49.9) || hdl == 77):
          hdlScore = 0.5;
          break
        case((hdl >= 48 && hdl <= 48.9) || hdl == 78):
          hdlScore = 0.6;
          break
        case((hdl >= 46 && hdl <= 47.9) || hdl == 79):
          hdlScore = 0.7;
          break
        case((hdl >= 46 && hdl <= 47.9) || (hdl >= 80 && hdl <= 89.9)):
          hdlScore = 0.8;
          break
        case((hdl >= 40 && hdl <= 43.9) || (hdl >= 90 && hdl <= 99.9)):
          hdlScore = 0.9;
          break
        case((hdl >= 39 && hdl <= 49.9) || hdl >= 100):
          hdlScore = 1;
          break
        case(hdl >= 37 && hdl <= 38.9):
          hdlScore = 1.1;
          break
        case(hdl >= 35 && hdl <= 36.9):
          hdlScore = 1.2;
          break
        case(hdl >= 33 && hdl <= 34.9):
          hdlScore = 1.3;
          break
        case(hdl >= 31 && hdl <= 32.9):
          hdlScore = 1.4;
          break
        case(hdl >= 29 && hdl <= 30.9):
          hdlScore = 1.5;
          break
        case(hdl >= 27 && hdl <= 28.9):
          hdlScore = 1.6;
          break
        case(hdl >= 25 && hdl <= 26.9):
          hdlScore = 1.7;
          break
        case(hdl >= 23 && hdl <= 24.9):
          hdlScore = 1.8;
          break
        case(hdl >= 21 && hdl <= 22.9):
          hdlScore = 1.9;
          break
        case(hdl <= 20.9):
          hdlScore = 2;
          break
      }
    } else if (parse(sex) === 'f') {
      switch(true) {
        case(hdl >= 60 && hdl <= 75):
          hdlScore = 0;
          break
        case(hdl >= 58 && hdl <= 59.9):
          hdlScore = 0.1;
          break
        case(hdl >= 57 && hdl <= 57.9):
          hdlScore = 0.2;
          break
        case(hdl >= 56 && hdl <= 56.9):
          hdlScore = 0.3;
          break
        case((hdl >= 55 && hdl <= 55.9) || hdl == 76):
          hdlScore = 0.4;
          break
        case((hdl >= 54 && hdl <= 54.9) || hdl == 77):
          hdlScore = 0.5;
          break
        case((hdl >= 53 && hdl <= 53.9) || hdl == 78):
          hdlScore = 0.6;
          break
        case((hdl >= 52 && hdl <= 52.9) || hdl == 79):
          hdlScore = 0.7;
          break
        case((hdl >= 51 && hdl <= 51.9) || (hdl >= 80 && hdl <= 89.9)):
          hdlScore = 0.8;
          break
        case((hdl >= 50 && hdl <= 50.9) || (hdl >= 90 && hdl <= 99.9)):
          hdlScore = 0.9;
          break
        case((hdl >= 49 && hdl <= 49.9) || hdl >= 100):
          hdlScore = 1;
          break
        case(hdl >= 47 && hdl <= 48.9):
          hdlScore = 1.1;
          break
        case(hdl >= 45 && hdl <= 46.9):
          hdlScore = 1.2;
          break
        case(hdl >= 43 && hdl <= 44.9):
          hdlScore = 1.3;
          break
        case(hdl >= 41 && hdl <= 42.9):
          hdlScore = 1.4;
          break
        case(hdl >= 40 && hdl <= 40.9):
          hdlScore = 1.5;
          break
        case(hdl >= 39 && hdl <= 39.9):
          hdlScore = 1.6;
          break
        case(hdl >= 38 && hdl <= 38.9):
          hdlScore = 1.7;
          break
        case(hdl >= 37 && hdl <= 37.9):
          hdlScore = 1.8;
          break
        case(hdl >= 36 && hdl <= 36.9):
          hdlScore = 1.9;
          break
        case(hdl <= 35.9):
          hdlScore = 2;
          break
      }
    } else {
      console.error('ERROR PARSING SEX')
    }
    if (medications && medications.cholesterol && hdlScore < 1) {
      return 1;
    }
    return hdlScore;
  },

  calcAdvWaistCirc(waistCirc, height) {
    let waitsCircScore = 0;
    let wcOverHeight = waistCirc / height;
    switch(true) {
      case(wcOverHeight <= 0.464):
        waitsCircScore = 0;
        break
      case(wcOverHeight <= 0.469):
        waitsCircScore = 0.1;
        break
      case(wcOverHeight <= 0.464):
        waitsCircScore = 0.2;
        break
      case(wcOverHeight <= 0.487):
        waitsCircScore = 0.3;
        break
      case(wcOverHeight <= 0.489):
        waitsCircScore = 0.4;
        break
      case(wcOverHeight <= 0.491):
        waitsCircScore = 0.5;
        break
      case(wcOverHeight <= 0.493):
        waitsCircScore = 0.6;
        break
      case(wcOverHeight <= 0.495):
        waitsCircScore = 0.7;
        break
      case(wcOverHeight <= 0.497):
        waitsCircScore = 0.8;
        break
      case(wcOverHeight <= 0.499):
        waitsCircScore = 0.9;
        break
      case(wcOverHeight <= 0.504):
        waitsCircScore = 1;
        break
      case(wcOverHeight <= 0.519):
        waitsCircScore = 1.1;
        break
      case(wcOverHeight <= 0.529):
        waitsCircScore = 1.2;
        break
      case(wcOverHeight <= 0.539):
        waitsCircScore = 1.3;
        break
      case(wcOverHeight <= 0.549):
        waitsCircScore = 1.4;
        break
      case(wcOverHeight <= 0.559):
        waitsCircScore = 1.5;
        break
      case(wcOverHeight <= 0.569):
        waitsCircScore = 1.6;
        break
      case(wcOverHeight <= 0.579):
        waitsCircScore = 1.7;
        break
      case(wcOverHeight <= 0.589):
        waitsCircScore = 1.8;
        break
      case(wcOverHeight <= 0.599):
        waitsCircScore = 1.9;
        break
      case(wcOverHeight >= 0.6):
        waitsCircScore = 2;
        break
    }
    return waitsCircScore;
  },

  calcAdvBloodPressure(systolicBP, diastolicBP, medications) {
    let systolicBPScore = 0;
    let diastolicBPScore = 0;
    switch(true) {
      case systolicBP < 120:
        systolicBPScore = 0;
        break
      case systolicBP == 120:
        systolicBPScore = 0.1;
        break
      case systolicBP == 121:
        systolicBPScore = 0.2;
        break
      case systolicBP == 122:
        systolicBPScore = 0.3;
        break
      case systolicBP == 123:
        systolicBPScore = 0.4;
        break
      case systolicBP == 124:
        systolicBPScore = 0.5;
        break
      case systolicBP == 125:
        systolicBPScore = 0.6;
        break
      case systolicBP == 126:
        systolicBPScore = 0.7;
        break
      case systolicBP == 127:
        systolicBPScore = 0.8;
        break
      case systolicBP <= 129:
        systolicBPScore = 0.9;
        break
      case systolicBP == 130:
        systolicBPScore = 1;
        break
      case systolicBP <= 132:
        systolicBPScore = 1.1;
        break
      case systolicBP <= 134:
        systolicBPScore = 1.2;
        break
      case systolicBP <= 136:
        systolicBPScore = 1.3;
        break
      case systolicBP <= 139:
        systolicBPScore = 1.4;
        break
      case systolicBP == 140:
        systolicBPScore = 1.5;
        break
      case systolicBP <= 144:
        systolicBPScore = 1.6;
        break
      case systolicBP <= 149:
        systolicBPScore = 1.7;
        break
      case systolicBP <= 159:
        systolicBPScore = 1.8;
        break
      case systolicBP <= 179:
        systolicBPScore = 1.9;
        break
      case systolicBP >= 180:
        systolicBPScore = 2;
        break
    }

    switch(true) {
      case diastolicBP < 80:
        diastolicBPScore = 0;
        break
      case diastolicBP == 80:
        diastolicBPScore = 0.1;
        break
      case diastolicBP == 81:
        diastolicBPScore = 0.3;
        break
      case diastolicBP == 82:
        diastolicBPScore = 0.5;
        break
      case diastolicBP == 83:
        diastolicBPScore = 0.7;
        break
      case diastolicBP == 84:
        diastolicBPScore = 0.9;
        break
      case diastolicBP == 85:
        diastolicBPScore = 1;
        break
      case diastolicBP == 86:
        diastolicBPScore = 1.1;
        break
      case diastolicBP == 87:
        diastolicBPScore = 1.2;
        break
      case diastolicBP <= 88:
        diastolicBPScore = 1.3;
        break
      case diastolicBP == 89:
        diastolicBPScore = 1.4;
        break
      case diastolicBP == 90:
        diastolicBPScore = 1.5;
        break
      case diastolicBP <= 94:
        diastolicBPScore = 1.6;
        break
      case diastolicBP <= 99:
        diastolicBPScore = 1.7;
        break
      case diastolicBP <= 109:
        diastolicBPScore = 1.8;
        break
      case diastolicBP <= 119:
        diastolicBPScore = 1.9;
        break
      case diastolicBP >= 120:
        diastolicBPScore = 2;
        break
    }
    let score = systolicBPScore > diastolicBPScore ? systolicBPScore : diastolicBPScore;
    if (medications && medications.bloodPressure && score < 1) {
      return 1;
    }
    return score;
  },

  calcAdvBMI(bmi) {
    let bmiScore = 0;
    switch(true) {
      case(bmi >= 18.5 && bmi <= 24.9):
        bmiScore = 0;
        break
      case((bmi >= 25 && bmi <= 25.4) || bmi == 18.4):
        bmiScore = 0.1;
        break
      case((bmi >= 25.5 && bmi <= 25.9) || bmi == 18.3):
        bmiScore = 0.2;
        break
      case((bmi >= 26 && bmi <= 26.4) || bmi == 18.2):
        bmiScore = 0.3;
        break
      case((bmi >= 26.5 && bmi <= 26.9) || bmi == 18.1):
        bmiScore = 0.4;
        break
      case((bmi >= 27 && bmi <= 27.4) || bmi == 18):
        bmiScore = 0.5;
        break
      case((bmi >= 27.5 && bmi <= 27.9) || bmi == 17.9):
        bmiScore = 0.6;
        break
      case((bmi >= 28 && bmi <= 28.4) || bmi == 17.8):
        bmiScore = 0.7;
        break
      case((bmi >= 28.5 && bmi <= 28.9) || bmi == 17.7):
        bmiScore = 0.8;
        break
      case((bmi >= 29 && bmi <= 29.9) || bmi == 17.6):
        bmiScore = 0.9;
        break
      case((bmi > 30 && bmi <= 30.9) || bmi < 17.5):
        bmiScore = 1;
        break
      case(bmi >= 31 && bmi <= 31.9):
        bmiScore = 1.1;
        break
      case(bmi >= 32 && bmi <= 32.9):
        bmiScore = 1.2;
        break
      case(bmi >= 33 && bmi <= 33.9):
        bmiScore = 1.3;
        break
      case(bmi >= 34 && bmi <= 34.9):
        bmiScore = 1.4;
        break
      case(bmi >= 35 && bmi <= 35.9):
        bmiScore = 1.5;
        break
      case(bmi >= 36 && bmi <= 36.9):
        bmiScore = 1.6;
        break
      case(bmi >= 37 && bmi <= 37.9):
        bmiScore = 1.7;
        break
      case(bmi >= 38 && bmi <= 38.9):
        bmiScore = 1.8;
        break
      case(bmi >= 39 && bmi <= 39.9):
        bmiScore = 1.9;
        break
      case((bmi > 39 && bmi <= 39.9) || bmi >= 40):
        bmiScore = 2;
        break
    }
    return bmiScore
  },
  calcAdvAge(age) {
    let ageScore = 0;
    switch(true) {
      case (age < 50):
        ageScore = 0;
        break
      case (age >= 50 && age <= 51):
        ageScore = 0.1;
        break
      case (age == 52):
        ageScore = 0.2;
        break
      case (age == 53):
        ageScore = 0.3;
        break
      case (age == 54):
        ageScore = 0.4;
        break
      case (age == 55):
        ageScore = 0.5;
        break
      case (age == 56):
        ageScore = 0.6;
        break
      case (age == 57):
        ageScore = 0.7;
        break
      case (age == 58):
        ageScore = 0.8;
        break
      case (age == 59):
        ageScore = 0.9;
        break
      case (age == 60):
        ageScore = 1;
        break
      case (age == 61):
        ageScore = 1.1;
        break
      case (age == 62):
        ageScore = 1.2;
        break
      case (age == 63):
        ageScore = 1.3;
        break
      case (age == 64):
        ageScore = 1.4;
        break
      case (age == 65):
        ageScore = 1.5;
        break
      case (age == 66):
        ageScore = 1.6;
        break
      case (age == 67):
        ageScore = 1.7;
        break
      case (age == 68):
        ageScore = 1.8;
        break
      case (age == 69):
        ageScore = 1.9;
        break
      case (age >= 70):
        ageScore = 2;
        break
    }
    return ageScore;
  }
};
