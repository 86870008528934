let { utcToHuman } = require('../../../shared/dates');

module.exports = {
  props: {
    record: {
      type: Object,
      default: {},
    },

    index: {
      type: Number,
      default: 0,
    },

    variable: {
      default: null,
    },
  },

  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    dob() {
      return utcToHuman(this.record.dateOfBirth);
    },
  },
};
