var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manager-page" }, [
    _c("div", { staticClass: "frow column-center" }, [
      _c("h1", [_vm._v(_vm._s(_vm.currentOrganization.name))])
    ]),
    _c("div", { staticClass: "frow gutters margin-bottom-30" }, [
      _c("div", { staticClass: "col-md-1-3" }, [
        _c("div", { staticClass: "setting-card shadow-light height-100" }, [
          _c("div", { staticClass: "frow column-center" }, [
            _c("div", { staticClass: "card-title" }, [_vm._v("Company Info")]),
            _c("label", [
              _vm._v("Name"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentOrganization.name,
                    expression: "currentOrganization.name"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.currentOrganization.name },
                on: {
                  blur: function($event) {
                    return _vm.updateOrganization()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.currentOrganization,
                      "name",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("label", [
              _vm._v("Email"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentOrganization.contactEmail,
                    expression: "currentOrganization.contactEmail"
                  }
                ],
                attrs: { type: "email" },
                domProps: { value: _vm.currentOrganization.contactEmail },
                on: {
                  blur: function($event) {
                    return _vm.updateOrganization()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.currentOrganization,
                      "contactEmail",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("label", [
              _vm._v("Phone"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentOrganization.contactPhone,
                    expression: "currentOrganization.contactPhone"
                  }
                ],
                attrs: { type: "tel" },
                domProps: { value: _vm.currentOrganization.contactPhone },
                on: {
                  blur: function($event) {
                    return _vm.updateOrganization()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.currentOrganization,
                      "contactPhone",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("label", [
              _vm._v("Website"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentOrganization.url,
                    expression: "currentOrganization.url"
                  }
                ],
                attrs: { type: "url" },
                domProps: { value: _vm.currentOrganization.url },
                on: {
                  blur: function($event) {
                    return _vm.updateOrganization()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.currentOrganization,
                      "url",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("label", [
              _vm._v("Address"),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentOrganization.address,
                    expression: "currentOrganization.address"
                  }
                ],
                domProps: { value: _vm.currentOrganization.address },
                on: {
                  blur: function($event) {
                    return _vm.updateOrganization()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.currentOrganization,
                      "address",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("label", [
              _vm._v("Logo"),
              _c("input", {
                attrs: { type: "file", id: "logo-upload", accept: ".png" },
                on: { change: _vm.uploadLogo }
              }),
              _vm._m(0)
            ]),
            _c("img", {
              staticClass: "small-logo",
              attrs: { src: _vm.currentOrganization.logo }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "col-md-1-3" }, [
        _c(
          "div",
          { staticClass: "setting-card shadow-light height-100" },
          [
            _c("div", { staticClass: "card-title" }, [_vm._v("Company Data")]),
            _c("div", { staticClass: "frow tabs width-100" }, [
              _c("div", { staticClass: "col-md-1-2" }, [
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.companyDataType === "collections" },
                    on: {
                      click: function($event) {
                        return _vm.changeCompanyDataType("collections")
                      }
                    }
                  },
                  [_vm._v("Collections")]
                )
              ]),
              _c("div", { staticClass: "col-md-1-2" }, [
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.companyDataType === "departments" },
                    on: {
                      click: function($event) {
                        return _vm.changeCompanyDataType("departments")
                      }
                    }
                  },
                  [_vm._v("Departments")]
                )
              ])
            ]),
            !_vm.addData
              ? [
                  _c(
                    "nav",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.addData,
                          expression: "!addData"
                        }
                      ],
                      staticClass: "collection-list"
                    },
                    _vm._l(_vm.activeData, function(item, index) {
                      return _c("div", [
                        _c("div", { staticClass: "frow row-between" }, [
                          _c(
                            "span",
                            [
                              _c("div", { staticClass: "bold" }, [
                                _vm._v(_vm._s(item.title))
                              ]),
                              (_vm.getRole === 0 || _vm.getRole === 1) &&
                              _vm.companyDataType === "collections"
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: "/risk-calculator/" + item._id
                                      }
                                    },
                                    [_vm._v("Collect Data")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          (_vm.getRole === 0 || _vm.getRole === 1) &&
                          _vm.companyDataType === "collections"
                            ? _c(
                                "label",
                                { staticClass: "row-end width-auto" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.activeData[index].minor,
                                        expression: "activeData[index].minor"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.activeData[index].minor
                                      )
                                        ? _vm._i(
                                            _vm.activeData[index].minor,
                                            null
                                          ) > -1
                                        : _vm.activeData[index].minor
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a = _vm.activeData[index].minor,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.activeData[index],
                                                  "minor",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.activeData[index],
                                                  "minor",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.activeData[index],
                                              "minor",
                                              $$c
                                            )
                                          }
                                        },
                                        function($event) {
                                          return _vm.updateCollection(
                                            item._id,
                                            index
                                          )
                                        }
                                      ]
                                    }
                                  }),
                                  _vm._v("Minor")
                                ]
                              )
                            : _vm._e(),
                          (_vm.getRole === 0 || _vm.getRole === 1) &&
                          _vm.companyDataType === "departments"
                            ? _c(
                                "a",
                                {
                                  staticClass: "clickable",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeDepartment(item._id)
                                    }
                                  }
                                },
                                [_vm._v("Delete")]
                              )
                            : _vm._e()
                        ])
                      ])
                    }),
                    0
                  ),
                  _c("div", { staticClass: "settings-buttons" }, [
                    _c("div", { staticClass: "frow" }, [
                      (_vm.getRole === 0 || _vm.getRole === 1) &&
                      _vm.companyDataType === "collections"
                        ? _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.importLink()
                                }
                              }
                            },
                            [_vm._v("Import")]
                          )
                        : _vm._e(),
                      _vm.getRole === 0 || _vm.getRole === 1
                        ? _c(
                            "button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.addData = true
                                }
                              }
                            },
                            [_vm._v("New")]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              : [
                  _c("label", [
                    _vm._v("Title"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newData.title,
                          expression: "newData.title"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.newData.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.newData, "title", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm.companyDataType === "collections"
                    ? [
                        _c("label", [
                          _vm._v("Location"),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newData.location,
                                expression: "newData.location"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.newData.location },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newData,
                                  "location",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]
                    : [
                        _c("label", [
                          _vm._v("Description"),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newData.description,
                                expression: "newData.description"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.newData.description },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newData,
                                  "description",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ],
                  _c("div", { staticClass: "settings-buttons" }, [
                    _c("div", { staticClass: "frow" }, [
                      _c(
                        "button",
                        {
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.addData = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "button",
                        {
                          attrs: { disabled: _vm.saving },
                          on: {
                            click: function($event) {
                              return _vm.addNewData()
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    ])
                  ])
                ]
          ],
          2
        )
      ]),
      _c("div", { staticClass: "col-md-1-3" }, [
        _c("div", { staticClass: "setting-card shadow-light height-100" }, [
          _c(
            "div",
            { staticClass: "frow column-center" },
            [
              !_vm.addResource
                ? [
                    _c("div", { staticClass: "card-title" }, [
                      _vm._v("Preferred Resources")
                    ]),
                    _c("label", { staticClass: "row-start" }, [
                      _c("div", { staticClass: "col-md-1-3" }, [
                        _vm._v("My Company's Health Resources")
                      ]),
                      _c("div", { staticClass: "col-md-2-3" }, [
                        _c("div", { staticClass: "frow column-start" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentOrganization.resource
                                    .companyResource.url,
                                expression:
                                  "currentOrganization.resource.companyResource.url"
                              }
                            ],
                            attrs: {
                              placeholder: _vm.currentOrganization.url,
                              type: "url"
                            },
                            domProps: {
                              value:
                                _vm.currentOrganization.resource.companyResource
                                  .url
                            },
                            on: {
                              blur: function($event) {
                                return _vm.updateOrganization()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentOrganization.resource
                                    .companyResource,
                                  "url",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("label", { staticClass: "row-start" }, [
                      _c("div", { staticClass: "col-md-1-3" }, [
                        _vm._v("General Health")
                      ]),
                      _c("div", { staticClass: "col-md-2-3" }, [
                        _c("div", { staticClass: "frow column-start" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentOrganization.resource
                                    .generalResource.url,
                                expression:
                                  "currentOrganization.resource.generalResource.url"
                              }
                            ],
                            attrs: {
                              placeholder: "https://cdc.gov/HealthyLiving",
                              type: "url"
                            },
                            domProps: {
                              value:
                                _vm.currentOrganization.resource.generalResource
                                  .url
                            },
                            on: {
                              blur: function($event) {
                                return _vm.updateOrganization()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentOrganization.resource
                                    .generalResource,
                                  "url",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("label", { staticClass: "row-start" }, [
                      _c("div", { staticClass: "col-md-1-3" }, [
                        _vm._v("Tobacco Quit Help")
                      ]),
                      _c("div", { staticClass: "col-md-2-3" }, [
                        _c("div", { staticClass: "frow column-start" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentOrganization.resource
                                    .tobaccoResource.url,
                                expression:
                                  "currentOrganization.resource.tobaccoResource.url"
                              }
                            ],
                            attrs: {
                              placeholder: "https://smokefree.gov",
                              type: "url"
                            },
                            domProps: {
                              value:
                                _vm.currentOrganization.resource.tobaccoResource
                                  .url
                            },
                            on: {
                              blur: function($event) {
                                return _vm.updateOrganization()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentOrganization.resource
                                    .tobaccoResource,
                                  "url",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("label", { staticClass: "row-start" }, [
                      _c("div", { staticClass: "col-md-1-3" }, [
                        _vm._v("Health Coaching")
                      ]),
                      _c("div", { staticClass: "col-md-2-3" }, [
                        _c("div", { staticClass: "frow column-start" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentOrganization.resource
                                    .coachingResource.url,
                                expression:
                                  "currentOrganization.resource.coachingResource.url"
                              }
                            ],
                            attrs: {
                              placeholder: "https://wellnessdatasolutions.com",
                              type: "url"
                            },
                            domProps: {
                              value:
                                _vm.currentOrganization.resource
                                  .coachingResource.url
                            },
                            on: {
                              blur: function($event) {
                                return _vm.updateOrganization()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentOrganization.resource
                                    .coachingResource,
                                  "url",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("label", { staticClass: "row-start" }, [
                      _c("div", { staticClass: "col-md-1-3" }, [
                        _vm._v("Diabetes Info")
                      ]),
                      _c("div", { staticClass: "col-md-2-3" }, [
                        _c("div", { staticClass: "frow column-start" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentOrganization.resource
                                    .diabetesResource.url,
                                expression:
                                  "currentOrganization.resource.diabetesResource.url"
                              }
                            ],
                            attrs: {
                              placeholder: "http://diabetes.org",
                              type: "url"
                            },
                            domProps: {
                              value:
                                _vm.currentOrganization.resource
                                  .diabetesResource.url
                            },
                            on: {
                              blur: function($event) {
                                return _vm.updateOrganization()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentOrganization.resource
                                    .diabetesResource,
                                  "url",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("label", { staticClass: "row-start" }, [
                      _c("div", { staticClass: "col-md-1-3" }, [
                        _vm._v("Heart Health")
                      ]),
                      _c("div", { staticClass: "col-md-2-3" }, [
                        _c("div", { staticClass: "frow column-start" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.currentOrganization.resource.heartResource
                                    .url,
                                expression:
                                  "currentOrganization.resource.heartResource.url"
                              }
                            ],
                            attrs: {
                              placeholder: "https://heart.org",
                              type: "url"
                            },
                            domProps: {
                              value:
                                _vm.currentOrganization.resource.heartResource
                                  .url
                            },
                            on: {
                              blur: function($event) {
                                return _vm.updateOrganization()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.currentOrganization.resource
                                    .heartResource,
                                  "url",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "frow" })
                  ]
                : [
                    _c("label", { staticClass: "row-start" }, [
                      _c("div", { staticClass: "col-md-1-3" }, [
                        _vm._v("Name of Resource")
                      ]),
                      _c("div", { staticClass: "col-md-2-3" }, [
                        _c("div", { staticClass: "frow column-start" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newResource.name,
                                expression: "newResource.name"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.newResource.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newResource,
                                  "name",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("label", { staticClass: "row-start" }, [
                      _c("div", { staticClass: "col-md-1-3" }, [
                        _vm._v("Website")
                      ]),
                      _c("div", { staticClass: "col-md-2-3" }, [
                        _c("div", { staticClass: "frow column-start" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newResource.website,
                                expression: "newResource.website"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.newResource.website },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newResource,
                                  "website",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "settings-buttons" }, [
                      _c("div", { staticClass: "frow" }, [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                _vm.addResource = false
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.addNewResource()
                              }
                            }
                          },
                          [_vm._v("Save")]
                        )
                      ])
                    ])
                  ]
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "frow" }, [
      _c("small", [_c("strong", [_vm._v(".png")]), _vm._v(" format only")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }