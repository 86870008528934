let api = require('begin-build/api');
let { mapActions: mapAlertsActions } = require('begin-build/alerts');
let { mapActions: mapAuthActions, mapMutations: mapAuthMutations } = require('../store/auth');
// let collection = require('../store/collection');

module.exports = {
  components: {
    logo: require('../app/logo.svg').default,
  },

  data: () => ({
    email: null,
    key: null,
    firstName: null,
    lastName: null,
    type: 'user',
    create: false,
    rememberMe: false,
  }),

  created() {
    this.checkCreate(this.$route);
  },

  watch: {
    $route(route) {
      this.checkCreate(route);
    },
  },

  methods: Object.assign({
    checkCreate(route) {
      this.create = route.query.create !== undefined;
    },

    toggle() {
      this.create = !this.create;
      let query = {};
      if (this.$route.query.redirect) {
        query.redirect = this.$route.query.redirect;
      }
      if (this.$route.query.create === undefined) {
        query.create = null;
      }
      this.$router.push({
        query,
      });
    },

    async submit() {
      let profile = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        key: this.key,
        remember: this.rememberMe,
      };
      try {
        if (this.create) {
          let res = await api.post('profile', profile);
          this.setState(res);
        } else {
          await this.login(profile);
          // collection.init()
        }
        let { redirect = '/' } = this.$route.query;
        this.$router.push(redirect);
      } catch (e) {
        this.error(e);
      }
    },
  }, mapAlertsActions(['error']), mapAuthActions(['login']), mapAuthMutations(['setState'])),
};
