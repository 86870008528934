let { mapActions } = require('begin-build/alerts');
let api = require('begin-build/api');
let personFields = require('../../shared/person-fields');
let assessmentFields = require('../../shared/assessment-fields');

const now = new Date();
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];
const years = [...Array(100).keys()].map(year => now.getFullYear() - year);
const fields = Object.entries(Object.assign(personFields, assessmentFields));

module.exports = {
  data: () => ({
    months,
    years,
    fields,
    progress: null,
    headers: null,
    mapping: Array(fields.length).fill(null),
    month: months[now.getMonth()],
    year: now.getFullYear(),
    titlePlaceholder: null,
    title: null,
    location: null,
    uploading: false,
  }),

  methods: Object.assign({
    setTitlePlaceholder() {
      this.titlePlaceholder = `${this.month} - ${this.year}`;
    },

    handleFiles() {
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsText(this.$refs.import.files[0]);
        reader.onprogress = this.updateProgress;
        reader.onload = this.loaded;
        reader.onerror = this.errorHandler;
      } else {
        this.error('FileReader is not supported in your browser');
      }
    },

    loaded(evt) {
      this.headers = evt.target.result.split(/\r\n|\n/)[0].replace(/"/g, '').split(',');
      this.setTitlePlaceholder();
    },

    updateProgress(evt) {
      let loaded = (evt.loaded / evt.total);
      this.progress = loaded;
    },

    async upload() {
      let mapping = Array(this.headers.length).fill(null);
      this.mapping.forEach((value, key) => {
        if (value !== null) {
          mapping[value] = key;
        }
      });
      let data = new FormData();
      data.append('details', JSON.stringify({
        mapping,
        collection: {
          title: this.title || this.titlePlaceholder,
          location: this.location,
          month: this.month,
          year: this.year,
        },
      }));
      data.append('import', this.$refs.import.files[0]);
      this.uploading = true;
      try {
        await api.post(this.$route.params.id ? `import/${this.$route.params.id}` : 'import', data);
        this.success('data imported');
        this.mapping = null;
        this.$refs.importForm.reset();
        Object.assign(this.$data, this.$options.data());
      } catch (e) {
        this.error(e);
      } finally {
        this.uploading = false;
      }
    },
  }, mapActions(['error', 'success'])),
};
