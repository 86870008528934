let { password } = require('begin-server/validate');
let { mapActions } = require('begin-build/alerts');
let api = require('begin-build/api');

module.exports = {
  data: () => ({
    token: null,
    password: '',
    passwordValid: true,
  }),

  created() {
    if (this.$route.query.reset) {
      this.token = this.$route.query.reset;
    } else {
      this.$router.push('log-in');
    }
  },

  methods: Object.assign({
    passwordCheck() {
      this.passwordValid = password.test(this.password);
    },

    async submitPassword() {
      this.passwordCheck();
      if (this.passwordValid) {
        try {
          await api.put('profile/reset', {
            key: this.password,
            verify: this.token,
          });
          this.success('Password Changed');
          this.$router.push('log-in');
        } catch (e) {
          this.error(e);
        }
      } else {
        this.error('Password does not match criteria');
      }
    },
  }, mapActions(['error', 'success'])),
};
