let api = require('begin-build/api');
let store = require('begin-build/store');
let router = require('begin-build/router');

const CSRF_TOKEN = 'wds-csrf';

let setHeaders = ({ access, csrfToken }) => {
  if (access && csrfToken) {
    api.defaults.headers.common.Authorization = `Bearer ${access}`;
    api.defaults.headers.common[CSRF_TOKEN] = csrfToken;
  }
};

module.exports = store(module.id, {
  persist: true,

  state: {
    profile: null,
    organization: null,
    access: null,
    csrfToken: null,
    isLoggedIn: false,
  },

  mutations: {
    setState(state, { data, headers }) {
      let { profile, organization, access } = data;
      let csrfToken = headers[CSRF_TOKEN];
      state.profile = profile;
      state.organization = organization;
      state.access = access;
      state.csrfToken = csrfToken;
      state.isLoggedIn = true;
      setHeaders(state);
    },

    unsetState(state) {
      state.profile = null;
      state.access = null;
      state.csrfToken = null;
      state.isLoggedIn = false;
    },
  },

  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },

    access(state) {
      return state.access;
    },

    getProfile(state) {
      return state.profile;
    },

    getRole(state) {
      return state.profile && state.profile.role;
    },

    getFlags(state) {
      let out = {};
      if (!state.organization || !Array.isArray(state.organization.flags)) {
        return out;
      }
      state.organization.flags.forEach(flag => {
        out[flag] = true;
      });
      return out;
    },
  },

  actions: {
    async login({ commit }, credentials) {
      let res = await api.put('profile/login', credentials);
      commit('setState', res);
    },

    async logout({ state, commit }, { error, redirect } = {}) {
      if (state.isLoggedIn) {
        let loginState = {
          access: state.access,
        };
        api.put('profile/logout', loginState);
        commit('unsetState');
        delete api.defaults.headers.common.Authorization;
        delete api.defaults.headers.common[CSRF_TOKEN];
        store.$persist.clear();
        router.push({
          name: 'log-in',
          query: { redirect },
        });
      }
      if (error) {
        console.error(error);
      }
    },

    init({ dispatch, getters, state }) {
      setHeaders(state);
      router.beforeEach((route, redirect, next) => {
        if (getters.isLoggedIn || route.matched.some(({ meta }) => meta.publicAllowed)) {
          next();
        } else {
          next({
            path: '/log-in',
            query: {
              redirect: route.fullPath,
            },
          });
        }
      });
      api.interceptors.response.use(null, err => {
        let { status = 0 } = err.response || {};
        if (status === 403) {
          router.push('/');
        } else if (status === 401) {
          let error = (err.response && err.response.data && err.response.data.error)
            || 'unauthorized';
          dispatch('logout', {
            error,
            redirect: router.currentRoute.fullPath,
          });
        }
        throw err;
      });
    },
  },
});
