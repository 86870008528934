let store = require('begin-build/store');
let router = require('begin-build/router');

module.exports = store(module.id, {
  state: {
    visible: false,
    noSidebar: false,
    flipping: false,
  },

  mutations: {
    setVisibility(state, visible) {
      state.visible = visible;
    },

    setFlipping(state, flipping) {
      state.flipping = flipping;
    },

    setNoSidebar(state, noSidebar) {
      state.noSidebar = noSidebar;
    },
  },

  actions: {
    flip({ commit }, noFlip) {
      if (!noFlip) {
        commit('setFlipping', true);
        setTimeout(() => {
          commit('setFlipping', false);
        }, 800);
      }
    },

    hide({ commit }) {
      commit('setVisibility', false);
    },

    show({ commit }) {
      commit('setVisibility', true);
    },

    toggle({ state, commit }) {
      commit('setVisibility', !state.visible);
    },

    async init({ commit, dispatch }) {
      router.beforeEach((route, redirect, next) => {
        dispatch('hide');
        if (route.matched.some(record => record.meta.noSidebar)) {
          commit('setNoSidebar', true);
        } else {
          commit('setNoSidebar', false);
        }
        next();
      });
    },
  },
});
