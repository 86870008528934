var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "frow row-start height-100" }, [
      _c("div", { staticClass: "col-xs-1-5" }, [
        _c(
          "div",
          {
            staticClass: "toggle-sidebar-button clickable",
            class: { "back-button": _vm.visible },
            on: {
              click: function($event) {
                return _vm.toggle()
              }
            }
          },
          [_c("div"), _c("div"), _c("div")]
        )
      ]),
      _c(
        "div",
        { staticClass: "col-xs-3-5" },
        [
          _c(
            "router-link",
            { staticClass: "header-logo clickable", attrs: { to: "/" } },
            [_c("logo")],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "col-xs-1-5" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }