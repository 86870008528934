let { mapActions } = require("begin-build/alerts");
let { root } = require("begin-build/properties");
let api = require("begin-build/api");
let WDS = require("../../embed-api/index.js").default;

module.exports = {
  data: () => ({
    key: null,
    confirmGenerateKey: false
  }),

  methods: Object.assign(
    {
      async generateKey() {
        try {
          let { data } = await api.get("external/authorize");
          this.key = data.key;
        } catch (e) {
          this.error(e);
        }
        this.confirmGenerateKey = false;
      }
    },
    mapActions(["error"])
  ),

  computed: {
    embedUrl() {
      return `${root}/embed.html`;
    }
  },

  async mounted() {
    // Get API private key
    //    let {
    //      data: { key }
    //    } = await api.get("external/authorize");
    // Get API ACCESS token
    //    let {
    //      data: { access }
    //    } = await api.get("external/access", {
    //      headers: {
    //        Authorization: `Bearer ${key}`
    //      }
    //    });
    // Get EMBED token using permanent access token from assesment result
    //    let {
    //      data: { request }
    //    } = await api.get("external/embed", {
    //      headers: {
    //        Authorization: `Bearer ${access}`
    //      },
    //      params: {
    //        access:
    //          "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJwdlhhMmpvTk1ETm9MU3phIiwiYXNzZXNzbWVudCI6IkJ0emctZ3J5c3BjcFh6QlQiLCJpYXQiOjE1NTA5NzUwODYsImF1ZCI6IndlbGxuZXNzZGF0YS5zb2x1dGlvbnMjZXh0ZXJuYWwtcGVybS1yZXN1bHQtYWNjZXNzIiwiaXNzIjoid2VsbG5lc3NkYXRhLnNvbHV0aW9ucyJ9.krPzn6e8_647L4Tj7GFjMVVjneUQUcOmCc7uRiAKibH9Lmw_YX2NpQuqQgMzAFcJGhXfMMxkwFBrCy69Vq8H0w"
    //      }
    //    });
    // token above is PERM_RESULT_ACCESS
    // interface with the embed javascript api
    //    WDS({
    //      element: this.$refs.embed,
    //      request,
    //      onComplete(access) {
    //        console.log("ACCESS_KEY", access);
    // complete here
    //      }
    //    });
  }
};
