let api = require('begin-build/api');
let store = require('begin-build/store');

const ARTICLE = {
    title: 'New Article',
    text: '## Article Heading\nArticle Body',
  };

module.exports = store(module.id, {
  state: {
    activeArticle: null,
    articles: [],
  },

  getters: {
    activeArticle(state) {
      return state.activeArticle;
    },
    articles(state) {
      return state.articles;
    }
  },

  mutations: {
    setActiveArticle(state, article) {
      state.activeArticle = article;
    },
    setArticles(state, articles) {
      state.articles = articles;
    }
  },

  actions: {
    async fetchArticles({ commit }) {
      let { data } = await api.get('article');
      commit('setArticles', data);
    },
    setDefaultArticle({ commit }) {
      commit('setActiveArticle', {
        title: 'New Article',
        text: '## Article Heading\nArticle Body',
      });
    }
  },
});
