let api = require('begin-build/api');
let store = require('begin-build/store');

module.exports = store(module.id, {
  state: {
    all: null,
  },

  mutations: {
    setAll(state, data) {
      state.all = data;
    },
  },

  actions: {
    async getAll({ state, commit }) {
      if (!state.all) {
        let { data } = await api.get('collection/aggregate');
        commit('setAll', data);
      }
      return state.all;
    },
  },
});
