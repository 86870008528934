'use strict';

let validate = require('begin-server/validate');
let fields = require('./fields');

module.exports = {
  firstName: {
    text: 'First Name',
    validate: validate.any,
  },

  lastName: {
    text: 'Last Name',
    validate: validate.any,
  },

  sex: {
    text: 'Sex',
    validate: validate.any,
    description: fields.sex.description,
    parse: fields.sex.parse,
  },

  email: {
    text: 'Email',
    validate: validate.any,
  },

  ethnicity: {
    text: 'Ethnicity',
    validate: validate.any,
  },

  dateOfBirth: {
    text: 'Date of Birth',
    validate: validate.any,
    description: fields.date.description,
    parse: fields.date.parse,
  },

  phone: {
    text: 'Phone',
    validate: validate.any,
  },

  /* TODO: not included in the API 07/28 ??? */
  spouse: {
    text: 'Spouse',
    validate: validate.any,
    description: fields.bool.description,
    parse: fields.bool.parse,
  },
};
