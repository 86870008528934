'use strict';

/**
 * @module articles
 */

module.exports = {
  buildTree(articles, parent = null) {
    articles = articles.map(a => {
      if (!a.hasOwnProperty('parent')) {
        a.parent = null;
      }
      return a;
    });
    let node = [];
    articles
      .filter(a => a.parent === parent)
      .forEach(a => {
        a.children = this.buildTree(articles, a._id);
        node.push(a);
      });
    return node;
  },
  parseArticles(articles) {
    return this.buildTree(articles);
  },

};
