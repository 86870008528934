var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-page" }, [
    _c("div", { staticClass: "frow centered" }, [
      _c("div", { staticClass: "col-md-2-3" }, [
        _c(
          "div",
          { staticClass: "setting-card shadow-light height-100" },
          [
            _vm.newOrganizationView
              ? [
                  _c("div", { staticClass: "card-title" }, [
                    _vm._v("New Organization")
                  ]),
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submitOrganization()
                        }
                      }
                    },
                    [
                      _c("label", [
                        _vm._v("Name*"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newOrganization.name,
                              expression: "newOrganization.name"
                            }
                          ],
                          attrs: { type: "text", required: "" },
                          domProps: { value: _vm.newOrganization.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newOrganization,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("label", [
                        _vm._v("Email*"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newOrganization.contactEmail,
                              expression: "newOrganization.contactEmail"
                            }
                          ],
                          attrs: { type: "email", required: "" },
                          domProps: { value: _vm.newOrganization.contactEmail },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newOrganization,
                                "contactEmail",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("label", [
                        _vm._v("Phone"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newOrganization.contactPhone,
                              expression: "newOrganization.contactPhone"
                            }
                          ],
                          attrs: { type: "tel" },
                          domProps: { value: _vm.newOrganization.contactPhone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newOrganization,
                                "contactPhone",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("label", [
                        _vm._v("Website"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newOrganization.url,
                              expression: "newOrganization.url"
                            }
                          ],
                          attrs: { type: "url" },
                          domProps: { value: _vm.newOrganization.url },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newOrganization,
                                "url",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("label", [
                        _vm._v("Address"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newOrganization.address,
                              expression: "newOrganization.address"
                            }
                          ],
                          domProps: { value: _vm.newOrganization.address },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newOrganization,
                                "address",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "settings-buttons" }, [
                        _c("div", { staticClass: "frow" }, [
                          _c(
                            "button",
                            {
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.newOrganizationView = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "button",
                            {
                              attrs: {
                                type: "submit",
                                disabled:
                                  !_vm.newOrganization.name ||
                                  !_vm.newOrganization.contactEmail ||
                                  _vm.saving
                              }
                            },
                            [_vm._v("Create")]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              : [
                  _c("div", { staticClass: "frow justify-start gutters" }, [
                    _c("div", { staticClass: "col-md-1-2 border-line" }, [
                      _c("div", { staticClass: "card-title" }, [
                        _vm._v("Organizations")
                      ]),
                      _c(
                        "nav",
                        { staticClass: "admin-list" },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.displayNewOrganization()
                                }
                              }
                            },
                            [_vm._v("+ Add New Organization")]
                          ),
                          _vm._l(_vm.organizationsWithChildren, function(org) {
                            return _c(
                              "div",
                              { class: { "active-nav": _vm.isActiveNav(org) } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.fetchProfiles(org)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(org.name))]
                                ),
                                _vm._l(org.children, function(childOrg) {
                                  return _vm.isActiveNav(org)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "child-nav",
                                          class: {
                                            "active-child-nav":
                                              _vm.organization._id ===
                                              childOrg._id
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "nav-primary",
                                              on: {
                                                click: function($event) {
                                                  return _vm.fetchProfiles(
                                                    childOrg
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(childOrg.name))]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-1-2" },
                      [
                        _vm.organization._id
                          ? _c("div", { staticClass: "frow tabs" }, [
                              _c("div", { staticClass: "col-md-1-3" }, [
                                _c(
                                  "button",
                                  {
                                    attrs: {
                                      disabled: _vm.activeView === "manage"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.activeView = "manage"
                                      }
                                    }
                                  },
                                  [_vm._v("Manage")]
                                )
                              ]),
                              _c("div", { staticClass: "col-md-1-3" }, [
                                _c(
                                  "button",
                                  {
                                    attrs: {
                                      disabled: _vm.activeView === "about"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.activeView = "about"
                                      }
                                    }
                                  },
                                  [_vm._v("About")]
                                )
                              ]),
                              _c("div", { staticClass: "col-md-1-3" }, [
                                _c(
                                  "button",
                                  {
                                    attrs: {
                                      disabled: _vm.activeView === "profiles"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.activeView = "profiles"
                                      }
                                    }
                                  },
                                  [_vm._v("Profiles")]
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.activeView === "manage"
                          ? [
                              _c("div", { staticClass: "admin-category" }, [
                                _c(
                                  "div",
                                  { staticClass: "admin-category-title" },
                                  [_vm._v("Views")]
                                ),
                                _c("div", { staticClass: "manage-views" }, [
                                  _c(
                                    "div",
                                    { staticClass: "frow justify-between" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-secondary",
                                          attrs: {
                                            type: "button",
                                            to:
                                              "/dashboard/" +
                                              _vm.organization._id
                                          }
                                        },
                                        [_vm._v("Dashboard")]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-secondary",
                                          attrs: {
                                            type: "button",
                                            to:
                                              "/manager/" + _vm.organization._id
                                          }
                                        },
                                        [_vm._v("View as Manager")]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-secondary",
                                          attrs: {
                                            type: "button",
                                            to:
                                              "/insurance-table/" +
                                              _vm.organization._id
                                          }
                                        },
                                        [_vm._v("Insurance Tables")]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "nav-secondary",
                                          attrs: {
                                            type: "button",
                                            to:
                                              "/reports/" + _vm.organization._id
                                          }
                                        },
                                        [_vm._v("Patient Reports")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "admin-category" }, [
                                _c(
                                  "div",
                                  { staticClass: "admin-category-title" },
                                  [_vm._v("Options")]
                                ),
                                _c("div", { staticClass: "manage-options" }, [
                                  _c("label", { staticClass: "row-start" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.flags.api,
                                          expression: "flags.api"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(_vm.flags.api)
                                          ? _vm._i(_vm.flags.api, null) > -1
                                          : _vm.flags.api
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$a = _vm.flags.api,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "api",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "api",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(_vm.flags, "api", $$c)
                                            }
                                          },
                                          function($event) {
                                            return _vm.updateFlags()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v("API Access")
                                  ]),
                                  _c("label", { staticClass: "row-start" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.flags.reports,
                                          expression: "flags.reports"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.flags.reports
                                        )
                                          ? _vm._i(_vm.flags.reports, null) > -1
                                          : _vm.flags.reports
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$a = _vm.flags.reports,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "reports",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "reports",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.flags,
                                                "reports",
                                                $$c
                                              )
                                            }
                                          },
                                          function($event) {
                                            return _vm.updateFlags()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v("Managed patients")
                                  ]),
                                  _c("label", { staticClass: "row-start" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.flags.aggregate,
                                          expression: "flags.aggregate"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.flags.aggregate
                                        )
                                          ? _vm._i(_vm.flags.aggregate, null) >
                                            -1
                                          : _vm.flags.aggregate
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$a = _vm.flags.aggregate,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "aggregate",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "aggregate",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.flags,
                                                "aggregate",
                                                $$c
                                              )
                                            }
                                          },
                                          function($event) {
                                            return _vm.updateFlags()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v("Aggregate data access")
                                  ]),
                                  _c("label", { staticClass: "row-start" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.flags.advancedRisk,
                                          expression: "flags.advancedRisk"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.flags.advancedRisk
                                        )
                                          ? _vm._i(
                                              _vm.flags.advancedRisk,
                                              null
                                            ) > -1
                                          : _vm.flags.advancedRisk
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$a = _vm.flags.advancedRisk,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "advancedRisk",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "advancedRisk",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.flags,
                                                "advancedRisk",
                                                $$c
                                              )
                                            }
                                          },
                                          function($event) {
                                            return _vm.updateFlags()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v("Advanced Risk Score")
                                  ]),
                                  _c("label", { staticClass: "row-start" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.flags.organizationCreation,
                                          expression:
                                            "flags.organizationCreation"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.flags.organizationCreation
                                        )
                                          ? _vm._i(
                                              _vm.flags.organizationCreation,
                                              null
                                            ) > -1
                                          : _vm.flags.organizationCreation
                                      },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$a =
                                                _vm.flags.organizationCreation,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "organizationCreation",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.flags,
                                                    "organizationCreation",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.flags,
                                                "organizationCreation",
                                                $$c
                                              )
                                            }
                                          },
                                          function($event) {
                                            return _vm.updateFlags()
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(
                                      "Ability to create new child organizations"
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "admin-category" }, [
                                _c(
                                  "div",
                                  { staticClass: "admin-category-title" },
                                  [_vm._v("Parent Company")]
                                ),
                                _c("div", { staticClass: "frow" }, [
                                  _c("div", { staticClass: "col-xs-1-1" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.organization
                                                .parentOrganization,
                                            expression:
                                              "organization.parentOrganization"
                                          }
                                        ],
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.organization,
                                                "parentOrganization",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            _vm.updateParentOrganization
                                          ]
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("None")
                                        ]),
                                        _vm._l(
                                          _vm.organizationsExcludingSelf,
                                          function(org) {
                                            return _c(
                                              "option",
                                              { domProps: { value: org._id } },
                                              [_vm._v(_vm._s(org.name))]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "admin-category" }, [
                                _c(
                                  "div",
                                  { staticClass: "admin-category-title" },
                                  [_vm._v("Export")]
                                ),
                                _c("div", { staticClass: "frow" }, [
                                  _c("div", { staticClass: "col-xs-3-4" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.currentCollection,
                                            expression: "currentCollection"
                                          }
                                        ],
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.currentCollection = $event
                                              .target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          }
                                        }
                                      },
                                      _vm._l(_vm.collections, function(
                                        collection
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            attrs: { selected: "" },
                                            domProps: { value: collection._id }
                                          },
                                          [_vm._v(_vm._s(collection.title))]
                                        )
                                      }),
                                      0
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-xs-1-4" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ml-10",
                                        attrs: {
                                          type: "button",
                                          href:
                                            _vm.api +
                                            "collection/export/" +
                                            _vm.currentCollection +
                                            "?authorization=" +
                                            _vm.access,
                                          download: "",
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("Export")]
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "disclaimer" }, [
                                  _vm._v(
                                    "Please be sure to always keep downloaded data secure, encrypted, and HIPAA compliant."
                                  )
                                ])
                              ])
                            ]
                          : _vm.activeView === "about"
                          ? [
                              _vm.organization.logo
                                ? _c("div", { staticClass: "frow" }, [
                                    _c("img", {
                                      staticClass: "small-logo mb-15",
                                      attrs: { src: _vm.organization.logo }
                                    })
                                  ])
                                : _vm._e(),
                              _c("label", [
                                _vm._v("Name*"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.organization.name,
                                      expression: "organization.name"
                                    }
                                  ],
                                  attrs: { type: "text", disabled: "" },
                                  domProps: { value: _vm.organization.name },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.organization,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("label", [
                                _vm._v("Email*"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.organization.contactEmail,
                                      expression: "organization.contactEmail"
                                    }
                                  ],
                                  attrs: { type: "email", disabled: "" },
                                  domProps: {
                                    value: _vm.organization.contactEmail
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.organization,
                                        "contactEmail",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("label", [
                                _vm._v("Phone"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.organization.contactPhone,
                                      expression: "organization.contactPhone"
                                    }
                                  ],
                                  attrs: { type: "tel", disabled: "" },
                                  domProps: {
                                    value: _vm.organization.contactPhone
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.organization,
                                        "contactPhone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("label", [
                                _vm._v("Website"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.organization.url,
                                      expression: "organization.url"
                                    }
                                  ],
                                  attrs: { type: "url", disabled: "" },
                                  domProps: { value: _vm.organization.url },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.organization,
                                        "url",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("label", [
                                _vm._v("Address"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.organization.address,
                                      expression: "organization.address"
                                    }
                                  ],
                                  attrs: { disabled: "" },
                                  domProps: { value: _vm.organization.address },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.organization,
                                        "address",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]
                          : _vm.activeView === "profiles"
                          ? [
                              _c(
                                "nav",
                                { staticClass: "admin-list" },
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.activeView = "newProfile"
                                        }
                                      }
                                    },
                                    [_vm._v("+ Add New Profile")]
                                  ),
                                  _vm._l(_vm.profiles, function(profile) {
                                    return _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "nav-primary" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              profile.firstName +
                                                " " +
                                                profile.lastName
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "nav-secondary" },
                                        [_vm._v(_vm._s(profile.email))]
                                      )
                                    ])
                                  })
                                ],
                                2
                              )
                            ]
                          : _vm.activeView === "newProfile"
                          ? [
                              _c("div", { staticClass: "card-title" }, [
                                _vm._v("New Profile")
                              ]),
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.submitProfile()
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "frow gutters" }, [
                                    _c("div", { staticClass: "col-md-1-2" }, [
                                      _c("label", [
                                        _vm._v("First Name"),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.profile.firstName,
                                              expression: "profile.firstName"
                                            }
                                          ],
                                          attrs: { type: "text", required: "" },
                                          domProps: {
                                            value: _vm.profile.firstName
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.profile,
                                                "firstName",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ]),
                                    _c("div", { staticClass: "col-md-1-2" }, [
                                      _c("label", [
                                        _vm._v("Last Name"),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.profile.lastName,
                                              expression: "profile.lastName"
                                            }
                                          ],
                                          attrs: { type: "text", required: "" },
                                          domProps: {
                                            value: _vm.profile.lastName
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.profile,
                                                "lastName",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ]),
                                    _c("div", { staticClass: "col-md-1-2" }, [
                                      _c("label", [
                                        _vm._v("Email"),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.profile.email,
                                              expression: "profile.email"
                                            }
                                          ],
                                          attrs: {
                                            type: "email",
                                            required: ""
                                          },
                                          domProps: {
                                            value: _vm.profile.email
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.profile,
                                                "email",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ]),
                                    _c("div", { staticClass: "col-md-1-2" }, [
                                      _c("label", [
                                        _vm._v("Role"),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.profile.role,
                                                expression: "profile.role"
                                              }
                                            ],
                                            attrs: { required: "" },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.profile,
                                                  "role",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "2" } },
                                              [_vm._v("Assessor")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "3" } },
                                              [_vm._v("Manager")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "4" } },
                                              [_vm._v("Supervisor")]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "settings-buttons" },
                                    [
                                      _c("div", { staticClass: "frow" }, [
                                        _c(
                                          "button",
                                          {
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                _vm.activeView = "profiles"
                                              }
                                            }
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            attrs: {
                                              type: "submit",
                                              disabled:
                                                !_vm.profile.firstName ||
                                                !_vm.profile.email ||
                                                !_vm.profile.lastName ||
                                                !_vm.profile.role ||
                                                _vm.saving
                                            }
                                          },
                                          [_vm._v("Create")]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ]
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }