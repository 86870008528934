var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wiki-page height-100" }, [
    _c("div", { staticClass: "frow height-100" }, [
      _c("div", { staticClass: "col-md-1-5" }, [
        _c(
          "nav",
          { staticClass: "display-list" },
          [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: { click: _vm.setDefaultArticle }
              },
              [_vm._v("Add a New Wiki Page")]
            ),
            _c("tree", { attrs: { model: _vm.articleContainer } })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "col-md-4-5" },
        [_c("editor", { on: { saved: _vm.refreshArticles } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }