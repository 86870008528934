const THIRD = (1 / 3);

module.exports = {
  props: {

    title: {
      type: String,
      default: '',
    },

    units: {
      type: String,
      default: '',
    },

    value: {
      default: 0,
    },

    flip: {
      type: Boolean,
      default: false,
    },

    add: {
      type: Number,
      default: 0,
    },

    min: {
      type: Number,
      default: 0,
    },

    mid: {
      type: Number,
      default: 0,
    },

    high: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    start() {
      return this.min + this.add;
    },

    midMin() {
      return this.mid + this.add;
    },

    midMax() {
      return this.high + this.add;
    },

    end() {
      return this.max + this.add;
    },

    calcClass() {
      if (!this.flip) {
        if (this.value >= this.midMin && this.value < this.midMax) {
          return 'yellow';
        }
        if (this.value < this.midMin) {
          return 'green';
        }
        if (this.value >= this.midMax ) {
          return 'red';
        }
      } else {
        if (this.value > this.midMin && this.value <= this.midMax) {
          return 'yellow';
        }
        if (this.value <= this.midMin) {
          return 'red';
        }
        if (this.value > this.midMax ) {
          return 'green';
        }
      }
    },

    calcRange() {
      let min = this.midMin;
      let max = this.midMax;
      let input = +(this.value || 0);
      let pad = 0;
      if (input > this.end) {
        input = this.end;
      } else if (input < this.start) {
        input = this.start;
      }
      if (input < this.midMin) {
        min = this.start;
        max = this.midMin;
      } else if (input >= this.midMax) {
        min = this.midMax;
        max = this.end;
        pad = 2 * THIRD;
      } else {
        pad = THIRD;
      }
      let percentage = (input - min) / (max - min);
      return (percentage * THIRD) + pad;
    },
  },
};
