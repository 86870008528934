var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.alerts.length > 0,
          expression: "alerts.length > 0"
        }
      ],
      staticClass: "alerts-container do-not-print"
    },
    _vm._l(_vm.alerts, function(alert) {
      return _c("div", { staticClass: "alert-container", class: alert.type }, [
        _c("span", [_vm._v(_vm._s(alert.type) + ":")]),
        _vm._v(" " + _vm._s(alert.message)),
        _c("div", {
          staticClass: "alert-container-close clickable",
          on: {
            click: function($event) {
              return _vm.dismiss(alert.id)
            }
          }
        })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }