var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "print-contain frow justify-start height-100" },
    [
      _c("div", { staticClass: "col-md-1-4 height-100 do-not-print" }, [
        _c("div", { staticClass: "risk-sidebar height-100" }, [
          _c(
            "form",
            {
              staticClass: "form-details height-100",
              attrs: { autocomplete: "off" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              !_vm.saving
                ? [
                    _c(
                      "div",
                      { staticClass: "sidebar-group" },
                      [
                        _c("label", { staticClass: "not-clickable" }, [
                          _vm._v(_vm._s(_vm.currentOrganization.name))
                        ]),
                        _c("label", { staticClass: "autocomplete" }, [
                          _vm._v("Last Name"),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.assessment.lastName,
                                expression: "assessment.lastName"
                              }
                            ],
                            attrs: { type: "text", required: "" },
                            domProps: { value: _vm.assessment.lastName },
                            on: {
                              keyup: function($event) {
                                return _vm.searchForPerson()
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.assessment,
                                  "lastName",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.searchResults && !_vm.selectedResult
                            ? _c(
                                "nav",
                                { staticClass: "dark-card-shadow" },
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.selectedResult = true
                                        }
                                      }
                                    },
                                    [_vm._v("+ New Entry")]
                                  ),
                                  _vm._l(_vm.searchResults, function(result) {
                                    return _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.selectResult(result)
                                          }
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(result.firstName) +
                                              " " +
                                              _vm._s(result.lastName)
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "Born: " +
                                              _vm._s(
                                                _vm.dateToReadable(
                                                  result.dateOfBirth
                                                )
                                              )
                                          )
                                        ])
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ]),
                        _vm.selectedResult
                          ? [
                              _c("label", [
                                _vm._v("First Name"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.assessment.firstName,
                                      expression: "assessment.firstName"
                                    }
                                  ],
                                  attrs: { type: "text", required: "" },
                                  domProps: { value: _vm.assessment.firstName },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.assessment,
                                        "firstName",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("label", [
                                _vm._v("Email"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.assessment.email,
                                      expression: "assessment.email"
                                    }
                                  ],
                                  attrs: { type: "email", required: "" },
                                  domProps: { value: _vm.assessment.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.assessment,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("label", [
                                _vm._v("Phone Number"),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.assessment.phone,
                                      expression: "assessment.phone"
                                    }
                                  ],
                                  attrs: { type: "tel", required: "" },
                                  domProps: { value: _vm.assessment.phone },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.assessment,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm.selectedResult
                      ? [
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c("label", [
                              _vm._v("Ethnicity"),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.assessment.ethnicity,
                                      expression: "assessment.ethnicity"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.assessment,
                                        "ethnicity",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.ethnicities, function(ethnicity) {
                                  return _c("option", {
                                    domProps: {
                                      value: ethnicity,
                                      textContent: _vm._s(ethnicity)
                                    }
                                  })
                                }),
                                0
                              )
                            ]),
                            _c("div", { staticClass: "sidebar-radio-select" }, [
                              _c("div", { staticClass: "frow" }, [
                                _c("div", { staticClass: "col-xs-1-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "frow column-center width-100"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "row-center" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.assessment.sex,
                                                expression: "assessment.sex"
                                              }
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "f"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.assessment.sex,
                                                "f"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.assessment,
                                                  "sex",
                                                  "f"
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v("Female")
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "col-xs-1-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "frow column-center width-100"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "row-center" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.assessment.sex,
                                                expression: "assessment.sex"
                                              }
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "m"
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.assessment.sex,
                                                "m"
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.assessment,
                                                  "sex",
                                                  "m"
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v("Male")
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "frow gutters" }, [
                              _c("div", { staticClass: "col-xs-1-3" }, [
                                _c("label", [
                                  _vm._v("DOB"),
                                  _c("br"),
                                  _vm._v("Month"),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.assessment.dobMonth,
                                        expression: "assessment.dobMonth"
                                      }
                                    ],
                                    attrs: {
                                      placeholder: "MM",
                                      maxlength: "2",
                                      type: "text"
                                    },
                                    domProps: {
                                      value: _vm.assessment.dobMonth
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.assessment,
                                          "dobMonth",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _c("div", { staticClass: "col-xs-1-3" }, [
                                _c("label", [
                                  _vm._v("DOB"),
                                  _c("br"),
                                  _vm._v("Day"),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.assessment.dobDay,
                                        expression: "assessment.dobDay"
                                      }
                                    ],
                                    attrs: {
                                      placeholder: "DD",
                                      maxlength: "2",
                                      type: "text"
                                    },
                                    domProps: { value: _vm.assessment.dobDay },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.assessment,
                                          "dobDay",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _c("div", { staticClass: "col-xs-1-3" }, [
                                _c("label", [
                                  _vm._v("DOB"),
                                  _c("br"),
                                  _vm._v("Year"),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.assessment.dobYear,
                                        expression: "assessment.dobYear"
                                      }
                                    ],
                                    attrs: {
                                      placeholder: "YYYY",
                                      maxlength: "4",
                                      type: "text"
                                    },
                                    domProps: { value: _vm.assessment.dobYear },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.assessment,
                                          "dobYear",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ]),
                            _c("label", [
                              _vm._v("Are you a spouse of a current employee?")
                            ]),
                            _c("div", { staticClass: "frow" }, [
                              _c("div", { staticClass: "col-xs-1-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "frow column-center width-100"
                                  },
                                  [
                                    _c("label", { staticClass: "row-center" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.assessment.spouse,
                                            expression: "assessment.spouse"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(
                                            _vm.assessment.spouse,
                                            true
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.assessment,
                                              "spouse",
                                              true
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v("Yes")
                                    ])
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "col-xs-1-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "frow column-center width-100"
                                  },
                                  [
                                    _c("label", { staticClass: "row-center" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.assessment.spouse,
                                            expression: "assessment.spouse"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(
                                            _vm.assessment.spouse,
                                            false
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.assessment,
                                              "spouse",
                                              false
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v("No")
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c("label", [
                              _vm._v("Insurance Plan"),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedPlan,
                                      expression: "selectedPlan"
                                    }
                                  ],
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.selectedPlan = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function($event) {
                                        return _vm.setPlan(_vm.selectedPlan)
                                      }
                                    ]
                                  }
                                },
                                _vm._l(_vm.insurancePlans, function(plan) {
                                  return _c("option", {
                                    domProps: {
                                      value: plan._id,
                                      textContent: _vm._s(plan.name)
                                    }
                                  })
                                }),
                                0
                              )
                            ]),
                            _c("label", [
                              _vm._v("Department"),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.assessment.department,
                                      expression: "assessment.department"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.assessment,
                                        "department",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(
                                  _vm.currentOrganization.departments,
                                  function(department) {
                                    return _c("option", {
                                      domProps: {
                                        value: department._id,
                                        textContent: _vm._s(department.title)
                                      }
                                    })
                                  }
                                ),
                                0
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c("div", { staticClass: "frow gutters" }, [
                              _c("div", { staticClass: "col-xs-1-2" }, [
                                _c("label", [
                                  _vm._v("Height"),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.assessment.heightFT,
                                        expression: "assessment.heightFT"
                                      }
                                    ],
                                    staticClass: "js-height",
                                    attrs: {
                                      placeholder: "FT",
                                      maxlength: "1",
                                      type: "text"
                                    },
                                    domProps: {
                                      value: _vm.assessment.heightFT
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.assessment,
                                          "heightFT",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ]),
                              _c("div", { staticClass: "col-xs-1-2" }, [
                                _c("label", [
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.assessment.heightIN,
                                        expression: "assessment.heightIN"
                                      }
                                    ],
                                    staticClass: "js-height",
                                    attrs: {
                                      placeholder: "IN",
                                      maxlength: "5",
                                      type: "text"
                                    },
                                    domProps: {
                                      value: _vm.assessment.heightIN
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.assessment,
                                          "heightIN",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "frow justify-start gutters" },
                              [
                                _c("div", { staticClass: "col-xs-1-2" }, [
                                  _c("label", [
                                    _vm._v("Weight"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.weight,
                                          expression: "assessment.weight"
                                        }
                                      ],
                                      staticClass: "js-weight",
                                      attrs: {
                                        placeholder: "LBS",
                                        type: "text"
                                      },
                                      domProps: {
                                        value: _vm.assessment.weight
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "weight",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c(
                              "div",
                              { staticClass: "frow justify-start gutters" },
                              [
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("Systolic BP"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.systolicBP,
                                          expression: "assessment.systolicBP"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.assessment.systolicBP
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "systolicBP",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("Diastolic BP"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.diastolicBP,
                                          expression: "assessment.diastolicBP"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.assessment.diastolicBP
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "diastolicBP",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("Waist Circ."),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.waistCirc,
                                          expression: "assessment.waistCirc"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.assessment.waistCirc
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "waistCirc",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("Triglycerides"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.triglycerides,
                                          expression: "assessment.triglycerides"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.assessment.triglycerides
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "triglycerides",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("Blood Glucose"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.bloodGlucose,
                                          expression: "assessment.bloodGlucose"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.assessment.bloodGlucose
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "bloodGlucose",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("A1c"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.a1c,
                                          expression: "assessment.a1c"
                                        }
                                      ],
                                      attrs: { placeholder: "%", type: "text" },
                                      domProps: { value: _vm.assessment.a1c },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "a1c",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("Total Cholesterol"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.assessment.totalCholesterol,
                                          expression:
                                            "assessment.totalCholesterol"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.assessment.totalCholesterol
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "totalCholesterol",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("LDL Cholesterol"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.ldlCholesterol,
                                          expression:
                                            "assessment.ldlCholesterol"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.assessment.ldlCholesterol
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "ldlCholesterol",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-2" }, [
                                  _c("label", [
                                    _vm._v("HDL"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.assessment.hdl,
                                          expression: "assessment.hdl"
                                        }
                                      ],
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.assessment.hdl },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.assessment,
                                            "hdl",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                ])
                              ]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "sidebar-group" },
                            [
                              _vm.bloodWork === false || _vm.bloodWork === true
                                ? [
                                    _c("label", [
                                      _vm._v("Create blood work order?")
                                    ]),
                                    _c("div", { staticClass: "frow" }, [
                                      _c("div", { staticClass: "col-xs-1-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "frow column-center width-100"
                                          },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "row-start" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.bloodWork,
                                                      expression: "bloodWork"
                                                    }
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: true,
                                                    checked: _vm._q(
                                                      _vm.bloodWork,
                                                      true
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      _vm.bloodWork = true
                                                    }
                                                  }
                                                }),
                                                _vm._v("Yes")
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "col-xs-1-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "frow column-center width-100"
                                          },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "row-start" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.bloodWork,
                                                      expression: "bloodWork"
                                                    }
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: false,
                                                    checked: _vm._q(
                                                      _vm.bloodWork,
                                                      false
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      _vm.bloodWork = false
                                                    }
                                                  }
                                                }),
                                                _vm._v("No")
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ]
                                : _c("div", { staticClass: "frow" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Blood Work Status: " +
                                          _vm._s(
                                            _vm.bloodWork.status.toUpperCase()
                                          )
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            type: "button",
                                            download: "blood-work.pdf",
                                            href:
                                              "data:application/pdf;base64," +
                                              (_vm.bloodWork.results &&
                                              _vm.bloodWork.results.pdf
                                                ? _vm.bloodWork.results.pdf
                                                : _vm.bloodWork
                                                    .requisition_pdf),
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("Download PDF")]
                                      )
                                    ])
                                  ]),
                              _vm.bloodWork === true
                                ? [
                                    _c("label", [
                                      _vm._v("Address"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.address,
                                            expression: "address"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.address },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.address = $event.target.value
                                          }
                                        }
                                      })
                                    ]),
                                    _c("label", [
                                      _vm._v("City"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.city,
                                            expression: "city"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: { value: _vm.city },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.city = $event.target.value
                                          }
                                        }
                                      })
                                    ]),
                                    _c("label", [
                                      _vm._v("State"),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.state,
                                              expression: "state"
                                            }
                                          ],
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.state = $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            }
                                          }
                                        },
                                        _vm._l(_vm.states, function(name, id) {
                                          return _c(
                                            "option",
                                            { domProps: { value: id } },
                                            [_vm._v(_vm._s(name))]
                                          )
                                        }),
                                        0
                                      )
                                    ]),
                                    _c("label", [
                                      _vm._v("Zip"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.zip,
                                            expression: "zip"
                                          }
                                        ],
                                        attrs: { type: "text", maxlength: "5" },
                                        domProps: { value: _vm.zip },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.zip = $event.target.value
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c("label", [_vm._v("Nicotine Use?")]),
                            _c("div", { staticClass: "frow" }, [
                              _c("div", { staticClass: "col-xs-1-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "frow column-center width-100"
                                  },
                                  [
                                    _c("label", { staticClass: "row-start" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.assessment.nicotine,
                                            expression: "assessment.nicotine"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(
                                            _vm.assessment.nicotine,
                                            true
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.assessment,
                                              "nicotine",
                                              true
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v("Yes")
                                    ])
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "col-xs-1-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "frow column-center width-100"
                                  },
                                  [
                                    _c("label", { staticClass: "row-start" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.assessment.nicotine,
                                            expression: "assessment.nicotine"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(
                                            _vm.assessment.nicotine,
                                            false
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.assessment,
                                              "nicotine",
                                              false
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v("No")
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c("label", [
                              _vm._v("Family History of Diabetes?")
                            ]),
                            _c("div", { staticClass: "frow" }, [
                              _c("div", { staticClass: "col-xs-1-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "frow column-center width-100"
                                  },
                                  [
                                    _c("label", { staticClass: "row-start" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.assessment
                                                .familyHistoryDiabetes,
                                            expression:
                                              "assessment.familyHistoryDiabetes"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: true,
                                          checked: _vm._q(
                                            _vm.assessment
                                              .familyHistoryDiabetes,
                                            true
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.assessment,
                                              "familyHistoryDiabetes",
                                              true
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v("Yes")
                                    ])
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "col-xs-1-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "frow column-center width-100"
                                  },
                                  [
                                    _c("label", { staticClass: "row-start" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.assessment
                                                .familyHistoryDiabetes,
                                            expression:
                                              "assessment.familyHistoryDiabetes"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: false,
                                          checked: _vm._q(
                                            _vm.assessment
                                              .familyHistoryDiabetes,
                                            false
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.assessment,
                                              "familyHistoryDiabetes",
                                              false
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v("No")
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c(
                              "div",
                              { staticClass: "sidebar-checkbox-select" },
                              [
                                _c("label", [
                                  _vm._v("Diagnosed by a physician with:")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "frow column-start" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .diagnosedWith
                                                        .heartDisease,
                                                    expression:
                                                      "assessment.diagnosedWith.heartDisease"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.diagnosedWith
                                                      .heartDisease
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .diagnosedWith
                                                          .heartDisease,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .diagnosedWith
                                                        .heartDisease
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .diagnosedWith
                                                          .heartDisease,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .diagnosedWith,
                                                            "heartDisease",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .diagnosedWith,
                                                            "heartDisease",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .diagnosedWith,
                                                        "heartDisease",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Heart Disease")
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .diagnosedWith
                                                        .cardiovascularDisease,
                                                    expression:
                                                      "assessment.diagnosedWith.cardiovascularDisease"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.diagnosedWith
                                                      .cardiovascularDisease
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .diagnosedWith
                                                          .cardiovascularDisease,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .diagnosedWith
                                                        .cardiovascularDisease
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .diagnosedWith
                                                          .cardiovascularDisease,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .diagnosedWith,
                                                            "cardiovascularDisease",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .diagnosedWith,
                                                            "cardiovascularDisease",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .diagnosedWith,
                                                        "cardiovascularDisease",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Hypertension")
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .diagnosedWith.diabetes,
                                                    expression:
                                                      "assessment.diagnosedWith.diabetes"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.diagnosedWith
                                                      .diabetes
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .diagnosedWith
                                                          .diabetes,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .diagnosedWith.diabetes
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .diagnosedWith
                                                          .diabetes,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .diagnosedWith,
                                                            "diabetes",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .diagnosedWith,
                                                            "diabetes",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .diagnosedWith,
                                                        "diabetes",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Diabetes")
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .diagnosedWith.cancer,
                                                    expression:
                                                      "assessment.diagnosedWith.cancer"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.diagnosedWith
                                                      .cancer
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .diagnosedWith.cancer,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .diagnosedWith.cancer
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .diagnosedWith.cancer,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .diagnosedWith,
                                                            "cancer",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .diagnosedWith,
                                                            "cancer",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .diagnosedWith,
                                                        "cancer",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Cancer")
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "sidebar-checkbox-select" },
                              [
                                _c("label", [
                                  _vm._v("Currently taking medication for:")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "frow column-start" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment.medications
                                                        .cholesterol,
                                                    expression:
                                                      "assessment.medications.cholesterol"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.medications
                                                      .cholesterol
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .medications
                                                          .cholesterol,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment.medications
                                                        .cholesterol
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .medications
                                                          .cholesterol,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "cholesterol",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "cholesterol",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .medications,
                                                        "cholesterol",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Cholesterol")
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment.medications
                                                        .bloodPressure,
                                                    expression:
                                                      "assessment.medications.bloodPressure"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.medications
                                                      .bloodPressure
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .medications
                                                          .bloodPressure,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment.medications
                                                        .bloodPressure
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .medications
                                                          .bloodPressure,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "bloodPressure",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "bloodPressure",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .medications,
                                                        "bloodPressure",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Blood Pressure")
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment.medications
                                                        .diabetes,
                                                    expression:
                                                      "assessment.medications.diabetes"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.medications
                                                      .diabetes
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .medications.diabetes,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment.medications
                                                        .diabetes
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .medications.diabetes,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "diabetes",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "diabetes",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .medications,
                                                        "diabetes",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Diabetes")
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment.medications
                                                        .asthma,
                                                    expression:
                                                      "assessment.medications.asthma"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.medications
                                                      .asthma
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .medications.asthma,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment.medications
                                                        .asthma
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .medications.asthma,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "asthma",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "asthma",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .medications,
                                                        "asthma",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Asthma")
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment.medications
                                                        .depression,
                                                    expression:
                                                      "assessment.medications.depression"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.medications
                                                      .depression
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .medications
                                                          .depression,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment.medications
                                                        .depression
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .medications
                                                          .depression,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "depression",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "depression",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .medications,
                                                        "depression",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Depression")
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment.medications
                                                        .betaBlocker,
                                                    expression:
                                                      "assessment.medications.betaBlocker"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment.medications
                                                      .betaBlocker
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .medications
                                                          .betaBlocker,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment.medications
                                                        .betaBlocker
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .medications
                                                          .betaBlocker,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "betaBlocker",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .medications,
                                                            "betaBlocker",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .medications,
                                                        "betaBlocker",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v("Beta Blocker")
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c(
                              "div",
                              { staticClass: "sidebar-checkbox-select" },
                              [
                                _c("label", [_vm._v("Recent Checkups:")]),
                                _c(
                                  "div",
                                  { staticClass: "frow column-start" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .recentCheckups.dental,
                                                    expression:
                                                      "assessment.recentCheckups.dental"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment
                                                      .recentCheckups.dental
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .dental,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .recentCheckups.dental
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .dental,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "dental",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "dental",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .recentCheckups,
                                                        "dental",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "Dental Exam (in last 1 year)"
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .recentCheckups
                                                        .mammogram,
                                                    expression:
                                                      "assessment.recentCheckups.mammogram"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment
                                                      .recentCheckups.mammogram
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .mammogram,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .recentCheckups
                                                        .mammogram
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .mammogram,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "mammogram",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "mammogram",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .recentCheckups,
                                                        "mammogram",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "Mammogram (in last 2 years)"
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .recentCheckups
                                                        .cervicalCancer,
                                                    expression:
                                                      "assessment.recentCheckups.cervicalCancer"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment
                                                      .recentCheckups
                                                      .cervicalCancer
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .cervicalCancer,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .recentCheckups
                                                        .cervicalCancer
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .cervicalCancer,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "cervicalCancer",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "cervicalCancer",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .recentCheckups,
                                                        "cervicalCancer",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "Cervical Cancer Screening (in last 3 years)"
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .recentCheckups
                                                        .colorectalCancer,
                                                    expression:
                                                      "assessment.recentCheckups.colorectalCancer"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment
                                                      .recentCheckups
                                                      .colorectalCancer
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .colorectalCancer,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .recentCheckups
                                                        .colorectalCancer
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .colorectalCancer,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "colorectalCancer",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "colorectalCancer",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .recentCheckups,
                                                        "colorectalCancer",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "Colorectal Cancer Screening (in last 5 years)"
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-checkbox-row" },
                                      [
                                        _c("div", { staticClass: "frow" }, [
                                          _c(
                                            "label",
                                            { staticClass: "row-start" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.assessment
                                                        .recentCheckups
                                                        .fluVaccination,
                                                    expression:
                                                      "assessment.recentCheckups.fluVaccination"
                                                  }
                                                ],
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.assessment
                                                      .recentCheckups
                                                      .fluVaccination
                                                  )
                                                    ? _vm._i(
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .fluVaccination,
                                                        null
                                                      ) > -1
                                                    : _vm.assessment
                                                        .recentCheckups
                                                        .fluVaccination
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.assessment
                                                          .recentCheckups
                                                          .fluVaccination,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "fluVaccination",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.assessment
                                                              .recentCheckups,
                                                            "fluVaccination",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.assessment
                                                          .recentCheckups,
                                                        "fluVaccination",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _vm._v(
                                                "Flu Vaccination (in last 1 year)"
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c("div", { staticClass: "sidebar-radio-select" }, [
                              _c("label", [_vm._v("Participation")]),
                              _c("div", { staticClass: "frow" }, [
                                _c("div", { staticClass: "col-xs-1-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "frow column-center width-100"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "row-center" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.assessment.participation,
                                                expression:
                                                  "assessment.participation"
                                              }
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: true,
                                              checked: _vm._q(
                                                _vm.assessment.participation,
                                                true
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.assessment,
                                                  "participation",
                                                  true
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v("Yes")
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "col-xs-1-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "frow column-center width-100"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "row-center" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.assessment.participation,
                                                expression:
                                                  "assessment.participation"
                                              }
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: false,
                                              checked: _vm._q(
                                                _vm.assessment.participation,
                                                false
                                              )
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.$set(
                                                  _vm.assessment,
                                                  "participation",
                                                  false
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v("No")
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "frow justify-start gutters" },
                              [
                                _c("div", { staticClass: "col-md-1-1" }, [
                                  _c("label", [
                                    _vm._v(
                                      "Readiness to make diet and nutrition changes?"
                                    ),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.assessment.readinessDiet,
                                            expression:
                                              "assessment.readinessDiet"
                                          }
                                        ],
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.assessment,
                                              "readinessDiet",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: 0 } },
                                          [_vm._v("Low")]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 0.5 } },
                                          [_vm._v("Medium")]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 1 } },
                                          [_vm._v("High")]
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "col-md-1-1" }, [
                                  _c("label", [
                                    _vm._v(
                                      "Readiness to make physical activity changes?"
                                    ),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.assessment.readinessPhysical,
                                            expression:
                                              "assessment.readinessPhysical"
                                          }
                                        ],
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.assessment,
                                              "readinessPhysical",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: 0 } },
                                          [_vm._v("Low")]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 0.5 } },
                                          [_vm._v("Medium")]
                                        ),
                                        _c(
                                          "option",
                                          { domProps: { value: 1 } },
                                          [_vm._v("High")]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "sidebar-radio-select" }, [
                              _c("label", [_vm._v("Health Coaching?")]),
                              _c(
                                "div",
                                { staticClass: "frow" },
                                [
                                  _c("div", { staticClass: "col-xs-1-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "frow column-center width-100"
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "row-center" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.assessment
                                                      .healthCoaching,
                                                  expression:
                                                    "assessment.healthCoaching"
                                                }
                                              ],
                                              attrs: { type: "radio" },
                                              domProps: {
                                                value: true,
                                                checked: _vm._q(
                                                  _vm.assessment.healthCoaching,
                                                  true
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.assessment,
                                                    "healthCoaching",
                                                    true
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v("Yes")
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-xs-1-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "frow column-center width-100"
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "row-center" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.assessment
                                                      .healthCoaching,
                                                  expression:
                                                    "assessment.healthCoaching"
                                                }
                                              ],
                                              attrs: { type: "radio" },
                                              domProps: {
                                                value: false,
                                                checked: _vm._q(
                                                  _vm.assessment.healthCoaching,
                                                  false
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.assessment,
                                                    "healthCoaching",
                                                    false
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v("No")
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm.assessment.healthCoaching
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "frow" },
                                          [
                                            _c(
                                              "external",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  href:
                                                    "https://wdscoach.simplybook.me"
                                                }
                                              },
                                              [_vm._v("Submit Appointment")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c("div", { staticClass: "frow column-start" }, [
                                _c(
                                  "div",
                                  { staticClass: "print-checkbox-row" },
                                  [
                                    _c("div", { staticClass: "frow" }, [
                                      _c(
                                        "label",
                                        { staticClass: "row-start" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.assessment
                                                    .physicianConsult,
                                                expression:
                                                  "assessment.physicianConsult"
                                              }
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.assessment.physicianConsult
                                              )
                                                ? _vm._i(
                                                    _vm.assessment
                                                      .physicianConsult,
                                                    null
                                                  ) > -1
                                                : _vm.assessment
                                                    .physicianConsult
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.assessment
                                                      .physicianConsult,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.assessment,
                                                        "physicianConsult",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.assessment,
                                                        "physicianConsult",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.assessment,
                                                    "physicianConsult",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(
                                            "One-time 20-min consult about score?"
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "print-checkbox-row" },
                                  [
                                    _c("div", { staticClass: "frow" }, [
                                      _c(
                                        "label",
                                        { staticClass: "row-start" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.assessment.oneTimeConsult,
                                                expression:
                                                  "assessment.oneTimeConsult"
                                              }
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.assessment.oneTimeConsult
                                              )
                                                ? _vm._i(
                                                    _vm.assessment
                                                      .oneTimeConsult,
                                                    null
                                                  ) > -1
                                                : _vm.assessment.oneTimeConsult
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.assessment
                                                      .oneTimeConsult,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.assessment,
                                                        "oneTimeConsult",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.assessment,
                                                        "oneTimeConsult",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.assessment,
                                                    "oneTimeConsult",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v("Physician Consult?")
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _c("div", { staticClass: "sidebar-group" }, [
                            _c("div", { staticClass: "frow" }, [
                              _c("label", [
                                _vm._v("Notes"),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.assessment.notes,
                                      expression: "assessment.notes"
                                    }
                                  ],
                                  domProps: { value: _vm.assessment.notes },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.assessment,
                                        "notes",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ]
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.selectedResult
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.saving,
                            expression: "!saving"
                          }
                        ],
                        staticClass: "frow justify-around"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "sidebar-button",
                            attrs: { onclick: "this.blur();" },
                            on: {
                              click: function($event) {
                                return _vm.requestReset()
                              }
                            }
                          },
                          [_vm._v("Clear Form")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "sidebar-button",
                            attrs: { onclick: "this.blur();" },
                            on: {
                              click: function($event) {
                                return _vm.saveForm()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.saved ? "Update" : "Save") +
                                " and Print"
                            )
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "sidebar-button text-only",
                            attrs: { onclick: "this.blur();" },
                            on: {
                              click: function($event) {
                                return _vm.printForm()
                              }
                            }
                          },
                          [_vm._v("Only Print")]
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "frow",
                  class: {
                    "height-100": _vm.saving,
                    "centered-column": _vm.saving
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.saving,
                          expression: "saving"
                        }
                      ],
                      staticClass: "frow centered-column"
                    },
                    [
                      _c("div", { staticClass: "please-message" }, [
                        _vm._v("Please wait")
                      ]),
                      _c("div", { staticClass: "saving-message" }, [
                        _vm._v(_vm._s(_vm.savingMessage))
                      ])
                    ]
                  )
                ]
              )
            ],
            2
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "dynamic-3-4 height-100" },
        [
          _c("Printout", {
            attrs: {
              form: _vm.assessment,
              organization: _vm.currentOrganization,
              collection: _vm.collection,
              "selected-plan": _vm.selectedPlan,
              "insurance-plan": _vm.insurancePlan
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }