var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "height-100",
      class: [_vm.$route.name + "-route"],
      attrs: { id: "one-div-to-rule-them-all" }
    },
    [
      _c("alerts"),
      _c(
        "div",
        { staticClass: "main-content height-100" },
        [
          _c("header-bar", {
            staticClass: "input-fixed-to-absolute do-not-print"
          }),
          _c(
            "transition",
            [
              _c("sidebar", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.visible,
                    expression: "visible"
                  }
                ],
                staticClass: "fixed-sidebar height-100 visible-xs visible-sm"
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main-frow frow justify-start nowrap height-100" },
            [
              _c(
                "transition",
                [
                  _c("sidebar", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.visible,
                        expression: "visible"
                      }
                    ],
                    staticClass: "height-100 hidden-xs hidden-sm"
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "main-view height-100 width-100" },
                [
                  _c(
                    "transition",
                    [
                      _c("router-view", {
                        staticClass: "template",
                        class: { "router-view-padding": !_vm.noSidebar }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }