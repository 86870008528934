let build = require('begin-build');
let { context } = require('begin-build/store');
let { create, register } = require('begin-build/router');

let store = context(module, require.context('./store', true, /\.js$/));
let router = create();

module.exports = build({
  store,
  router,
  components: { app: require('./app/vue.pug').default },
});

let { bound: { mapGetters } } = require('./store/auth');

let { isLoggedIn, getRole } = mapGetters(['isLoggedIn', 'getRole']);

register([
  {
    path: '/',
    redirect() {
      if (!isLoggedIn()) {
        return '/log-in';
      }
      switch (getRole()) {
        case 0:
        case 1:
          return '/admin';
        case 2:
          return '/assessor';
        case 3:
        case 4:
        default:
          return '/dashboard';
      }
    },
  }, {
    name: '404',
    path: '/404',
    component: require('./404/vue.pug').default,
    meta: {
      publicAllowed: true,
    },
  }, {
    name: 'log-in',
    path: '/log-in',
    component: require('./log-in/vue.pug').default,
    meta: {
      publicAllowed: true,
    },
  }, {
    name: 'admin',
    path: '/admin',
    component: require('./admin/vue.pug').default,
    meta: {
      noSidebar: true,
    },
  }, {
    name: 'admin-tools',
    path: '/admin-tools',
    component: require('./admin-tools/vue.pug').default,
    meta: {
      noSidebar: true,
    },
  }, {
    name: 'wiki',
    path: '/wiki',
    component: require('./wiki/vue.pug').default,
  }, {
    name: 'password-reset',
    path: '/password-reset',
    component: require('./password-reset/vue.pug').default,
    meta: {
      publicAllowed: true, // should change for admin view in future
    },
  }, {
    name: 'dashboard',
    path: '/dashboard/:id?',
    component: require('./dashboard/vue.pug').default,
  }, {
    name: 'import',
    path: '/import/:id?',
    component: require('./import/vue.pug').default,
  }, {
    name: 'manager',
    path: '/manager/:id?',
    component: require('./manager/vue.pug').default,
  }, {
    name: 'risk-calculator',
    path: '/risk-calculator/:id',
    component: require('./risk-calculator/vue.pug').default,
    meta: {
      noSidebar: true,
    },
  }, {
    name: 'insurance-table',
    path: '/insurance-table/:id?',
    component: require('./insurance-table/vue.pug').default,
  }, {
    name: 'assessor',
    path: '/assessor',
    component: require('./assessor/vue.pug').default,
  }, {
    name: 'developer',
    path: '/developer',
    component: require('./developer/vue.pug').default,
  }, {
    name: 'articles',
    path: '/articles/:id?',
    component: require('./articles/vue.pug').default,
    meta: {
      publicAllowed: true,
    },
  }, {
    name: 'reports',
    path: '/reports/:id?',
    component: require('./reports/vue.pug').default,
  }, {
    path: '*',
    redirect: '/404',
  },
]);
