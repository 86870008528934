import { render, staticRenderFns } from "./template.pug?vue&type=template&id=4a743507&scoped=true&lang=pug&"
import script from "./index.js?vue&type=script&lang=js&"
export * from "./index.js?vue&type=script&lang=js&"
import style0 from "./style.sass?vue&type=style&index=0&id=4a743507&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a743507",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/beaty-dev/wds/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a743507')) {
      api.createRecord('4a743507', component.options)
    } else {
      api.reload('4a743507', component.options)
    }
    module.hot.accept("./template.pug?vue&type=template&id=4a743507&scoped=true&lang=pug&", function () {
      api.rerender('4a743507', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/reports/vue.pug"
export default component.exports