var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-setup-page" }, [
    _c("div", { staticClass: "frow centered" }, [
      _c("div", { staticClass: "card-medium page dark-card-shadow" }, [
        _c("div", { staticClass: "account-card-title" }, [
          _vm._v("Account Setup")
        ]),
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitPassword()
              }
            }
          },
          [
            _c("div", { staticClass: "frow gutters" }, [
              _c("div", { staticClass: "frow col-md-1-1 column-center" }, [
                _c("div", { staticClass: "material-group" }, [
                  _c("span", [_vm._v("Please enter your Password")]),
                  _c("br"),
                  _c("br"),
                  _c("label", [
                    _vm._v("Password"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      attrs: { required: "", type: "password" },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._m(0),
                _c(
                  "button",
                  {
                    staticClass: "account-button",
                    attrs: { type: "submit", disabled: !_vm.password }
                  },
                  [_vm._v("Submit")]
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "invalid-password" }, [
      _c("p", [_vm._v("• Must be at least 8 characters long")]),
      _c("p", [_vm._v("• Must contain at least one number")]),
      _c("p", [_vm._v("• Must contain at least one letter")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }