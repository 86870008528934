let { mapGetters, mapMutations, mapActions } = require('../../store/articles');

module.exports = {
  name: 'tree',

  props: {
    model: Object,
  },

  data: () => ({
    open: false,
  }),

  computed: Object.assign({
    isFolder() {
      return this.model.children && this.model.children.length;
    },
  }, mapGetters(['activeArticle'])),

  methods: Object.assign({
    toggle() {
      if(this.model._id) {
        if (this.isFolder) {
          this.open = !this.open;
        }
        this.setActiveArticle(this.model);
      }
    },

    isActive(id) {
      if (this.activeArticle) {
        return this.activeArticle._id == id;
      } else {
        return false;
      }
    },
  }, mapMutations(['setActiveArticle']), mapActions(['fetchArticles'])),
};
