let assignDeep = require('begin-util/assign-deep');
let calculations = require('../../shared/calculations');
let {
  display,
  sections,
  interventions,
  recommendations,
  vaccinations,
} = require('../../shared/risk-calculator');
let plan = require('../../shared/plan');
let { TABLE } = require('../../shared/plan');

const DEFAULTS = {
  firstName: '',
  lastName: '',
  created: Date.now(),
  sex: 'f',
  dobMonth: 1,
  dobDay: 1,
  dobYear: 1970,
  a1c: 0,
  weight: 150,
  waistCirc: 34,
  heightFT: 5,
  heightIN: 10,
  triglycerides: 100,
  hdl: 60,
  bloodGlucose: 90,
  systolicBP: 120,
  diastolicBP: 80,
  nicotine: false,
  diagnosedWith: {
    heartDisease: false,
    hypertension: false,
    diabetes: false,
    cancer: false,
  },
  medications: {
    cholesterol: false,
    bloodPressure: false,
    diabetes: false,
    asthma: false,
    depression: false,
    betaBlocker: false,
  },
};

const RESOURCES = {
  companyResource: {
    title: 'Our Resources',
  },
  generalResource: {
    title: 'General Health',
    url: 'https://cdc.gov/HealthyLiving',
  },
  tobaccoResource: {
    title: 'Tobacco Quit Help',
    url: 'https://smokefree.gov',
  },
  fitnessResource: {
    title: 'Fitness App',
    url: 'https://myfitnesspal.com',
  },
  coachingResource: {
    title: 'Health Coaching',
    url: 'wellnessdatasolutions.com',
  },
  diabetesResource: {
    title: 'Diabetes Info',
    url: 'http://diabetes.org',
  },
  heartResource: {
    title: 'Heart Health',
    url: 'https://heart.org',
  },
};

const COLUMNS = ['', 0, 1, 2, 3, 4, 5];
const ROWS = ['-1', 0, 1, 2, 3];
const TITLE_ROW = COLUMNS.map(i => ({ title: `${i}` }));

module.exports = {
  components: {
    metsSlider: require('./mets-slider/vue.pug').default,
    wds: require('../app/logo.svg').default,
    warning: require('./warning.svg').default,
    arrow: require('./arrow.svg').default,
  },

  props: {
    form: {
      default: () => ({}),
    },

    organization: {
      default: () => ({}),
    },

    collection: {
      default: () => ({ title: '' }),
    },

    selectedPlan: {
      default: null,
    },

    insurancePlan: {
      default: null,
    },
  },

  data() {
    return {
      assessment: Object.assign({}, DEFAULTS),
      arrowPercentage: '0%',
    };
  },

  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },

    dateToReadable(timestamp) {
      let date = timestamp ? new Date(+timestamp) : new Date();
      let mm = date.getUTCMonth() + 1;
      let dd = date.getUTCDate();
      let yyyy = date.getUTCFullYear();
      if (dd < 10) {
        dd = `0${dd}`;
      }
      if (mm < 10) {
        mm = `0${mm}`;
      }
      return `${mm}/${dd}/${yyyy}`;
    },
  },

  watch: {
    form: {
      handler() {
        this.assessment = Object.assign({}, DEFAULTS, this.form || {});
        if (this.assessment.height) {
          this.assessment.heightFT = ~~(this.assessment.height / 12);
          this.assessment.heightIN = this.assessment.height - this.assessment.heightFT * 12;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    display() {
      return display.call(
        Object.assign(
          {
            // TODO: this is not an accurate age, must use day
            calcAge: ~~(new Date().getUTCFullYear() - this.assessment.dobYear),
            calcDiabetesLabel: this.diabetesScore,
            bmiScore: this.bmiScore,
            metsScore: this.metsScore,
          },
          this.assessment,
        ),
      );
    },

    sections() {
      return sections;
    },

    interventions() {
      return interventions;
    },

    recommendations() {
      return recommendations;
    },

    vaccinations() {
      return vaccinations;
    },

    resources() {
      if (this.organization) {
        let resources = this.organization.resources || {};
        resources = assignDeep(
          {
            companyResource: {
              url: this.organization.url,
            },
          },
          resources,
        );
        return assignDeep(RESOURCES, resources);
      }
      return RESOURCES;
    },

    bmi() {
      // return calculations.calcBmi(100, 60);
      return calculations.calcBmi(this.assessment.weight, this.totalHeight);
    },

    roundBmi() {
      return this.bmi.toFixed(1);
    },

    bmiScore() {
      return calculations.calcBmiScore(this.bmi);
    },

    bmiScoreColor() {
      switch (this.bmiScore) {
        case -1:
          return 'font-color-light-green';
        case 1:
          return 'font-color-gold';
        case 2:
          return 'font-color-orange';
        case 3:
          return 'font-color-red';
        default:
          return 'font-color-green';
      }
    },

    bmiScoreText() {
      switch (this.bmiScore) {
        case -1:
          this.arrowPercentage = '10%';
          return 'Underweight';
        case 0:
          this.arrowPercentage = '30%';
          return 'Healthy Weight';
        case 1:
          this.arrowPercentage = '50%';
          return 'Overweight';
        case 2:
          this.arrowPercentage = '70%';
          return 'Obese';
        case 3:
          this.arrowPercentage = '90%';
          return 'Morbidly Obese';
        default:
          this.arrowPercentage = '0%';
          return 'Undetermined';
      }
    },

    metsScore() {
      return calculations.calcMetsScore(
        +this.waistCircumferenceScore,
        +this.triglyceridesScore,
        +this.hdlScore,
        +this.bloodGlucoseScore,
        +this.bloodPressureScore,
      );
    },

    // metsScoreText() {
    //   return this.metsScore >= 3 ? 'Yes' : 'No';
    // },

    totalHeight() {
      return calculations.getInches(
        ~~this.assessment.heightFT,
        +this.assessment.heightIN,
      );
    },

    weightRanges() {
      return calculations.getWeightRanges(this.totalHeight);
    },

    diabetesScore() {
      return calculations.getDiabetesRanges(
        this.assessment.bloodGlucose,
        this.assessment.a1c,
      );
    },

    diabetesText() {
      switch (this.diabetesScore) {
        case 1:
          return 'Low Risk';
        case 2:
          return 'Medium Risk';
        case 3:
          return 'High Risk';
        case 4:
          return 'Very High Risk';
        default:
          return 'At Risk';
      }
    },

    waistCircumferenceScore() {
      return calculations.calcWaistCircumference(
        this.assessment.sex,
        this.assessment.waistCirc,
      );
    },

    triglyceridesScore() {
      return calculations.calcTriglycerides(this.assessment.triglycerides);
    },

    hdlScore() {
      return calculations.calcHdl(this.assessment.sex, this.assessment.hdl);
    },

    bloodGlucoseScore() {
      return calculations.calcBloodGlucose(this.assessment.bloodGlucose);
    },

    bloodPressureScore() {
      return calculations.calcBloodPressure(
        this.assessment.systolicBP,
        this.assessment.diastolicBP,
      );
    },

    displayAssessmentDate() {
      if (this.assessment.created && typeof this.assessment.created === 'string') {
        return this.assessment.created;
      }
      return this.dateToReadable(this.assessment.created || null);
    },

    insuranceName() {
      if (this.selectedPlan && this.insurancePlan) {
        return this.insurancePlan.name;
      }
      return 'N/A';
    },

    insurancePremium() {
      if (
        this.selectedPlan
        && this.insurancePlan
        && this.bmiScore !== null
        && this.metsScore !== null
      ) {
        return plan
          .prices(this.insurancePlan.table)
          [this.bmiScore][this.metsScore].toFixed(2);
      }
      return 'N/A';
    },

    lipidAndCholesterolText() {
      let output = 'Undetermined';
      if (
        this.assessment.hdl === null
        || this.assessment.triglycerides === null
      ) {
        return output;
      }
      if (
        this.assessment.hdl >= (this.assessment.sex === 'm' ? 40 : 50)
        && this.assessment.triglycerides < 150
      ) {
        output = 'Low Risk';
        return output;
      }
      if (
        this.assessment.hdl <= (this.assessment.sex === 'm' ? 39 : 49)
        || this.assessment.triglycerides < 200
      ) {
        output = 'Medium Risk';
      }
      if (
        this.assessment.hdl <= (this.assessment.sex === 'm' ? 40 : 50)
        || this.assessment.triglycerides >= 200
      ) {
        output = 'High Risk';
      }
      return output;
    },

    hypertensionText() {
      let output = 'Undetermined';
      if (
        this.assessment.systolicBP === null
        || this.assessment.diastolicBP === null
      ) {
        return output;
      }
      if (
        this.assessment.systolicBP < 130
        && this.assessment.diastolicBP < 85
      ) {
        output = 'Low Risk';
        return output;
      }
      if (
        this.assessment.systolicBP < 139
        || this.assessment.diastolicBP < 89
      ) {
        output = 'Medium Risk';
      }
      if (
        this.assessment.systolicBP >= 150
        || this.assessment.diastolicBP >= 90
      ) {
        output = 'High Risk';
      }
      return output;
    },

    scoreTable() {
      let columns = [0, 1, 2, 3, 4, 5];
      return ROWS.reduce(
        (out, title) => out.concat(
          [{ title }].concat(
            columns.map(column => {
              let cell = TABLE[title][column];
              let isScore = this.bmiScore === title && this.metsScore === column;
              let color = 'red-cell';
              if (cell.low) {
                color = 'yellow-cell';
              }
              if (cell.green) {
                color = 'green-cell';
              }

              return { color, isScore };
            }),
          ),
        ),
        TITLE_ROW,
      );
    },
  },
};
