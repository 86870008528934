var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar do-not-print" }, [
    _c("div", { staticClass: "frow justify-start height-100" }, [
      _c("div", { staticClass: "sidebar-card height-100" }, [
        _c(
          "div",
          {
            staticClass: "frow column-center justify-between nowrap height-100"
          },
          [
            _c(
              "nav",
              { staticClass: "sidebar-items" },
              [
                _vm.isLoggedIn
                  ? [
                      _vm.getRole === 0 || _vm.getRole === 1
                        ? [
                            _c("router-link", { attrs: { to: "/admin" } }, [
                              _vm._v("Admin")
                            ])
                          ]
                        : _vm._e(),
                      _vm.getRole === 0 || _vm.getRole === 1
                        ? [
                            _c(
                              "router-link",
                              { attrs: { to: "/admin-tools" } },
                              [_vm._v("Admin Tools")]
                            )
                          ]
                        : _vm._e(),
                      _vm.getRole === 0 || _vm.getRole === 1
                        ? [
                            _c("router-link", { attrs: { to: "/wiki" } }, [
                              _vm._v("Wiki")
                            ])
                          ]
                        : _vm._e(),
                      _vm.getRole === 2
                        ? [
                            _c("router-link", { attrs: { to: "/assessor" } }, [
                              _vm._v("Assessor")
                            ])
                          ]
                        : _vm._e(),
                      _vm.getRole === 3 || _vm.getRole === 4
                        ? [
                            _c("router-link", { attrs: { to: "/dashboard" } }, [
                              _vm._v("Dashboard")
                            ])
                          ]
                        : _vm._e(),
                      _vm.getRole === 3
                        ? [
                            _c("router-link", { attrs: { to: "/manager" } }, [
                              _vm._v("Manager")
                            ])
                          ]
                        : _vm._e(),
                      _vm.getFlags.reports && _vm.getRole === 3
                        ? [
                            _c("router-link", { attrs: { to: "/reports" } }, [
                              _vm._v("Reports")
                            ])
                          ]
                        : _vm._e(),
                      _vm.getRole < 2 || (_vm.getFlags.api && _vm.getRole < 4)
                        ? [
                            _c("router-link", { attrs: { to: "/developer" } }, [
                              _vm._v("Developer")
                            ])
                          ]
                        : _vm._e(),
                      _c("router-link", { attrs: { to: "/articles" } }, [
                        _vm._v("Articles")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "clickable",
                          on: {
                            click: function($event) {
                              return _vm.logout()
                            }
                          }
                        },
                        [_vm._v("Log Out")]
                      )
                    ]
                  : [
                      _c("router-link", { attrs: { to: "/log-in" } }, [
                        _vm._v("Log In")
                      ]),
                      _c("router-link", { attrs: { to: "/articles" } }, [
                        _vm._v("Articles")
                      ]),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "mailto:info@wellnessdata.solutions?Subject=Hello%20WDS"
                          }
                        },
                        [_vm._v("Contact Us")]
                      )
                    ]
              ],
              2
            ),
            _c("div", { staticClass: "sidebar-logo" }, [_c("logo")], 1)
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }