let { mapActions } = require('begin-build/alerts');
let api = require('begin-build/api');
let { mapGetters } = require('../store/auth');
let calculations = require('../../shared/calculations');
let assessmentFields = require('../../shared/assessment-fields');
let personFields = require('../../shared/person-fields');

let states = require('../../shared/us');

module.exports = {
  components: {
    Printout: require('../risk-printout/vue.pug').default,
  },

  data: () => ({
    id: undefined,
    currentOrganization: {
      name: '',
    },
    collection: { title: '' },
    searchResults: null,
    selectedResult: false,
    insurancePlans: null,
    selectedPlan: null,
    insurancePlan: null,
    personId: null,
    ethnicities: [
      'White',
      'Black or African American',
      'American Indian',
      'Alaska Native',
      'Asian',
      'Hispanic/Latino',
      'Native Hawaiian/Other Pacific Islander',
    ],
    assessment: {
      firstName: '',
      lastName: '',
      // TODO: Add validation on email and phone fields
      email: null,
      dateOfBirth: null,
      phone: null,
      created: null,
      ethnicity: null,
      sex: null,
      dobMonth: null,
      dobDay: null,
      dobYear: null,
      spouse: false,
      department: null,
      bmi: null,
      a1c: null,
      weight: null,
      waistCirc: null,
      triglycerides: null,
      hdl: null,
      bloodGlucose: null,
      systolicBP: 0,
      diastolicBP: 0,
      nicotine: null,
      familyHistoryDiabetes: null,
      totalCholesterol: null,
      ldlCholesterol: null,
      diagnosedWith: {
        heartDisease: null,
        hypertension: null,
        diabetes: null,
        cancer: null,
      },
      medications: {
        cholesterol: null,
        bloodPressure: null,
        diabetes: null,
        asthma: null,
        depression: null,
        betaBlocker: null,
      },
      recentCheckups: {
        dental: null,
        mammogram: null,
        cervicalCancer: null,
        colorectalCancer: null,
        fluVaccination: null,
      },
      participation: null,
      readinessDiet: null,
      readinessPhysical: null,
      healthCoaching: null,
      physicianConsult: null,
      oneTimeConsult: null,
      notes: null,
      // not important variables
      heightFT: 0,
      heightIN: 0,
    },
    bloodWork: false,
    address: null,
    city: null,
    state: null,
    zip: null,
    saved: false,
    saving: false,
    savingMessage: '',
    states,
  }),

  methods: Object.assign(
    {
      async init() {
        try {
          let {
            data: { collection, organization, departments },
          } = await api.get(`collection/${this.$route.params.id}`);
          this.collection = collection;
          this.currentOrganization = organization;
          this.currentOrganization.departments = departments;
        } catch (e) {
          this.$router.push('/');
          this.error(e);
          this.error('Did you provide a valid Collection ID?');
        }
        this.getPlans();
      },

      async getPlans() {
        let { data } = await api.get(`plan/${this.currentOrganization._id}`);
        this.insurancePlans = data;
      },

      resetForm() {
        this.dismissAll();
        Object.assign(this.$data, this.$options.data.apply(this));
        this.init();
      },

      requestReset() {
        /* eslint-disable no-restricted-globals */
        if (
          this.saved
          || confirm(
            'Warning: This profile has not been saved, clicking OK will delete it.',
          )
        ) {
          this.resetForm();
        }
        /* eslint-enable */
      },

      printForm() {
        if (
          confirm(`You\'ve selected Print Only, this user will not be ${this.saved ? 'updated' : 'saved'}.`)
        ) {
          window.print();
        }
      },

      async saveForm() {
        this.dismissAll();
        this.assessment.created = Date.now();
        if (this.bloodWork === true) {
          if (!this.address || this.address.length < 3) {
            this.error('Enter a valid address');
            return;
          }
          if (!this.city || this.city.length < 3) {
            this.error('Enter a valid city');
            return;
          }
          if (!this.state || this.state.length < 2) {
            this.error('Enter a valid state');
            return;
          }
          if (!this.zip || this.zip.length < 5) {
            this.error('Enter a valid zip code');
            return;
          }
          if (!this.assessment.email || this.assessment.email.length < 2) {
            this.error('Enter a valid email address');
            return;
          }
          if (!this.assessment.phone || this.assessment.phone.length < 10) {
            this.error('Enter a valid phone number');
            return;
          }
        }
        this.savingMessage = 'Saving...';
        this.saving = true;
        try {
          let form = Object.keys(personFields).reduce((data, key) => {
            data[key] = this.assessment[key];
            return data;
          }, {});
          form.organization = this.currentOrganization._id;
          form.dateOfBirth = `${this.assessment.dobMonth}/${
            this.assessment.dobDay
          }/${this.assessment.dobYear}`;
          if (this.personId) {
            form._id = this.personId;
          }
          let {
            data: { _id },
          } = await api.post('person', form);
          if (!this.personId) {
            this.personId = _id;
          }
        } catch (e) {
          this.error(e);
        }
        let form = Object.keys(assessmentFields).reduce(
          (data, key) => {
            data[key] = this.assessment[key];
            return data;
          },
          {
            collection: this.collection._id,
            person: this.personId,
          },
        );
        form.height = calculations.getInches(
          ~~this.assessment.heightFT,
          +this.assessment.heightIN,
        );
        if (this.insurancePlan) {
          form.plan = this.insurancePlan._id;
        }
        try {
          if (!this.saved) {
            let {
              data: { _id },
            } = await api.post('assessment', form);
            this.id = _id;
            this.success('Assessment saved.');
          } else {
            form._id = this.id;
            await api.put('assessment', form);
            this.success('Assessment updated.');
          }
          this.saved = true;
          this.saving = false;
        } catch (e) {
          this.saving = false;
          this.stickyError(
            'Failed updating assessment. Please try again.',
            true,
          );
        }
        window.print();
        if (this.bloodWork === true) {
          this.savingMessage = 'Sending blood work order...';
          this.saving = true;
          try {
            let phone = this.assessment.phone.replace(/[^\d]/g, '');
            let { length } = phone;
            let { data } = await api.post(
              `integration/personalabs/${this.id}`,
              {
                email_address: this.assessment.email,
                first_name: this.assessment.firstName,
                last_name: this.assessment.lastName,
                sex: this.assessment.sex,
                dob: `${this.assessment.dobMonth}/${this.assessment.dobDay}/${
                  this.assessment.dobYear
                }`,
                phone: `${phone.substring(
                  length - 10,
                  length - 7,
                )}-${phone.substring(length - 7, length - 4)}-${phone.substring(
                  length - 4,
                )}`,
                address: this.address,
                city: this.city,
                state: this.state,
                zip: this.zip,
              },
            );
            this.bloodWork = data;
          } catch (e) {
            this.error(e);
          }
          this.saving = false;
        }
      },

      async searchForPerson() {
        if (this.assessment.lastName && this.assessment.lastName.length > 1) {
          let { data } = await api.get(
            `person/${this.currentOrganization._id}/${this.assessment.lastName}`,
          );
          this.searchResults = data;
        } else {
          this.searchResults = null;
        }
      },

      async selectResult(person) {
        let result;
        let personId = person._id;
        try {
          let { data } = await api.get(
            `assessment/${this.$route.params.id}/${personId}`,
          );
          result = data;
          result.id = result._id;
          result.heightFT = ~~(result.height / 12);
          result.heightIN = result.height - result.heightFT * 12;
          this.saved = true;
          if (result.plan) {
            this.insurancePlan = this.insurancePlans.find(
              x => x._id === result.plan,
            );
            this.selectedPlan = this.insurancePlan._id;
          }
        } catch (e) {
          result = {};
        }
        Object.assign(person, this.splitDate(person.dateOfBirth));
        delete person._id;
        delete result.collection;
        this.personId = personId;
        this.id = result._id;
        Object.assign(this.assessment, person, result);
        this.selectedResult = true;
      },

      dateToReadable(timestamp) {
        let date = timestamp ? new Date(+timestamp) : new Date();
        let mm = date.getUTCMonth() + 1;
        let dd = date.getUTCDate();
        let yyyy = date.getUTCFullYear();
        if (dd < 10) {
          dd = `0${dd}`;
        }
        if (mm < 10) {
          mm = `0${mm}`;
        }
        return `${mm}/${dd}/${yyyy}`;
      },

      splitDate(timestamp) {
        let date = new Date(timestamp);
        return {
          dobMonth: date.getUTCMonth() + 1,
          dobDay: date.getUTCDate(),
          dobYear: date.getUTCFullYear(),
        };
      },

      setPlan() {
        this.insurancePlan = this.insurancePlans.find(
          x => x._id === this.selectedPlan,
        );
      },
    },
    mapActions(['error', 'success', 'stickyError', 'dismissAll']),
  ),

  computed: mapGetters(['getRole']),

  async created() {
    if (this.getRole === 0 || this.getRole === 1 || this.getRole === 2) {
      this.init();
    } else {
      this.$router.push('/');
    }
  },
};
