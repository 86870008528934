'use strict';

/* eslint-disable max-len */
const TABLE = {
  '-1': [{ low: 1.15 }, { low: 1.45 }, { low: 2 }, { high: 0.7 }, { high: 0.8 }, { high: 0.9 }],
  0: [{ healthy: true, low: 1, green: true }, { low: 1.25, green: true }, { low: 1.87 }, { high: 0.65 }, { high: 0.75 }, { high: 0.85 }],
  1: [{ low: 1.25, green: true }, { low: 1.5 }, { low: 2 }, { high: 0.7 }, { high: 0.8 }, { high: 0.9 }],
  2: [{ low: 1.5 }, { low: 1.75 }, { high: 0.65 }, { high: 0.75 }, { high: 0.85 }, { high: 0.95 }],
  3: [{ high: 0.5 }, { high: 0.6 }, { high: 0.7 }, { high: 0.8 }, { high: 0.9 }, { unhealthy: true, high: 1 }],
};
/* eslint-enable max-len */

module.exports = {
  hriToIndex(bmi, mets) {
    return ((~~bmi + 1) * 6) + ~~mets;
  },

  calculateCell(cell, overrides) {
    return cell.low ? (+overrides[6] * cell.low) : (+overrides[29] * cell.high);
  },

  prices(overrides = { 6: 0, 29: 0 }) {
    return Object.entries(TABLE).reduce((out, [bmi, row]) => {
      out[bmi] = row.map((cell, mets) =>
        overrides[this.hriToIndex(bmi, mets)] || this.calculateCell(cell, overrides));
      return out;
    }, {});
  },

  TABLE,
};
