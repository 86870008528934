const { waistCirc } = require('../../shared/assessment-fields');
let calculations = require('../../shared/calculations');
module.exports = {
  data: () => ({
    advancedRisk: {
      bloodGlucose: 1,
      a1c: 1,
      triglycerides: 1,
      hdl: 1,
      waistCirc: 1,
      systolicBP: 1,
      diastolicBP: 1,
      height: 1,
      weight: 1,
      bmi: 1,
      sex: 'm',
      age: 1,
      nicotine: false,
      medications:{
        cholesterol: false,
        bloodPressure: false,
        diabetes: false,
      },
    }
  }),
  computed: {
    glucoseA1cScore() {
      return calculations.calcAdvBloodSugar(this.advancedRisk.glucose, this.advancedRisk.a1c, this.advancedRisk.medications);
    },
    triglyceridesScore() {
      return calculations.calcAdvTriglycerides(this.advancedRisk.tg);
    },
    hdlScore() {
      return calculations.calcAdvHDL(this.advancedRisk.hdl, this.advancedRisk.sex, this.advancedRisk.medications);
    },
    waistCircScore() {
      return calculations.calcAdvWaistCirc(this.advancedRisk.wc, this.advancedRisk.height);
    },
    bloodPressureScore() {
      return calculations.calcAdvBloodPressure(this.advancedRisk.sbp, this.advancedRisk.dbp, this.advancedRisk.medications);
    },
    bmiScore() {
      return calculations.calcAdvBMI(this.advancedRisk.bmi);
    },
    ageScore() {
      return calculations.calcAdvAge(this.advancedRisk.age);
    }
  },
  methods: {
    calculateScore() {
      let advancedRisk = this.advancedRisk;
      let score = calculations.calcAdvancedRiskScore(advancedRisk)
      this.$set(this.advancedRisk, 'score', score)
    }
  }
};
