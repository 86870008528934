var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        on: {
          click: function($event) {
            return _vm.toggle()
          }
        }
      },
      [
        _c("a", {
          class: { active: _vm.isActive(_vm.model._id), folder: _vm.isFolder }
        }),
        _vm._v(_vm._s(_vm.model.title)),
        _vm.isFolder
          ? _c("div", {
              staticClass: "dropdown-triangle",
              class: { open: _vm.open || !_vm.model._id }
            })
          : _vm._e()
      ]
    ),
    _vm.isFolder
      ? _c(
          "nav",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.open || !_vm.model._id,
                expression: "open || !model._id"
              }
            ]
          },
          _vm._l(_vm.model.children, function(model) {
            return _c("tree", { key: model._id, attrs: { model: model } })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }