let marked = require('marked');
let articleHelper = require('../../shared/articles');
let { mapGetters, mapActions, mapMutations } = require('../store/articles');

module.exports = {
  components: {
    tree: require('../wiki/tree/vue.pug').default,
  },

  data: () => ({
    articleContainer: {
      title: 'Articles',
      text: '',
      children: [],
    },
    treeVisible: true,
  }),

  computed: Object.assign({
    compiledMarkdown() {
      return marked(this.activeArticle.text, { sanitize: true });
    },
  }, mapGetters(['articles', 'activeArticle'])),

  watch: {
    activeArticle: function () {
      this.treeVisible = false;
      this.setUrl(this.activeArticle._id);
    },
  },

  methods: Object.assign({
    setUrl(id) {
      this.$router.push({ name: 'articles', params: { id } });
    },
  }, mapActions(['fetchArticles']), mapMutations(['setActiveArticle'])),

  async created() {
    await this.fetchArticles();
    this.articleContainer.children = articleHelper.parseArticles(this.articles);
    if (this.$route.params.id) {
      this.setActiveArticle(this.articles.find(a => a._id === this.$route.params.id));
      this.treeVisible = false;
    }
  },
};
