let Vue = require('vue');
let Chartist = require('chartist');

const RESPONSIVE_LINE_OPTIONS = {
  axisY: {
    onlyInteger: true,
  },
};
const RESPONSIVE_OPTIONS = [
  ['screen and (max-width: 767px)', {
    chartPadding: 30,
    labelOffset: 60,
    labelDirection: 'explode',
    labelInterpolationFnc: value => value,
  }],
  ['screen and (min-width: 768px) and (max-width: 991px)', {
    chartPadding: 40,
    labelOffset: 60,
    labelDirection: 'explode',
  }],
  ['screen and (min-width: 992px) and (max-width: 1199px)', {
    chartPadding: 40,
    labelOffset: 60,
    labelDirection: 'explode',
  }],
  ['screen and (min-width: 1200px) and (max-width: 1601px)', {
    chartPadding: 30,
    labelOffset: 60,
    labelDirection: 'explode',
  }],
  ['screen and (min-width: 1602px) and (max-width: 2000px)', {
    chartPadding: 50,
    labelOffset: 60,
    labelDirection: 'explode',
  }],
];
const HEALTH_PIE_STYLE = {
  chartPadding: 30,
  labelDirection: 'explode',
};

module.exports = {
  props: ['graphData', 'currentCollection', 'graphType', 'title'],
  methods: {
    setCurrentCollection() {
      let index = this.currentCollection;
      if (this.graphData[index - 2]) {
        index = index - 2;
      } else if (this.graphData[index - 1]) {
        index = index - 1;
      }
      this.activeData.series = Object.values(this.graphData[index][this.graphType]);
      let selection = this.graphData.slice(index, index + 5);
      this.historyChart.labels = [];
      this.historyChart.series = selection.reduce((totals, aggregateData) => {
        this.historyChart.labels.unshift(new Date(aggregateData.created).getUTCFullYear());
        totals[0].unshift(aggregateData[this.graphType].green / aggregateData.count * 100);
        totals[1].unshift(aggregateData[this.graphType].yellow / aggregateData.count * 100);
        totals[2].unshift(aggregateData[this.graphType].red / aggregateData.count * 100);
        totals[3].unshift(aggregateData[this.graphType].darkred / aggregateData.count * 100);
        return totals;
      }, [[], [], [], [], []]);
      if (this.charts) {
        this.charts.forEach(chart => {
          chart.update();
        });
      } else {
        this.charts = [
          new Chartist.Pie(`#${this.graphType}-distribution-chart`, this.activeData, HEALTH_PIE_STYLE, RESPONSIVE_OPTIONS),
          new Chartist.Line(`#${this.graphType}-history-chart`, this.historyChart, RESPONSIVE_LINE_OPTIONS),
        ];
      }
    },
  },
  created() {
    this.activeData = {};
    this.historyChart = {};
    this.$parent.$on('updateChart', () => Vue.nextTick(this.setCurrentCollection));
  },
};