var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "frow" }, [
    _c(
      "div",
      { staticClass: "import-page" },
      [
        _vm._m(0),
        _c("form", { ref: "importForm", staticClass: "frow" }, [
          _c("input", {
            ref: "import",
            staticClass: "col-md-1-1",
            attrs: { type: "file", accept: ".csv, text/csv" },
            on: { change: _vm.handleFiles }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.headers,
                  expression: "!headers"
                }
              ],
              staticClass: "col-md-1-1"
            },
            [_vm._v("comma-seperated values (.csv) files only.")]
          )
        ]),
        _vm.headers && !_vm.uploading
          ? [
              _c("h2", { staticClass: "import-h2" }, [_vm._v("Match Fields")]),
              _c(
                "div",
                { staticClass: "frow gutters row-start" },
                _vm._l(_vm.fields, function(ref, index) {
                  var key = ref[0]
                  var field = ref[1]
                  return _c("div", { staticClass: "col-md-1-2" }, [
                    _c("label", [
                      _vm._v(
                        _vm._s(field.text) +
                          _vm._s(
                            field.description
                              ? " (" + field.description + ")"
                              : ""
                          )
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mapping[index],
                              expression: "mapping[index]"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.mapping,
                                index,
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("-- N/A --")
                          ]),
                          _vm._l(_vm.headers, function(header, selection) {
                            return _c(
                              "option",
                              { domProps: { value: selection } },
                              [
                                _vm._v(
                                  _vm._s(
                                    header ||
                                      "UNNAMED (column #" +
                                        (selection + 1) +
                                        ")"
                                  )
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ])
                }),
                0
              ),
              _vm.mapping
                ? _c("h2", { staticClass: "import-h2" }, [
                    _vm._v("Collection Details")
                  ])
                : _vm._e(),
              _c("div", { staticClass: "frow gutters" }, [
                _c("div", { staticClass: "col-sm-1-2" }, [
                  _c("label", [
                    _vm._v("Month"),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.month,
                            expression: "month"
                          }
                        ],
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.month = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.setTitlePlaceholder()
                            }
                          ]
                        }
                      },
                      _vm._l(_vm.months, function(key) {
                        return _c("option", { domProps: { value: key } }, [
                          _vm._v(_vm._s(key))
                        ])
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-sm-1-2" }, [
                  _c("label", [
                    _vm._v("Year"),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.year,
                            expression: "year"
                          }
                        ],
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.year = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.setTitlePlaceholder()
                            }
                          ]
                        }
                      },
                      _vm._l(_vm.years, function(key) {
                        return _c("option", { domProps: { value: key } }, [
                          _vm._v(_vm._s(key))
                        ])
                      }),
                      0
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-md-1-2" }, [
                  _c("label", [
                    _vm._v("Title"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.titlePlaceholder
                      },
                      domProps: { value: _vm.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.title = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _c("div", { staticClass: "col-md-1-2" }, [
                  _c("label", [
                    _vm._v("Location (optional)"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.location,
                          expression: "location"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.location },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.location = $event.target.value
                        }
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "frow" }, [
                _c(
                  "button",
                  {
                    staticClass: "import-button",
                    on: {
                      click: function($event) {
                        return _vm.upload()
                      }
                    }
                  },
                  [_vm._v("Upload")]
                )
              ])
            ]
          : _vm.progress && !_vm.uploading
          ? _c("div", { staticClass: "frow" }, [
              _vm._v("Loading (" + _vm._s(_vm.progress * 100) + "%)...")
            ])
          : _vm.uploading
          ? _c("div", { staticClass: "frow" }, [_vm._v("Uploading...")])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "frow row-center" }, [
      _c("h1", [_vm._v("Import Data")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }