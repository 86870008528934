var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "frow centered-column" }, [
    _c("div", { staticClass: "log-in-logo" }, [_c("logo")], 1),
    _c("div", { staticClass: "page dark-card-shadow" }, [
      _c("div", { staticClass: "log-in-card" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submit()
              }
            }
          },
          [
            !_vm.create
              ? _c("div", { staticClass: "frow column-start" }, [
                  _c("label", [
                    _vm._v("Email"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        }
                      ],
                      attrs: {
                        type: "text",
                        maxlength: "254",
                        required: "",
                        name: "email"
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    })
                  ]),
                  _c("label", [
                    _vm._v("Password"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.key,
                          expression: "key"
                        }
                      ],
                      staticClass: "sign-up-password",
                      attrs: {
                        type: "password",
                        required: "",
                        name: "password"
                      },
                      domProps: { value: _vm.key },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.key = $event.target.value
                        }
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _vm.create
              ? _c("div", { staticClass: "frow gutters" }, [
                  _c("div", { staticClass: "col-md-1-2" }, [
                    _c("label", [
                      _vm._v("First Name"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.firstName,
                            expression: "firstName"
                          }
                        ],
                        attrs: {
                          type: "text",
                          maxlength: "254",
                          required: "",
                          name: "firstName"
                        },
                        domProps: { value: _vm.firstName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.firstName = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-1-2" }, [
                    _c("label", [
                      _vm._v("Last Name"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.lastName,
                            expression: "lastName"
                          }
                        ],
                        attrs: {
                          type: "text",
                          maxlength: "254",
                          required: "",
                          name: "lastName"
                        },
                        domProps: { value: _vm.lastName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.lastName = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-1-2" }, [
                    _c("label", [
                      _vm._v("Email"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email"
                          }
                        ],
                        attrs: {
                          type: "text",
                          maxlength: "254",
                          required: "",
                          name: "email"
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-1-2" }, [
                    _c("label", [
                      _vm._v("Password"),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.key,
                            expression: "key"
                          }
                        ],
                        staticClass: "sign-up-password",
                        attrs: {
                          type: "password",
                          required: "",
                          name: "password"
                        },
                        domProps: { value: _vm.key },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.key = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "frow column-center" }, [
              _c(
                "button",
                {
                  staticClass: "log-in-button",
                  attrs: {
                    type: "submit",
                    disabled:
                      (_vm.create && (!_vm.firstName || !_vm.lastName)) ||
                      !_vm.email ||
                      !_vm.key
                  }
                },
                [_vm._v("Go")]
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }