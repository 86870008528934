let { TABLE, hriToIndex } = require('./plan');

const GREEN = 'g';
const YELLOW = 'y';
const RED = 'r';
const CELLS = Object.values(TABLE).reduce((out, arr) => out.concat(arr), []).map(cell => {
  if (cell.green) {
    return GREEN;
  }
  if (cell.low !== undefined) {
    return YELLOW;
  }
  return RED;
});

module.exports = {
  fromRecord({ bmiScore = 0, metsScore = 0, diagnosedWith = {}, medications = {} }) {
    let color = CELLS[hriToIndex(bmiScore, metsScore)];
    let hasFactor = Object.values(diagnosedWith).concat(Object.values(medications)).some(a => a);
    if (color === GREEN) {
      if (hasFactor) {
        return 2;
      }
      return 1;
    }
    if (color === YELLOW) {
      if (hasFactor) {
        return 4;
      }
      return 3;
    }
    if (hasFactor) {
      return 6;
    }
    return 5;
  },
};
