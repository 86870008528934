var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.record
    ? _c(
        "div",
        { staticClass: "report clickable", class: { expanded: _vm.expanded } },
        [
          _c(
            "div",
            {
              staticClass: "cells user-card shadow-light",
              on: {
                click: function($event) {
                  _vm.expanded = !_vm.expanded
                }
              }
            },
            [
              _c("div", { staticClass: "frow centered" }, [
                _c("div", { staticClass: "col-xs-1-12 text-center" }, [
                  _vm._v(_vm._s(_vm.index + 1))
                ]),
                _c("div", { staticClass: "col-xs-2-12" }, [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.record.lastName + ", " + _vm.record.firstName)
                    )
                  ]),
                  _c("div", { staticClass: "birth-date" }, [
                    _vm._v(_vm._s(_vm.dob))
                  ])
                ]),
                _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                  _c("div", { staticClass: "wds-score" }, [
                    _vm._v(
                      _vm._s(
                        (Number.isInteger(_vm.record.bmiScore)
                          ? _vm.record.bmiScore
                          : "?") +
                          " / " +
                          (Number.isInteger(_vm.record.metsScore)
                            ? _vm.record.metsScore
                            : "?")
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                  _vm._v(_vm._s(_vm.record.priority))
                ]),
                _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                  _vm._v(_vm._s(_vm.record.risk))
                ]),
                _c(
                  "div",
                  { staticClass: "col-xs-1-12 text-center text-uppercase" },
                  [_vm._v(_vm._s(_vm.record.sex))]
                ),
                _vm.variable === null
                  ? _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                      _vm._v("N/A")
                    ])
                  : _vm.variable === "diagnosedWith"
                  ? _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                      _vm.record.diagnosedWith.heartDisease
                        ? _c("div", [_vm._v("Heart Disease")])
                        : _vm._e(),
                      _vm.record.diagnosedWith.hypertension
                        ? _c("div", [_vm._v("Hypertension")])
                        : _vm._e(),
                      _vm.record.diagnosedWith.diabetes
                        ? _c("div", [_vm._v("Diabetes")])
                        : _vm._e(),
                      _vm.record.diagnosedWith.cancer
                        ? _c("div", [_vm._v("Cancer")])
                        : _vm._e()
                    ])
                  : _vm.variable === "medications"
                  ? _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                      _vm.record.medications.cholesterol
                        ? _c("div", [_vm._v("Cholesterol")])
                        : _vm._e(),
                      _vm.record.medications.bloodPressure
                        ? _c("div", [_vm._v("Blood Pressure")])
                        : _vm._e(),
                      _vm.record.medications.diabetes
                        ? _c("div", [_vm._v("Diabetes")])
                        : _vm._e()
                    ])
                  : _vm.variable === "systolicBP"
                  ? _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                      _c("div", [
                        _vm._v(_vm._s(_vm.record.systolicBP) + " (Systolic)")
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.record.diastolicBP) + " (Diastolic)")
                      ])
                    ])
                  : _vm.variable === "bmi"
                  ? _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                      _vm._v(_vm._s(_vm.record.filteredVariable) + " (BMI)")
                    ])
                  : _c("div", { staticClass: "col-xs-2-12 text-center" }, [
                      _vm._v(_vm._s(_vm.record.filteredVariable))
                    ])
              ])
            ]
          ),
          _c("transition", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expanded,
                    expression: "expanded"
                  }
                ],
                staticClass: "additional-info shadow-light"
              },
              [
                _c("h4", [_vm._v("Additional Info")]),
                _c("div", { staticClass: "frow justify-start gutters" }, [
                  _c("div", { staticClass: "col-xs-1-2 col-md-1-5" }, [
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Email:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.email))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Phone:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.phone))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Etnicity:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.ethnicity))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Height:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(
                          _vm._s(
                            ~~(_vm.record.height / 12) +
                              "' " +
                              (_vm.record.height -
                                ~~(_vm.record.height / 12) * 12) +
                              '"'
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Weight:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.weight + " lbs"))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("BMI:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.bmi))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Waist Circ.:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.waistCirc + '"'))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-1-2 col-md-1-5" }, [
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Systolic BP:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.systolicBP))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Diastolic BP:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.diastolicBP))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Blood Glucose:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.bloodGlucose))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("A1C:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.a1c))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Triglycerides:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.triglycerides))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("HDL:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.hdl))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-1-2 col-md-1-5" }, [
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Participation:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.participation))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Diet Readiness:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.readinessDiet))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Activity Readiness:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.readinessPhysical))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Health Coaching:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.healthCoaching))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-1-2 col-md-1-5" }, [
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Nicotine:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.nicotine))
                      ])
                    ]),
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v("Diagnosed With:")
                    ]),
                    _vm.record.diagnosedWith
                      ? _c("ul", { staticClass: "info" }, [
                          _vm.record.diagnosedWith.heartDisease
                            ? _c("li", [_vm._v("Heart Disease")])
                            : _vm._e(),
                          _vm.record.diagnosedWith.hypertension
                            ? _c("li", [_vm._v("Hypertension")])
                            : _vm._e(),
                          _vm.record.diagnosedWith.diabetes
                            ? _c("li", [_vm._v("Diabetes")])
                            : _vm._e(),
                          _vm.record.diagnosedWith.cancer
                            ? _c("li", [_vm._v("Cancer")])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v("Medications:")
                    ]),
                    _vm.record.medications
                      ? _c("ul", { staticClass: "info" }, [
                          _vm.record.medications.cholesterol
                            ? _c("li", [_vm._v("Cholesterol")])
                            : _vm._e(),
                          _vm.record.medications.bloodPressure
                            ? _c("li", [_vm._v("Blood Pressure")])
                            : _vm._e(),
                          _vm.record.medications.diabetes
                            ? _c("li", [_vm._v("Diabetes")])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "info-title" }, [
                      _vm._v("Recent Checkups:")
                    ]),
                    _vm.record.recentCheckups
                      ? _c("ul", { staticClass: "info" }, [
                          _vm.record.recentCheckups.dental
                            ? _c("li", [_vm._v("Dental")])
                            : _vm._e(),
                          _vm.record.recentCheckups.mammogram
                            ? _c("li", [_vm._v("Mammogram")])
                            : _vm._e(),
                          _vm.record.recentCheckups.cervicalCancer
                            ? _c("li", [_vm._v("Cervical Cancer")])
                            : _vm._e(),
                          _vm.record.recentCheckups.colorectalCancer
                            ? _c("li", [_vm._v("Colorectal Cancer")])
                            : _vm._e(),
                          _vm.record.recentCheckups.fluVaccination
                            ? _c("li", [_vm._v("Flu Vaccination")])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "col-xs-1-2 col-md-1-5" }, [
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Department:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.department))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Plan:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.plan))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Premium:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.premium))
                      ])
                    ]),
                    _c("div", { staticClass: "frow justify-start" }, [
                      _c("div", { staticClass: "info-title" }, [
                        _vm._v("Spouse:")
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _vm._v(_vm._s(_vm.record.spouse))
                      ])
                    ])
                  ])
                ])
              ]
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }