let { mapGetters, mapActions } = require('../../store/auth');

module.exports = {
  components: {
    logo: require('../../app/logo.svg').default,
  },

  computed: Object.assign({}, mapGetters(['isLoggedIn', 'getRole', 'getFlags'])),

  methods: Object.assign({
    logOut() {
      this.$router.push('log-in');
    },
  }, mapActions(['logout'])),
};
