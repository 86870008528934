var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "print-form height-100 width-100" }, [
    _c("div", { staticClass: "print-section" }, [
      _c("div", { staticClass: "frow justify-between" }, [
        _c("div", { staticClass: "print-name" }, [
          _vm._v(
            _vm._s(_vm.assessment.firstName + " " + _vm.assessment.lastName)
          )
        ]),
        _c("div", { staticClass: "print-date" }, [
          _vm._v(_vm._s(_vm.displayAssessmentDate))
        ])
      ]),
      _c("div", { staticClass: "print-basic-info" }, [
        _c("div", { staticClass: "print-section-title" }, [
          _vm._v("Summary Of Your Results:")
        ]),
        _c("div", { staticClass: "frow" }, [
          _c("div", { staticClass: "col-xs-1-2" }, [
            _c("div", { staticClass: "print-basic-info-column" }, [
              _c("div", { staticClass: "frow justify-between" }, [
                _c("div", [_vm._v("Your Health Risk Index")]),
                _c("div", [
                  _vm._v(_vm._s(_vm.bmiScore + " / " + _vm.metsScore))
                ])
              ]),
              _vm.selectedPlan && _vm.insurancePlan
                ? _c("div", { staticClass: "frow justify-between" }, [
                    _c("div", [_vm._v("Your Insurance Premium")]),
                    _c("div", [_vm._v("$" + _vm._s(_vm.insurancePremium))])
                  ])
                : _vm._e(),
              _vm.selectedPlan && _vm.insurancePlan
                ? _c("div", { staticClass: "frow justify-between" }, [
                    _c("div", [_vm._v("Your Plan")]),
                    _c("div", [_vm._v(_vm._s(_vm.insuranceName))])
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "col-xs-1-2" }, [
            _c("div", { staticClass: "print-basic-info-column" }, [
              _c("div", { staticClass: "frow justify-between" }, [
                _c("div", [_vm._v("BMI Category")]),
                _c("div", [_vm._v(_vm._s(_vm.bmiScoreText))])
              ]),
              _c("div", { staticClass: "frow justify-between" }, [
                _c("div", [_vm._v("Metabolic Syndrome")]),
                _c("div", [_vm._v(_vm._s(this.metsScore >= 3 ? "Yes" : "No"))])
              ]),
              _c("div", { staticClass: "frow justify-between" }, [
                _c("div", [_vm._v("Diabetes")]),
                _c("div", [
                  _vm._v(
                    _vm._s(
                      _vm.assessment.diagnosedWith.diabetes
                        ? "Diabetes"
                        : _vm.diabetesText
                    )
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "print-sentence" }, [
        _c("strong", [
          _vm._v(
            "The Health Risk Index is a 2-number score comprised of two variables: Body Mass Index and Metabolic Syndrome."
          )
        ]),
        _c("div", { staticClass: "frow" }, [
          _vm._m(0),
          _c("div", { staticClass: "col-xs-1-2" }, [
            _c("div", { staticClass: "chart-box" }, [
              _c(
                "div",
                { staticClass: "frow" },
                _vm._l(_vm.scoreTable, function(cell) {
                  return _c("div", { staticClass: "col-xs-1-7" }, [
                    cell.title !== undefined
                      ? _c("div", { staticClass: "cell title" }, [
                          _c("div", { staticClass: "frow centered" }, [
                            _vm._v(_vm._s(cell.title))
                          ])
                        ])
                      : _c("div", { staticClass: "cell", class: cell.color }, [
                          cell.isScore
                            ? _c("span", { staticClass: "cell-score" }, [
                                _vm._v(
                                  _vm._s(_vm.bmiScore + " / " + _vm.metsScore)
                                )
                              ])
                            : _c("span", [_vm._v(" ")])
                        ])
                  ])
                }),
                0
              )
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "print-section" }, [
      _c("div", { staticClass: "frow width-100" }, [
        _c("div", { staticClass: "col-xs-3-4" }, [
          _c("div", { staticClass: "print-section-title" }, [
            _vm._v("Your Body Mass Index (BMI)")
          ]),
          _c(
            "div",
            { staticClass: "print-sentence", class: _vm.bmiScoreColor },
            [
              _vm._v(
                "Your BMI of " +
                  _vm._s(_vm.roundBmi) +
                  ' places you in the "' +
                  _vm._s(_vm.bmiScoreText) +
                  '" category.'
              )
            ]
          ),
          _vm.bmiScore === -1
            ? _c(
                "div",
                { staticClass: "print-sentence", class: _vm.bmiScoreColor },
                [
                  _vm._v(
                    'To reach the "Healthy Weight" category, you need to weigh ' +
                      _vm._s(_vm.weightRanges.healthyWeight.low) +
                      " pounds."
                  )
                ]
              )
            : _vm._e(),
          _vm.bmiScore === 0
            ? _c(
                "div",
                { staticClass: "print-sentence", class: _vm.bmiScoreColor },
                [
                  _vm._v(
                    'Congratulations! Your weight places you in the "Healthy Weight" category.'
                  )
                ]
              )
            : _vm._e(),
          _vm.bmiScore === 1
            ? _c(
                "div",
                { staticClass: "print-sentence", class: _vm.bmiScoreColor },
                [
                  _vm._v(
                    'To reach the "Healthy Weight" category, you need to weigh ' +
                      _vm._s(_vm.weightRanges.healthyWeight.high) +
                      " pounds."
                  )
                ]
              )
            : _vm._e(),
          _vm.bmiScore === 2
            ? _c(
                "div",
                { staticClass: "print-sentence", class: _vm.bmiScoreColor },
                [
                  _vm._v(
                    'To reach the "Overweight" category, you need to weigh ' +
                      _vm._s(_vm.weightRanges.overweight.high) +
                      " pounds."
                  )
                ]
              )
            : _vm._e(),
          _vm.bmiScore === 3
            ? _c(
                "div",
                { staticClass: "print-sentence", class: _vm.bmiScoreColor },
                [
                  _vm._v(
                    'To reach the "Obese" category, you need to weigh ' +
                      _vm._s(_vm.weightRanges.obese.high) +
                      " pounds."
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "frow content-end items-end" }, [
            _c("div", { staticClass: "col-xs-9-10" }, [
              _c("table", { staticClass: "print-bmi-table" }, [
                _vm._m(1),
                _c("tr", { staticClass: "underweight" }, [
                  _c("td", [_vm._v("Underweight")]),
                  _c("td", [_vm._v("< 18.5")]),
                  _c("td", [_vm._v("-1")]),
                  _c("td", [
                    _vm._v("< " + _vm._s(_vm.weightRanges.underweight.high))
                  ])
                ]),
                _c("tr", { staticClass: "healthy-weight" }, [
                  _c("td", [_vm._v("Healthy Weight")]),
                  _c("td", [_vm._v("18.5 – 24.9")]),
                  _c("td", [_vm._v("0")]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.weightRanges.healthyWeight.low) +
                        " - " +
                        _vm._s(_vm.weightRanges.healthyWeight.high)
                    )
                  ])
                ]),
                _c("tr", { staticClass: "overweight" }, [
                  _c("td", [_vm._v("Overweight")]),
                  _c("td", [_vm._v("25.0 – 29.9")]),
                  _c("td", [_vm._v("1")]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.weightRanges.overweight.low) +
                        " - " +
                        _vm._s(_vm.weightRanges.overweight.high)
                    )
                  ])
                ]),
                _c("tr", { staticClass: "obese" }, [
                  _c("td", [_vm._v("Obese")]),
                  _c("td", [_vm._v("30.0 – 39.9")]),
                  _c("td", [_vm._v("2")]),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.weightRanges.obese.low) +
                        " - " +
                        _vm._s(_vm.weightRanges.obese.high)
                    )
                  ])
                ]),
                _c("tr", { staticClass: "morbidly-obese" }, [
                  _c("td", [_vm._v("Morbidly Obese")]),
                  _c("td", [_vm._v("> 40.0")]),
                  _c("td", [_vm._v("3")]),
                  _c("td", [
                    _vm._v("> " + _vm._s(_vm.weightRanges.morbidlyObese.low))
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-xs-1-10" }, [
              _c("div", { staticClass: "print-table-gradient" }, [
                _c(
                  "div",
                  {
                    staticClass: "print-table-arrow",
                    style: { top: _vm.arrowPercentage }
                  },
                  [_c("arrow")],
                  1
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col-xs-1-4" }, [
          _c("div", { staticClass: "print-right-results" }, [
            _c("div", { staticClass: "print-section-title text-center" }, [
              _vm._v("Your BMI:")
            ]),
            _c("div", { staticClass: "frow items-center justify-end" }, [
              _c("div", { staticClass: "print-box-label" }, [
                _vm._v("Height:")
              ]),
              _c("div", { staticClass: "print-box" }, [
                _vm._v(
                  _vm._s(
                    _vm.assessment.heightFT +
                      "' " +
                      _vm.assessment.heightIN +
                      '"'
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "frow items-center justify-end" }, [
              _c("div", { staticClass: "print-box-label" }, [
                _vm._v("Weight:")
              ]),
              _c("div", { staticClass: "print-box" }, [
                _vm._v(_vm._s(_vm.assessment.weight))
              ])
            ]),
            _c("div", { staticClass: "frow items-center justify-end" }, [
              _c("div", { staticClass: "print-box-label" }, [_vm._v("BMI:")]),
              _c(
                "div",
                {
                  staticClass: "print-box",
                  class: { danger: !_vm.bmiScore == 0 }
                },
                [_vm._v(_vm._s(_vm.roundBmi))]
              )
            ]),
            _c("div", { staticClass: "print-right-final-score" }, [
              _c("div", { staticClass: "frow column-end" }, [
                _c("div", { staticClass: "print-final-title" }, [
                  _vm._v("BMI Score:")
                ]),
                _c("div", { staticClass: "print-final-score" }, [
                  _vm._v(_vm._s(_vm.bmiScore))
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "print-section page-break" }, [
      _c("div", { staticClass: "frow width-100" }, [
        _c("div", { staticClass: "col-xs-3-4 pr-30" }, [
          _c("div", { staticClass: "print-section-title" }, [
            _vm._v("Your Metabolic Syndrome (MetS)")
          ]),
          _c("div", { staticClass: "print-sentence" }, [
            _vm._v(
              "Metabolic Syndrome consists of 5 risk factors.  Values in the GREEN below indicate that you do NOT have that risk factor. If you have any 3 of the risk factors, you have Metabolic Syndrome."
            )
          ]),
          _c("div", { staticClass: "print-mets-bars pl-5" }, [
            _c(
              "div",
              { staticClass: "frow gutters" },
              [
                _c("mets-slider", {
                  attrs: {
                    title: "Waist Circumference",
                    units: "inches",
                    value: _vm.assessment.waistCirc,
                    add: _vm.assessment.sex === "m" ? 5 : 0,
                    min: 25,
                    mid: 35,
                    high: 39.9,
                    max: 50
                  }
                }),
                _c("mets-slider", {
                  attrs: {
                    title: "Blood Glucose",
                    units: "mg/dL",
                    value: _vm.assessment.bloodGlucose,
                    min: 60,
                    mid: 100,
                    high: 125,
                    max: 200
                  }
                }),
                _c("mets-slider", {
                  attrs: {
                    title: "Hemoglobin A1c",
                    units: "%",
                    value: _vm.assessment.a1c,
                    min: 4,
                    mid: 5.7,
                    high: 6.4,
                    max: 8
                  }
                }),
                _c("mets-slider", {
                  attrs: {
                    title: "HDL Cholesterol",
                    units: "mg/dL",
                    value: _vm.assessment.hdl,
                    flip: true,
                    add: _vm.assessment.sex === "f" ? 10 : 0,
                    min: 20,
                    mid: 30,
                    high: 39.9,
                    max: 90
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "frow gutters mt-15" },
              [
                _c("mets-slider", {
                  attrs: {
                    title: "Triglycerides",
                    units: "mg/dL",
                    value: _vm.assessment.triglycerides,
                    min: 50,
                    mid: 150,
                    high: 199,
                    max: 500
                  }
                }),
                _c("mets-slider", {
                  attrs: {
                    title: "Systolic",
                    units: "mmHg",
                    value: _vm.assessment.systolicBP,
                    min: 90,
                    mid: 130,
                    high: 139,
                    max: 180
                  }
                }),
                _c("mets-slider", {
                  attrs: {
                    title: "Diastolic",
                    units: "mmHg",
                    value: _vm.assessment.diastolicBP,
                    min: 60,
                    mid: 85,
                    high: 89,
                    max: 110
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "col-xs-1-4" }, [
          _c("div", { staticClass: "print-right-results" }, [
            _c("div", { staticClass: "print-section-title text-center" }, [
              _vm._v("Your MetS:")
            ]),
            _c("div", { staticClass: "frow items-center justify-end" }, [
              _c("div", { staticClass: "print-box-label" }, [_vm._v("Waist:")]),
              _c(
                "div",
                {
                  staticClass: "print-box",
                  class: { danger: _vm.waistCircumferenceScore == 1 }
                },
                [_vm._v(_vm._s(_vm.assessment.waistCirc) + '"')]
              )
            ]),
            _c("div", { staticClass: "frow items-center justify-end" }, [
              _c("div", { staticClass: "print-box-label" }, [
                _vm._v("Glucose:")
              ]),
              _c(
                "div",
                {
                  staticClass: "print-box",
                  class: { danger: _vm.bloodGlucoseScore == 1 }
                },
                [_vm._v(_vm._s(_vm.assessment.bloodGlucose))]
              )
            ]),
            _c("div", { staticClass: "frow items-center justify-end" }, [
              _c("div", { staticClass: "print-box-label" }, [_vm._v("HDL:")]),
              _c(
                "div",
                {
                  staticClass: "print-box",
                  class: { danger: _vm.hdlScore == 1 }
                },
                [_vm._v(_vm._s(_vm.assessment.hdl))]
              )
            ]),
            _c("div", { staticClass: "frow items-center justify-end" }, [
              _c("div", { staticClass: "print-box-label" }, [_vm._v("TG:")]),
              _c(
                "div",
                {
                  staticClass: "print-box",
                  class: { danger: _vm.triglyceridesScore == 1 }
                },
                [_vm._v(_vm._s(_vm.assessment.triglycerides))]
              )
            ]),
            _c("div", { staticClass: "frow items-center justify-end" }, [
              _c("div", { staticClass: "print-box-label" }, [_vm._v("BP:")]),
              _c(
                "div",
                {
                  staticClass: "print-box",
                  class: { danger: _vm.bloodPressureScore == 1 }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.assessment.systolicBP +
                        "/" +
                        _vm.assessment.diastolicBP
                    )
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "print-right-final-score" }, [
              _c("div", { staticClass: "frow column-end" }, [
                _c("div", { staticClass: "print-final-title" }, [
                  _vm._v("MetS Score:")
                ]),
                _c("div", { staticClass: "print-final-score" }, [
                  _vm._v(_vm._s(_vm.metsScore))
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "print-section" }, [
      _c("div", { staticClass: "print-section-title" }, [
        _vm._v("Your Health Interpretation")
      ]),
      _c("div", { staticClass: "print-mets-factor mt-30" }, [
        _c("ul", [
          _c("li", [
            _vm._v(
              "Your overall HRI Score is: " +
                _vm._s(_vm.bmiScore + " / " + _vm.metsScore)
            )
          ]),
          _c("li", [
            _vm._v('Your BMI status: "' + _vm._s(_vm.bmiScoreText) + '"')
          ]),
          _vm.bmiScore === -1
            ? _c("li", [
                _vm._v(
                  'To reach the "Healthy Weight" category, you need to weigh ' +
                    _vm._s(_vm.weightRanges.healthyWeight.low) +
                    " pounds."
                )
              ])
            : _vm._e(),
          _vm.bmiScore === 0
            ? _c("li", [
                _vm._v(
                  'Congratulations! Your weight places you in the "Healthy Weight" category.'
                )
              ])
            : _vm._e(),
          _vm.bmiScore === 1
            ? _c("li", [
                _vm._v(
                  'To reach the "Healthy Weight" category, you need to weigh ' +
                    _vm._s(_vm.weightRanges.healthyWeight.high) +
                    " pounds."
                )
              ])
            : _vm._e(),
          _vm.bmiScore === 2
            ? _c("li", [
                _vm._v(
                  'To reach the "Overweight" category, you need to weigh ' +
                    _vm._s(_vm.weightRanges.overweight.high) +
                    " pounds."
                )
              ])
            : _vm._e(),
          _vm.bmiScore === 3
            ? _c("li", [
                _vm._v(
                  'To reach the "Obese" category, you need to weigh ' +
                    _vm._s(_vm.weightRanges.obese.high) +
                    " pounds."
                )
              ])
            : _vm._e(),
          _c("li", [
            _vm._v(
              "You " +
                _vm._s(this.metsScore >= 3 ? "do" : "do not") +
                " have Metabolic Syndrome."
            )
          ]),
          _c("li", [_vm._v("Metabolic Syndrome disease indicators:")]),
          _c("li", { staticClass: "no-bullet" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  "Your Diabetes Status: " +
                    _vm._s(
                      _vm.assessment.diagnosedWith.diabetes
                        ? "High Risk"
                        : _vm.diabetesText
                    )
                )
              ]),
              _c("li", [
                _vm._v(
                  "Your Hypertension Status: " + _vm._s(_vm.hypertensionText)
                )
              ]),
              _c("li", [
                _vm._v(
                  "Your Lipid and Cholesterol Status: " +
                    _vm._s(_vm.lipidAndCholesterolText)
                )
              ])
            ])
          ]),
          _c("li", [_vm._v("Other Cholesterol Levels:")]),
          _c("li", { staticClass: "no-bullet" }, [
            _c("ul", [
              _c("li", [
                _vm._v(
                  "Total Cholesterol: " +
                    _vm._s(_vm.assessment.totalCholesterol || "Not collected")
                )
              ]),
              _c("li", [
                _vm._v(
                  "LDL Cholesterol: " +
                    _vm._s(_vm.assessment.ldlCholesterol || "Not collected")
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "print-section" },
      [
        _c("div", { staticClass: "print-section-title" }, [
          _vm._v("Your Lifestyle Medicine Prescription")
        ]),
        Object.keys(_vm.recommendations).some(function(key) {
          return _vm.display[key]
        })
          ? _c("div", { staticClass: "print-mets-factor" }, [
              _c(
                "div",
                { staticClass: "print-mets-factor-title" },
                [
                  _vm._v("Health Screening Recommendations"),
                  _vm._l(_vm.recommendations, function(rec, key) {
                    return _vm.display[key]
                      ? _c("div", { staticClass: "print-mets-factor" }, [
                          _c(
                            "ul",
                            _vm._l(rec.content, function(item) {
                              return _c(
                                "li",
                                { class: { "no-bullet": Array.isArray(item) } },
                                [
                                  Array.isArray(item)
                                    ? _c(
                                        "ul",
                                        _vm._l(item, function(subRec) {
                                          return _c("li", [
                                            _vm._v(_vm._s(subRec))
                                          ])
                                        }),
                                        0
                                      )
                                    : [_vm._v(_vm._s(item))]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  })
                ],
                2
              )
            ])
          : _vm._e(),
        Object.keys(_vm.vaccinations).some(function(key) {
          return _vm.display[key]
        })
          ? _c("div", { staticClass: "print-mets-factor" }, [
              _c(
                "div",
                { staticClass: "print-mets-factor-title" },
                [
                  _vm._v("Recommended Vaccinations"),
                  _vm._l(_vm.vaccinations, function(vac, key) {
                    return _vm.display[key]
                      ? _c("div", { staticClass: "print-mets-factor" }, [
                          _c(
                            "ul",
                            _vm._l(vac.content, function(item) {
                              return _c(
                                "li",
                                { class: { "no-bullet": Array.isArray(item) } },
                                [
                                  Array.isArray(item)
                                    ? _c(
                                        "ul",
                                        _vm._l(item, function(subVac) {
                                          return _c("li", [
                                            _vm._v(_vm._s(subVac))
                                          ])
                                        }),
                                        0
                                      )
                                    : [_vm._v(_vm._s(item))]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  })
                ],
                2
              )
            ])
          : _vm._e(),
        _vm._l(_vm.sections, function(section, key) {
          return _vm.display[key]
            ? _c("div", { staticClass: "print-mets-factor" }, [
                _c("div", { staticClass: "print-mets-factor-title" }, [
                  _vm._v(_vm._s(section.title))
                ]),
                _c(
                  "ul",
                  _vm._l(section.content, function(item) {
                    return _c(
                      "li",
                      { class: { "no-bullet": Array.isArray(item) } },
                      [
                        Array.isArray(item)
                          ? _c(
                              "ul",
                              _vm._l(item, function(subItem) {
                                return _c("li", [_vm._v(_vm._s(subItem))])
                              }),
                              0
                            )
                          : [_vm._v(_vm._s(item))]
                      ],
                      2
                    )
                  }),
                  0
                )
              ])
            : _vm._e()
        }),
        Object.keys(_vm.interventions).some(function(key) {
          return _vm.display[key]
        })
          ? _c("div", { staticClass: "print-mets-factor" }, [
              _c(
                "div",
                { staticClass: "print-intervention" },
                [_c("warning")],
                1
              ),
              _c(
                "div",
                { staticClass: "print-mets-factor-title print-intervention" },
                [_vm._v("Intervention")]
              ),
              _c(
                "ul",
                _vm._l(_vm.interventions, function(item, key) {
                  return _vm.display[key]
                    ? _c("li", [_vm._v(_vm._s(item))])
                    : _vm._e()
                }),
                0
              )
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "print-mets-factor" },
          [
            _c("div", { staticClass: "print-mets-factor-title" }, [
              _vm._v("Additional Resources")
            ]),
            _vm._l(_vm.resources, function(res, key) {
              return _vm.display[key] !== false
                ? _c("div", { staticClass: "resource" }, [
                    _c("ul", [
                      res.url
                        ? _c("li", [
                            _vm._v(_vm._s(res.title) + " - " + _vm._s(res.url))
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            })
          ],
          2
        )
      ],
      2
    ),
    _c("div", { staticClass: "logo-wds text-center" }, [
      _vm.organization && _vm.organization._id === "0j9k44fXujEDz8CY"
        ? _c("div", [
            _c("p", { staticClass: "mb-15" }, [
              _vm._v(
                "Thank you for choosing Wellness Data Solutions and Engage 180. Please note that the information provided in Engage 180 is not intended to be diagnostic medical advice and should not be relied on as such. There is no doctor-patient relationship between any user of Engage 180 and any doctor or other health care provider engaged by Wellness Data Solutions."
              )
            ]),
            _c("p", { staticClass: "mb-15" }, [
              _vm._v(
                " We encourage you to communicate with your physician or other appropriate healthcare provider so together you can integrate the information from Engage 180 to determine your health status. The information provided through Engage 180 and Wellness Data Solutions is not intended to substitute for such consultations with your physician or other appropriate healthcare provider nor for medical advice specific to your health condition."
              )
            ]),
            _vm._m(2)
          ])
        : _c("div", [
            _c("p", { staticClass: "mb-15" }, [
              _vm._v(
                "Thank you for choosing Wellness Data Solutions and the Health Risk Index (HRI). Please note that the information provided in the HRI is not intended to be diagnostic medical advice and should not be relied on as such. There is no doctor-patient relationship between any user of the HRI and any doctor or other health care provider engaged by Wellness Data Solutions."
              )
            ]),
            _c("p", { staticClass: "mb-15" }, [
              _vm._v(
                "We encourage you to communicate with your physician or other appropriate healthcare provider so together you can integrate the information from the HRI to determine your health status. The information provided through the HRI and Wellness Data Solutions is not intended to substitute for such consultations with your physician or other appropriate healthcare provider nor for medical advice specific to your health condition."
              )
            ]),
            _vm._m(3)
          ]),
      _c("div", { staticClass: "frow gutters" }, [
        _vm.organization && _vm.organization.logo
          ? _c("div", { staticClass: "col-xs-1-5" }, [
              _c("img", {
                staticClass: "logo",
                attrs: { src: _vm.organization.logo }
              })
            ])
          : _vm._e(),
        _c("div", { staticClass: "col-xs-1-5" }, [_c("wds")], 1)
      ])
    ]),
    _c("p", { staticClass: "text-center" }, [
      _vm._v("Copyright © 2018 by Wellness Data Solutions")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-1-2" }, [
      _c("p", [
        _vm._v("The lowest risk, target score is 0 / 0."),
        _c("br"),
        _vm._v("The highest risk score is 3 / 5."),
        _c("br"),
        _vm._v("The chart to the right represents all possible risk scores."),
        _c("br"),
        _c("br"),
        _c("strong", [
          _vm._v("Green: LOW RISK"),
          _c("br"),
          _vm._v("Yellow: MEDIUM RISK"),
          _c("br"),
          _vm._v("Red: HIGH RISK")
        ]),
        _c("br"),
        _c("br"),
        _vm._v(
          "Your goal is to lower your risk goals so you are in the green. Below is how we calculated your BMI and MetS score."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Weight Category")]),
      _c("th", [_vm._v("BMI")]),
      _c("th", [_vm._v("Health Risk Index")]),
      _c("th", [_vm._v("Weight Range")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-15" }, [
      _c("strong", [_vm._v(" Engage 180: Your health in your hands")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-15" }, [
      _c("strong", [
        _vm._v("The Health Risk Index™ (HRI): Your health in your hands")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }